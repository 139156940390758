import React, { Fragment, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';

import '../../styles/components/data-table/data-table.scss';

const DataTable = ({
    columns = [],
    data = [],
    total = 0,
    currentPage = 1,
    pageSize = 25,
    isPagination = true,
    handleOnChange = () => { },
    scrollX,
    scrollY = null,
    ...rest
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { t } = useTranslation();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const paginationConfig = {
        current: currentPage,
        pageSize: pageSize,
        total: total,
        pageSizeOptions: [10, 25, 50, 100],
        showTotal: (total, range) => `${t('component_datatable_displaying')} ${range[0]}-${range[1]} of ${total}`,
        itemRender: (_, type, originalElement) => {
            if (type === 'prev') {
                return <Button type='primary' className='paddingX-2'>{t('component_datatable_previous')}</Button>
            }
            if (type === 'next') {
                return <Button type='primary' className='paddingX-3'>{t('component_datatable_next')}</Button>
            }
            return originalElement;
        },
        onChange: handleOnChange,
        showLessItems: true,
        responsive: true,
        showSizeChanger: !isMobile,
        simple: isMobile ? isMobile : false,
    };

    return (
        <Fragment>
            <Table
                className='w-100 x-auto data-table'
                pagination={isPagination ? paginationConfig : false}
                columns={columns}
                dataSource={data}
                scroll={{
                    x: scrollX,
                    y: scrollY,
                }}
                {...rest}
            />
        </Fragment>
    );
};

export default DataTable;
