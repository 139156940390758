import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Collapse, Flex, Row, Select, Form, Typography, DatePicker, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';

import { deleteVacation, getVacationLeaves, getVacationListData } from './redux/slice';

import ExpandIcon from '../../components/icon/ExpandIcon';
import EditIcon from '../../components/icon/EditIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import DocumentIcon from '../../components/icon/DocumentIcon';

import '../../styles/pages/new-vacation.scss';
import dayjs from 'dayjs';
import { lightPrimary, lightSecondary, lightYellow } from '../../utils/theme/config';
import { getByIdClient } from '../administrator/redux/slice';

const { Title, Text } = Typography;

const AddNewVacation = ({ permission }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { profile, client, auth } = useSelector(state => state);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [vacations, setVacations] = useState([]);
    const [leaves, setLeaves] = useState({});
    const [filetrData, setFilterData] = useState({});
    const [holiDay, setHoliDay] = useState('');

    useEffect(() => {
        getVacationList();
    }, [page, pageSize, order, sortBy, auth?.decodeToken?.organizer, filetrData]);

    const getVacationList = () => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getVacationListData({
                sortBy,
                order,
                filters: {
                    leaveType: filetrData?.leave ? filetrData?.leave : '',
                    startDate: filetrData?.startDate ? filetrData?.startDate : '',
                    endDate: filetrData?.endDate ? filetrData?.endDate : ''
                },
                page: page,
                limit: pageSize,
                organizer: auth?.decodeToken?.organizer
            }))
        }
    };

    useEffect(() => {
        getLeaves();
    }, [auth?.decodeToken])

    useEffect(() => {
        auth?.decodeToken?._id && dispatch(getByIdClient(auth?.decodeToken?._id))
    }, [auth?.decodeToken?._id])

    useEffect(() => {
        if (client?.client?.holiday) {
            setHoliDay(client?.client?.holiday)
        }
    }, [client?.client])

    const getLeaves = () => {
        if (auth?.decodeToken?._id)
            dispatch(getVacationLeaves(auth?.decodeToken?._id));
    }

    useEffect(() => {
        setLeaves(profile?.leaves);
    }, [profile.leaves])

    // Set Vacation List
    useEffect(() => {
        setVacations(profile.vacatonLists);
        setTotalRecords(profile.totalRecords);
    }, [profile.vacatonLists, profile.totalRecords]);

    // Leave Collapse Items
    const items = [
        {
            key: '1',
            label: <Title level={4} className='text-primary'>{t('organizer_common_total')} {t('organizer_common_leaves')}</Title>,
            children: <Fragment>
                <Row gutter={[24, 24]}>
                    <Col xxl={2} xl={2} lg={4} md={8} sm={12} xs={12} style={{ borderRight: '1px solid #EEEEEE' }}>
                        <Flex vertical gap={8} justify='center' align='center'>
                            <Title level={2} type='success'>{leaves?.totalLeaves ? (leaves?.totalLeaves >= 10 ? leaves?.totalLeaves : `0${leaves?.totalLeaves}`) : holiDay}</Title>
                            <Text>{t('organizer_common_total')} <br />{t('organizer_common_leaves')}</Text>
                        </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={12} style={{ borderRight: '1px solid #EEEEEE' }}>
                        <Flex vertical gap={8} justify='center' align='center'>
                            <Title level={2} type='success'>{leaves?.remainPaidLeaves ? (leaves?.remainPaidLeaves >= 10 ? leaves?.remainPaidLeaves : `0${leaves?.remainPaidLeaves}`) : '00'}</Title>
                            <Text>{t('organizer_common_total')} {t('organizer_common_remain')} <br />{t('organizer_common_paid')} {t('organizer_common_leaves')}</Text>
                        </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={12} style={{ borderRight: '1px solid #EEEEEE' }}>
                        <Flex vertical gap={8} justify='center' align='center'>
                            <Title level={2} className='red-color'>{leaves?.usedLeaves ? (leaves?.usedLeaves >= 10 ? leaves?.usedLeaves : `0${leaves?.usedLeaves}`) : '00'}</Title>
                            <Text>{t('organizer_common_total')} {t('organizer_common_used')}<br />{t('organizer_common_leaves')}</Text>
                        </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={12} style={{ borderRight: '1px solid #EEEEEE' }}>
                        <Flex vertical gap={8} justify='center' align='center'>
                            <Title level={2} className='yellow-color'>{leaves?.sickLeaves ? (leaves?.sickLeaves >= 10 ? leaves?.sickLeaves : `0${leaves?.sickLeaves}`) : '00'}</Title>
                            <Text>{t('organizer_common_sick')} {t('organizer_common_leaves')}</Text>
                        </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={12} style={{ borderRight: '1px solid #EEEEEE' }}>
                        <Flex vertical gap={8} justify='center' align='center'>
                            <Title level={2} className='blue-color'>{leaves?.formalLeaves ? (leaves?.formalLeaves >= 10 ? leaves?.formalLeaves : `0${leaves?.formalLeaves}`) : '00'}</Title>
                            <Text>{t('organizer_common_formal')} {t('organizer_common_leaves')}</Text>
                        </Flex>
                    </Col>
                </Row>
            </Fragment>,
        }
    ];

    const handleOnFinish = (values) => {
        setFilterData(values);
    }

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    // Table columns
    const columns = [
        {
            title: t('organizer_profilesettings_vacations_leavedaterange'),
            dataIndex: 'leaveDateRange',
            key: 'leaveDateRange',
            sorter: (_, __, order) => handleSortFunction(order, 'leaveDateRange'),
            render: (_, record) => {
                return (
                    <Flex gap={8} align='center'>
                        <span style={{ backgroundColor: record?.leaveType === 'Sick Leave' ? '#FFCFC5' : '#B7E7AF', height: '0.75rem', width: '0.75rem', borderRadius: '50%' }}></span>
                        <Text>{`${dayjs(record?.leaveDateRange?.startDate).format('DD.MM.YY')} to ${dayjs(record?.leaveDateRange?.endDate).format('DD.MM.YY')}`}</Text>
                    </Flex>
                )
            },
        },
        {
            title: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type'),
            dataIndex: 'leaveType',
            key: 'type',
            sorter: (_, __, order) => handleSortFunction(order, 'leaveType'),
        },
        {
            title: t('organizer_common_total') + ' ' + t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_days'),
            dataIndex: 'totalLeaveDays',
            key: 'totalLeaveDays',
            sorter: (_, __, order) => handleSortFunction(order, 'leaveDays'),
            render: (text) => <Text>{text} {t('organizer_common_days')}</Text>,
        },
        {
            title: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason'),
            dataIndex: 'leaveReason',
            key: 'leaveReason',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: t('organizer_common_uploadedfile'),
            dataIndex: 'file',
            key: 'file',
            render: (_, record) => {
                return <Flex align='center' gap={3}>
                    <DocumentIcon />
                    <Text>{record?.uploadedFile}</Text>
                </Flex>
            },
        },
        {
            title: t('organizer_common_status'),
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                return <Fragment>
                    {
                        record.status?.toLowerCase() === 'approved' ?
                            <Tag color={lightPrimary} className='text-center'>{record?.status?.charAt(0)?.toUpperCase() + record?.status?.slice(1)}</Tag> :
                            record.status?.toLowerCase() === 'pending' ?
                                <Tag color={lightYellow} className='text-center'>{record?.status?.charAt(0)?.toUpperCase() + record?.status?.slice(1)}</Tag> :
                                <Tag color={lightSecondary} className='text-center'>{record?.status?.charAt(0)?.toUpperCase() + record?.status?.slice(1)}</Tag>
                    }
                </Fragment>
            },
        },
    ];

    // Permission

    if (Object.keys(permission ?? {})?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>{t('organizer_common_action')}</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                const isPastLeave = dayjs(record?.leaveDateRange?.endDate).isBefore(dayjs().startOf('day'));
                return (
                    <Flex justify='center'>
                        {
                            permission?.canUpdate &&
                            <Button
                                type='text'
                                className='padding-1'
                                onClick={() => navigate(`/settings/add-new-vacation/edit/${record?._id}`)}
                            >
                                <EditIcon role='button' />
                            </Button>
                        }
                        {
                            (!isPastLeave && permission?.canDelete) &&
                            <CommonPopconfirm
                                icon={null}
                                title={t('organizer_profilesettings_vacations_deleteLeave')}
                                description={t('organizer_profilesettings_vacations_deleteLeave_confirmdescription')}
                                onConfirm={() => dispatch(deleteVacation({
                                    id: record?._id,
                                    sortBy,
                                    order,
                                    filters: {
                                        leaveType: "",
                                        startDate: '',
                                        endDate: ''
                                    },
                                    page: page,
                                    limit: pageSize,
                                    organizer: auth?.decodeToken?.organizer,
                                    userId: auth?.decodeToken?._id
                                }))}
                                cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                            >
                                <Button
                                    type='text'
                                    className='padding-1'>
                                    <CloseIcon role='button' />
                                </Button>
                            </CommonPopconfirm>
                        }
                    </Flex >
                );
            },
        })
    }

    // Filter Collapse items
    const filterItems = [
        {
            key: '1',
            label: <Title level={4} className='text-primary'>{t('organizer_common_filter')}</Title>,
            children: <Fragment>
                <Form
                    form={form}
                    name='filters'
                    colon={false}
                    layout='vertical'
                    onFinish={handleOnFinish}
                >
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24} >
                            <Form.Item
                                name='leave'
                                label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        {
                                            label: t('organizer_common_formal') + ' ' + t('organizer_vacationmanagement_leave'),
                                            value: 'Formal Leave'
                                        },
                                        {
                                            label: t('organizer_common_sick') + ' ' + t('organizer_vacationmanagement_leave'),
                                            value: 'Sick Leave'
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>

                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='startDate'
                                label={t('organizer_profilesettings_vacations_leave_from_date')}
                            >
                                <DatePicker className='w-100' format={'DD-MM-YYYY'} placeholder={t('organizer_profilesettings_vacations_leave_from_date')} />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='endDate'
                                label={t('organizer_profilesettings_vacations_leave_to_date')}
                            >
                                <DatePicker className='w-100' format={'DD-MM-YYYY'} placeholder={t('organizer_profilesettings_vacations_leave_to_date')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' onClick={() => { form.resetFields(); setFilterData({}); getVacationList() }}>{t('organizer_common_clearbutton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_applybutton')}</Button>
                    </Flex>
                </Form>
            </Fragment >,
        }
    ];

    // Expand Icon
    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    // Table Pagination Funcation
    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    return (
        <Fragment>
            <Flex vertical className='new-vacation bg-gray padding-1 h-100 change-tag' gap={24}>
                {
                    (Object.keys(permission || {}).length > 0 && permission?.canCreate) &&
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Flex justify='flex-end' className='bg-white padding-1'>
                                <Button onClick={() => navigate('/settings/add-new-Vacation/add')}>{t('organizer_profilesettings_addnewvaction')}</Button>
                            </Flex>
                        </Col>
                    </Row>
                }
                <Flex>
                    <Collapse
                        items={items}
                        defaultActiveKey={['1']}
                        className='w-100'
                        bordered={false}
                        expandIcon={customExpandIcon}
                        expandIconPosition={'end'}
                    />
                </Flex>
                <Flex>
                    <Collapse
                        items={filterItems}
                        defaultActiveKey={['1']}
                        className='w-100'
                        bordered={false}
                        expandIcon={customExpandIcon}
                        expandIconPosition={'end'}
                    />
                </Flex>
                <Flex>
                    <DataTable
                        columns={columns}
                        pageSize={pageSize}
                        currentPage={page}
                        total={totalRecords}
                        scrollX={1000}
                        showSorterTooltip={false}
                        isPagination={true}
                        handleOnChange={handleOnChange}
                        data={vacations}
                        rowKey={(record) => record?._id}
                    />
                </Flex>
            </Flex>
        </Fragment >
    );
};

export default AddNewVacation;
