import * as React from 'react';

const GermanIcon = ({
    size = 36,
}) => {
    return (
        <svg
        width={size}
        height={size}
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <g filter='url(#filter0_d_3706_20)'>
          <circle cx={15} cy={11} r={10} fill='white' />
        </g>
        <rect
          x={5}
          y={1}
          width={20}
          height={20}
          rx={10}
          fill='url(#pattern0_3706_20)'
        />
        <defs>
          <filter
            id='filter0_d_3706_20'
            x={0}
            y={0}
            width={size}
            height={size}
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation={2.5} />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_3706_20'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_3706_20'
              result='shape'
            />
          </filter>
          <pattern
            id='pattern0_3706_20'
            patternContentUnits='objectBoundingBox'
            width={1}
            height={1}
          >
            <use
              xlinkHref='#image0_3706_20'
              transform='translate(-0.333333) scale(0.0133333)'
            />
          </pattern>
          <image
            id='image0_3706_20'
            width={125}
            height={75}
            xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAABLCAIAAADSwR/CAAABCklEQVR4Ae3SQREDMRAEMfMHFwbBkrCY3oeu/O8t3bznI0CAAAECBAgQIECAAAECBAgQIECAAAECBAgQIECAAAECBAgQIECAwFjg+563F4DeCDTV/b6uFbk3Ak312vr293BvBJrqfl/XitwbgaZ6bX37e7g3Ak11v69rRe6NQFO9tr79Pdwbgaa639e1IvdGoKleW9/+Hu6NQFPd7+takXsj0FSvrW9/D/dGoKnu93WtyL0RaKrX1re/5/0+XiAQJP3p3+dxbwSaqslzbwSaqr1zbwSaqr1zbwSaqr1zbwSaqr1zbwSaqr1zbwSaqr1zbwSaqr1zbwSaqr1zbwSaqr1zbwSaqr1zbwT+VhSHE95BLskAAAAASUVORK5CYII='
          />
        </defs>
      </svg>
    );
};

export default GermanIcon;
