import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';

import logo from '../../assets/images/logo.png';
import user from '../../assets/images/userpdf.png';
import sunIcon from '../../assets/images/sunpdfIcon.png';
import noteIcon from '../../assets/images/notepdfIcon.png';
import diaryIcon from '../../assets/images/diaryIcon.png';
import galleryIocn from '../../assets/images/galleryIocn.jpg';

// Define styles
const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 12,
        position: 'relative',
    },
    header: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    bold: {
        fontWeight: 800,
    },
    normal: {
        fontWeight: 'normal',
    },
    section: {
        border: '1px solid #D9D9D9',
        padding: 16,
        width: '100%'
    },
    part: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    flex: {
        display: 'flex',
        flexDirection: 'row'
    },
    width25: {
        width: '25%'
    },
    secondaryColor: {
        color: '#90909A'
    },
    image: {
        width: 70,
        height: 70,
        margin: 5,
    },
    note: {
        fontSize: 10,
        marginTop: 5,
        fontStyle: 'italic',
    },
});

const PageComponent = ({ data }) => {
    return (
        <Document>
            <Page style={styles.page} size="A4">
                <View style={styles.section}>
                    <View style={styles.part}>
                        <Text style={{ width: '50%' }}>
                            <Text style={styles.header}>Construction Daily Report</Text>
                        </Text>
                        <View style={{ width: '50%', display: 'flex', alignItems: 'flex-end', }}>
                            <View style={{ width: 80, height: 80, }}>
                                <Image style={{ width: "100%", height: "100%", borderRadius: 8 }} src={data?.company?.profilePicture ? `${process.env.REACT_APP_API_URI}/company/${data?.company?.profilePicture}` : logo} />
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <View style={{ width: '50%' }}>
                            <Text><Text style={styles.bold}>Nr: </Text>  <Text>01</Text></Text>
                            <Text><Text style={styles.bold}>Date:</Text>  <Text>{dayjs(data?.pdfListData?.date).format('DD.MM.YYYY') || "-"}</Text></Text>
                            <View style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text style={styles.bold}>Construction project / </Text>
                                <Text style={styles.bold}>Building component: {data?.pdfListData?.constructionProjectOrbuildingComponent || "-"}</Text>
                            </View>
                        </View>
                        <View style={{ width: '50%', textAlign: 'right' }}>
                            <Text style={styles.normal}>{data?.company?.name ?? '-'}</Text>
                            <Text>{data?.company?.email ?? '-'}</Text>
                            <Text>{data?.company?.phone ?? '-'}</Text>
                            <Text>{data?.company?.website ?? '-'}</Text>
                            <Text>{data?.company?.taxNumber ?? '-'}</Text>
                            <Text>{Object.keys(data?.company?.address)?.length > 0 ? (`${data?.company?.address?.streetNo} ${data?.company?.address?.city} ${data?.company?.address?.pincode}`) : '-'}</Text>
                        </View>
                    </View>
                </View>
                {/* Workers List */}
                <View style={{ ...styles.section, borderTop: 0 }}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={user} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text>Workers List</Text>
                    </View>
                </View>
                {/* Workers List Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={styles.flex}>
                        <Text style={{ width: '50%', ...styles.secondaryColor, textAlign: 'left' }}>Name</Text>
                        <Text style={{ width: '50%', ...styles.secondaryColor, textAlign: 'center' }}>Total Hrs</Text>
                    </View>
                </View>
                {/* Workers List Lists */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    {
                        data?.pdfListData?.workers?.map((item, index) => {
                            return <View style={styles.flex} key={index}>
                                <Text style={{ width: '50%', textAlign: 'left' }}>{item?.name}</Text>
                                <Text style={{ width: '50%', textAlign: 'center' }}>{item?.totalHrs}</Text>
                            </View>

                        })
                    }
                </View>
                {/* Weather */}
                <View style={{ ...styles.section, borderTop: 0 }}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={sunIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text>Weather</Text>
                    </View>
                </View>
                {/* Weather Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={styles.flex}>
                        <Text style={{ ...styles.width25, ...styles.secondaryColor, textAlign: 'left' }}>Wether</Text>
                        <Text style={{ ...styles.width25, ...styles.secondaryColor, }}>Temperature</Text>
                        <Text style={{ width: '50%', ...styles.secondaryColor, }}>Text</Text>
                    </View>
                </View>
                {/* Weather Lists*/}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    {
                        data?.pdfListData?.weather?.map((item, index) => {
                            return <View style={styles.flex} key={index}>
                                <Text style={{ ...styles.width25, textAlign: 'left' }}>{item?.weather?.charAt(0)?.toUpperCase() + item?.weather?.slice(1)}</Text>
                                <Text style={{ ...styles.width25 }}>
                                    {item?.temperature}
                                    <Text style={{ fontSize: 8, verticalAlign: 'super' }}>°C</Text>
                                </Text>
                                <Text style={{ width: '50%', }}>{item?.note ?? '-'}</Text>
                            </View>

                        })
                    }
                </View>
                {/* Performance Result */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10 }}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={noteIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text>Performance Result</Text>
                    </View>
                </View>
                {/* Performance Result Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={styles.flex}>
                        <Text style={styles.secondaryColor}>Note</Text>
                    </View>
                </View>
                {/* Performance Result Lists*/}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    {
                        data?.pdfListData?.performanceResults?.map((item, index) => {
                            return <View style={{ display: 'flex' }} key={index}>
                                <Text style={{ padding: '10 0' }}>{item?.note}</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, rowGap: 5 }}>
                                    {
                                        item?.photos?.map((url, index) => {
                                            return <View key={index} style={{ width: '19%' }}>
                                                <Image style={{ width: 80, height: 80, borderRadius: 8 }} src={`${process.env.REACT_APP_API_URI}/construction-diary/performance-results/${url}`} />
                                            </View>
                                        })
                                    }
                                </View>
                            </View>
                        })
                    }
                </View>
                {/* Disabilities */}
                <View style={{ ...styles.section, borderTop: 0 }} minPresenceAhead={100}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={noteIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text>Disabilities</Text>
                    </View>
                </View>
                {/* Disabilities Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={styles.flex}>
                        <Text style={styles.secondaryColor}>Note</Text>
                    </View>
                </View>
                {/* Disabilities Lists*/}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }} >
                    {
                        data?.pdfListData?.disabilities?.map((item, index) => {
                            return <View style={{ display: 'flex' }} key={index}>
                                <Text style={{ padding: '10 0' }}>{item.note}</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, rowGap: 5 }}>
                                    {
                                        item?.photos?.map((url, index) => {
                                            return <View key={index} style={{ width: '19%' }}>
                                                <Image style={{ width: 80, height: 80, borderRadius: 8 }} src={`${process.env.REACT_APP_API_URI}/construction-diary/disabilities/${url}`} />
                                            </View>
                                        })
                                    }
                                </View>
                            </View>

                        })
                    }
                </View>

                {/* Performance Changes */}
                <View style={{ ...styles.section, borderTop: 0 }}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={noteIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text>Performance Changes</Text>
                    </View>
                </View>
                {/* Performance Changes Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={styles.flex}>
                        <Text style={styles.secondaryColor}>Note</Text>
                    </View>
                </View>
                {/* Performance Changes Lists*/}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    {
                        data?.pdfListData?.performanceChanges?.map((item, index) => {
                            return <View style={{ display: 'flex' }} key={index}>
                                <Text style={{ padding: '10 0' }}>{item.note}</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, rowGap: 5 }}>
                                    {
                                        item?.photos?.map((url, index) => {
                                            return <View key={index} style={{ width: '19%' }}>
                                                <Image style={{ width: 80, height: 80, borderRadius: 8 }} src={`${process.env.REACT_APP_API_URI}/construction-diary/performance-changes/${url}`} />
                                            </View>
                                        })
                                    }
                                </View>
                            </View>

                        })
                    }
                </View>

                {/* Conformation Diary */}
                <View style={{ ...styles.section, borderTop: 0 }} minPresenceAhead={200}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={diaryIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text>Conformation Diary</Text>
                    </View>
                </View>

                {/* Conformation Diary Signature */}
                <View style={{ ...styles.section, borderBottom: 0, borderTop: 0 }}>
                    <View style={{ ...styles.flex, gap: 16 }}>
                        <Text style={{ width: '50%' }}>Signature of the Builders  / Building owners</Text>
                        <Text style={{ width: '50%', textAlign: 'center' }}>Signature of the construction manager</Text>
                    </View>
                </View>
                {/* Conformation Diary Signature Sign */}
                <View style={{ ...styles.section, borderTop: 0, marginTop: -50 }}>
                    <View style={{ ...styles.flex, gap: 16 }}>
                        <View style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                            {data?.pdfListData?.builderSignature &&
                                <Image src={`${process.env.REACT_APP_API_URI}/construction-diary/signatures/${data?.pdfListData?.builderSignature}`} style={{ width: 120, height: 80, marginTop: 20 }} />
                            }
                        </View>
                        <View style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                            {data?.pdfListData?.constructionManagerSignature &&
                                <Image style={{ width: 120, height: 80, marginTop: 20 }} src={`${process.env.REACT_APP_API_URI}/construction-diary/signatures/${data?.pdfListData?.constructionManagerSignature}`} />
                            }
                        </View>
                    </View>
                </View>
            </Page>

            <Page style={styles.page} size="A4">
                {/* Photos */}
                <View style={{ ...styles.section, padding: 10, borderTop: 0 }}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={galleryIocn} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text>Photos</Text>
                    </View>
                </View>

                {/* Performance Result Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={{ ...styles.flex, gap: 16 }}>
                        <Text>Performance Result</Text>
                    </View>
                </View>
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 10, paddingRight: 10 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 20 }} >
                        {
                            data?.pdfListData?.performanceResults?.map((item) =>
                                item?.photos?.map((url, index) => (
                                    <View
                                        key={index}
                                        style={{
                                            width: '48%',
                                            height: 220,
                                        }}
                                    >
                                        <Image
                                            style={{ width: '100%', height: '100%' }}
                                            src={`${process.env.REACT_APP_API_URI}/construction-diary/performance-results/${url}`}
                                        />
                                    </View>
                                ))
                            )
                        }
                    </View>
                </View>
                {/* Disabilities Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }} minPresenceAhead={200}>
                    <View style={{ ...styles.flex, gap: 16 }}>
                        <Text>Disabilities</Text>
                    </View>
                </View>
                <View style={{ ...styles.section, zIndex: -1, borderTop: 0, padding: 10, paddingLeft: 10, paddingRight: 10 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 20 }} >
                        {
                            data?.pdfListData?.disabilities?.map((item) =>
                                item?.photos?.map((url, index) => (
                                    <View
                                        key={index}
                                        style={{
                                            width: '48%',
                                            height: 220,
                                        }}
                                    >
                                        <Image
                                            style={{ width: '100%', height: '100%' }}
                                            src={`${process.env.REACT_APP_API_URI}/construction-diary/disabilities/${url}`}
                                        />
                                    </View>
                                ))
                            )
                        }
                    </View>
                </View>

                {/* Performance Changes Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }} minPresenceAhead={200}>
                    <View style={{ ...styles.flex }}>
                        <Text>Performance Changes</Text>
                    </View>
                </View>
                <View style={{ ...styles.section, zIndex: -1, borderTop: 0, padding: 10, paddingLeft: 10, paddingRight: 10 }} minPresenceAhead={280}>
                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 20 }}>
                        {
                            data?.pdfListData?.performanceChanges?.map((item) =>
                                item?.photos?.map((url, index) => (
                                    <View
                                        key={index}
                                        style={{
                                            width: '48%',
                                            height: 220,
                                        }}
                                    >
                                        <Image
                                            style={{ width: '100%', height: '100%' }}
                                            src={`${process.env.REACT_APP_API_URI}/construction-diary/performance-changes/${url}`}
                                        />
                                    </View>
                                ))
                            )
                        }
                    </View>
                </View>
            </Page>
        </Document >
    );
}

// Main Document Component
const ConstructionDiaryApiPDF = ({ data }) => (
    <PageComponent data={data} />
);

export default ConstructionDiaryApiPDF;
