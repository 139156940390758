import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';
// import { notification } from 'antd';

const initialState = {
    userTypesList: [],
    userType: {},
    userTypeMenuListing: [],
    totalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'userType',
    initialState,
    reducers: {
        getUserTypesList: (state, action) => {
            state.userType = {};
            state.isUpdating = false;
            state.userTypesList = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.userType = {};
            state.isSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        setUserTypeMenuListing: (state, action) => {
            state.userTypeMenuListing = action.payload;
        },
        getUserType: (state, action) => {
            state.userType = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        }
    },
});

export const getUserTypeMenuListing = createAsyncThunk('getUserTypeMenuListing', (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(id ? `menu/package/options?id=${id}` : `menu/package/options?id=`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(setUserTypeMenuListing(response.data.records))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getUserTypesListData = createAsyncThunk('getUserTypesListData', (filterData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/usertype/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getUserTypesList(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getUserTypesByOrganizerListData = createAsyncThunk('getUserTypesByOrganizerListData', (filterData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/usertype/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getUserTypesList(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createUserType = createAsyncThunk('createUserType', (userTypeData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/usertype/insert`, userTypeData?.userTypeData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getUserTypesListData({ search: '', sortBy: '', order: '', page: 1, limit: 10, organizer: null }))
            dispatch(getUserTypesByOrganizerListData({ search: '', sortBy: '', order: '', page: 1, limit: 10, organizer: userTypeData?.organizerID }))
            dispatch(setIsSuccess(true))
            openNotification({ message: response.data.message, type: 'success' });
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdUserType = createAsyncThunk('getByIdUserType', (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/usertype?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getUserType(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updateUserType = createAsyncThunk('updateUserType', (userTypeData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/usertype/update`, userTypeData?.userTypeData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getUserTypesListData({ search: '', sortBy: '', order: '', page: 1, limit: 10, organizer: null }))
            dispatch(getUserTypesByOrganizerListData({ search: '', sortBy: '', order: '', page: 1, limit: 10, organizer: userTypeData?.organizerID }))
            dispatch(setIsSuccess(true))
            openNotification({ message: response.data.message, type: 'success' });
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteUserType = createAsyncThunk('deleteUserType', (object, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/usertype/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getUserTypesListData(object))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getUserType, setIsUpdating, setIsSuccess, getUserTypesList, setTotalrecords, setUserTypeMenuListing } = slice.actions;

export default slice.reducer;
