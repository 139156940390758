import React, { Fragment, useEffect, useState } from 'react';

import { Button, Flex, Form, Row, Col, Input, Typography, Select, Divider } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

import { getPackagesListing, getUserTypeListing } from '../../redux/slice/global';
import { createUser, getByIdUser, getUser, setIsSuccess, setIsUpdating, updateUser } from './redux/slice';

import BackIcon from '../../components/icon/BackIcon';

import '../../styles/pages/profile-settings.scss';
import { getDecodeToken } from '../auth/redux/slice';

const { Title, Text } = Typography;

const PlatformAddUpdateUser = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { id } = useParams();
    const { user, listing } = useSelector(state => state);
    const [loginUser, setLoginUser] = useState({});
    const [modalType, setModalType] = useState(false);
    const [userTypesList, setUserTypesList] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const user = jwtDecode(token);
            dispatch(getDecodeToken(user))
            setLoginUser(user);
        }
    }, [])

    const getPackageLists = () => {
        dispatch(getPackagesListing());
    };

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getUser({
            ...values,
            address: { streetNo: values?.streetNo, city: values?.city, zip: values?.zip },
        }));
    }

    useEffect(() => {
        getPackageLists();
        dispatch(getUserTypeListing());
    }, [])

    useEffect(() => {
        if (listing.listing?.userType?.length > 0) {
            setUserTypesList(listing.listing.userType);
        }
    }, [listing.listing.userType, loginUser])

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsUpdating(true));
            dispatch(getByIdUser(id));
        }
    }, [id]);

    useEffect(() => {
        if (Object.keys(user.user).length > 0) {
            if (!modalType) {
                dispatch(createUser(user.user));
            } else {
                if (user.isUpdating) {
                    form.setFieldsValue({ ...user.user, streetNo: user?.user?.address?.streetNo, city: user?.user?.address?.city, zip: user?.user?.address?.zip });
                } else {
                    dispatch(updateUser({ updatedUser: { ...user.user, id: id }, isProfile: false }));
                }
            }
        }
        if (user.isSuccess) {
            handleClear();
            navigate('/user');
            setModalType(false);
            dispatch(setIsSuccess(false));
        }
    }, [user.isUpdating, user.user, user.isSuccess]);

    const handleClear = () => {
        form.resetFields();
    };

    return (
        <Fragment>
            <Flex vertical gap={24} className='user-management general-settings'>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate('/user')}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2'>
                    <Flex>
                        <Title level={4} type='success'>{id ? t('superadmin_usermanagement_user_editUser') : t('superadmin_usermanagement_user_addUser')}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='user'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Fragment>
                            <Row gutter={18}>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='firstName'
                                        label={t('superadmin_common_firstname')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('superadmin_common_firstnameerror'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('superadmin_common_firstname')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='lastName'
                                        label={t('superadmin_common_lastname')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('superadmin_common_lastnameerror'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('superadmin_common_lastname')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='phone'
                                        label={t('superadmin_common_phoneNo')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('superadmin_common_phoneNoerror'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('superadmin_common_phoneNo')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='email'
                                        label={t('superadmin_common_email')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('superadmin_common_emailerror'),
                                            },
                                            {
                                                type: 'email',
                                                message: t('superadmin_common_emailinvaliderror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('superadmin_common_email')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='userType'
                                        label={t('superadmin_common_usertype')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('superadmin_common_usertypeerror'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder={t('superadmin_common_usertype')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={userTypesList}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Title level={5} className='padding-bottom-1'>{t('superadmin_common_address')}</Title>
                            <Row gutter={18}>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='streetNo'
                                        label={t('superadmin_common_streetName')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('superadmin_common_streetnameerror'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            showCount={false}
                                            placeholder={t('superadmin_common_streetName')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='city'
                                        label={t('superadmin_common_city')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('superadmin_common_cityerror'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('superadmin_common_city')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='zip'
                                        label={t('superadmin_common_zip')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('superadmin_common_ziperror'),
                                            },
                                            {
                                                pattern: new RegExp(/^[0-9]+$/),
                                                message: t('superadmin_common_zipinvaliderror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('superadmin_common_zip')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Fragment>
                        <Flex justify='flex-end' gap={10} wrap={true}>
                            <Button danger type='primary' htmlType='button' onClick={() => navigate(-1)}>{t('superadmin_common_cancelButton')}</Button>
                            <Button type='primary' htmlType='submit' loading={user?.loading}>{t('superadmin_common_submitButton')}</Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default PlatformAddUpdateUser;
