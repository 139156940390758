import * as React from 'react';

const SunIcon = ({
    size = 24,
    color = '#000000',
    role = 'default',
    style = {},
    ...Rest
}) => {
    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styles}
            {...Rest}
        >
            <path
                d="M9.99967 15.4173C12.9912 15.4173 15.4163 12.9922 15.4163 10.0007C15.4163 7.00911 12.9912 4.58398 9.99967 4.58398C7.00813 4.58398 4.58301 7.00911 4.58301 10.0007C4.58301 12.9922 7.00813 15.4173 9.99967 15.4173Z"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9503 15.9493L15.842 15.841M15.842 4.15768L15.9503 4.04935L15.842 4.15768ZM4.05033 15.9493L4.15866 15.841L4.05033 15.9493ZM10.0003 1.73268V1.66602V1.73268ZM10.0003 18.3327V18.266V18.3327ZM1.73366 9.99935H1.66699H1.73366ZM18.3337 9.99935H18.267H18.3337ZM4.15866 4.15768L4.05033 4.04935L4.15866 4.15768Z"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
export default SunIcon;