import React, { Fragment, useEffect, useState } from 'react';

import { Button, Flex, Form, Row, Col, Input, Typography, Select, Divider, InputNumber } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

import { createClient, getClient, setIsUpdating as clientIsUpdating, setIsSuccess as clientIsSuccess, updateClient, getByIdClient } from '../administrator/redux/slice';
import { getDesignationListing, getPackagesListing, getUserTypeByIDListing } from '../../redux/slice/global';
import { getDecodeToken } from '../auth/redux/slice';

import BackIcon from '../../components/icon/BackIcon';

import '../../styles/pages/profile-settings.scss';

const { Title, Text } = Typography;

const ClientAddUpdateUser = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { id } = useParams();
    const { listing, auth, client } = useSelector(state => state);
    const [loginUser, setLoginUser] = useState({});
    const [modalType, setModalType] = useState(false);
    const [userTypesByIDList, setUserTypesByIDList] = useState([]);
    const [designationListing, setDesignationListing] = useState([]);
    const { t } = useTranslation()

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            dispatch(getDecodeToken(user))
            setLoginUser(user);
        }
    }, [])

    const getPackageLists = () => {
        dispatch(getPackagesListing());
    };

    useEffect(() => {
        if (listing.listing?.designation?.length > 0) {
            setDesignationListing(listing.listing?.designation)
        }
    }, [listing.listing.designation]);

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(clientIsUpdating(false));
        }
        dispatch(getClient({
            ...values,
            isImmutable: client?.client?.isImmutable ? client?.client?.isImmutable : false,
            organizer: id ? client?.client?.organizer : auth?.decodeToken?.organizer,
            package: id ? client?.client?.package : auth?.decodeToken?.package,
            userLimit: 0,
            address: { streetNo: values?.streetNo, city: values?.city, zip: values?.zip }
        }));
    }

    useEffect(() => {
        getPackageLists();
        if (loginUser?.organizer) {
            dispatch(getUserTypeByIDListing(loginUser?.organizer));
        }

        if (loginUser?.organizer) {
            dispatch(getDesignationListing(loginUser?.organizer))
        }
    }, [loginUser])

    useEffect(() => {
        if (listing.listing?.userTypeByID?.length > 0) {
            setUserTypesByIDList(listing.listing.userTypeByID);
        }
    }, [listing.listing.userTypeByID, loginUser])

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(clientIsUpdating(true))
            dispatch(getByIdClient(id));
        }
    }, [id]);

    useEffect(() => {
        if (Object.keys(client.client).length > 0) {
            if (!modalType) {
                dispatch(createClient(client.client));
            } else {
                if (client.isUpdating) {
                    form.setFieldsValue({ ...client.client, streetNo: client?.client?.address?.streetNo, city: client?.client?.address?.city, zip: client?.client?.address?.zip });
                } else {
                    dispatch(updateClient({ ...client.client, id: id }));
                }
            }
        }
        if (client.isSuccess) {
            handleClear();
            navigate('/user');
            setModalType(false);
            dispatch(clientIsSuccess(false));
        }
    }, [client.isSuccess, client?.client, client.isUpdating]);

    const handleClear = () => {
        form.resetFields();
    };

    return (
        <Fragment>
            <Flex vertical gap={24} className='user-management general-settings'>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate('/user')}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2'>
                    <Flex>
                        <Title level={4} type='success'>{id ? t('organizer_usermanagement_user_editUser') : t('organizer_usermanagement_user_addUser')}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='user'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Fragment>
                            <Row gutter={16}>
                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Row gutter={24}>
                                        {
                                            auth?.decodeToken?._id !== auth?.decodeToken?.organizer &&
                                            <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24} >
                                                <Form.Item
                                                    name='company'
                                                    label={t('organizer_common_companyname')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('organizer_common_companynameerror')
                                                        }
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder={t('organizer_common_companyname')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        }
                                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='firstName'
                                                label={t('organizer_common_firstname')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('organizer_common_firstnameerror'),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={t('organizer_common_firstname')}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='lastName'
                                                label={t('organizer_common_lastname')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('organizer_common_lastnameerror'),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={t('organizer_common_lastname')}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='phone'
                                                label={t('organizer_common_phoneNo')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('organizer_common_phoneNoerror'),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={t('organizer_common_phoneNo')}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='email'
                                                label={t('organizer_common_email')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('organizer_common_emailerror'),
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: t('organizer_common_emailinvaliderror')
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder={t('organizer_common_email')}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='designation'
                                                label={t('organizer_common_designation')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('organizer_common_designationerror')
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    placeholder={t('organizer_common_designation')}
                                                    options={designationListing}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='userType'
                                                label={t('organizer_common_usertype')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('organizer_common_usertypeerror')
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    placeholder={t('organizer_common_usertype')}
                                                    options={userTypesByIDList}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='employeeType'
                                                label={t('organizer_common_employeetype')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('organizer_common_employeetypeerror')
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    placeholder={t('organizer_common_employeetype')}
                                                    options={[
                                                        {
                                                            value: 'internal',
                                                            label: t('organizer_common_internal')
                                                        },
                                                        {
                                                            value: 'external',
                                                            label: t('organizer_common_external')
                                                        }
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='holiday'
                                                label={t('organizer_common_holliday')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('organizer_common_holidayerror')
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder={t('organizer_common_holliday')}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Title level={5} className='padding-bottom-1'>{t('organizer_common_address')}</Title>
                            <Row gutter={18}>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='streetNo'
                                        label={t('organizer_common_streetName')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_streetnameerror'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            showCount={false}
                                            placeholder={t('organizer_common_streetName')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='city'
                                        label={t('organizer_common_city')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_cityerror'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_city')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='zip'
                                        label={t('organizer_common_zip')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_ziperror'),
                                            },
                                            {
                                                pattern: new RegExp(/^[0-9]+$/),
                                                message: t('organizer_common_zipinvaliderror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_zip')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Title level={5} className='padding-bottom-2'>{t('organizer_common_shiftmanagement')}</Title>
                            <Row gutter={24}>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='workType'
                                        label={t('organizer_common_worktype')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_worktypeerror')
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            placeholder={t('organizer_common_worktype')}
                                            options={[
                                                {
                                                    value: 'full time',
                                                    label: t('organizer_common_fulltime')
                                                },
                                                {
                                                    value: 'part time',
                                                    label: t('organizer_common_parttime')
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='weeklyWorkHours'
                                        label={t('organizer_common_weeklyhours')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_weeklyhourserror')
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            className='w-100'
                                            placeholder={t('organizer_common_weeklyhours')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Fragment>
                        <Flex justify='flex-end' gap={10} wrap={true}>
                            <Button danger type='primary' htmlType='button' onClick={() => navigate(-1)}>{t('organizer_common_cancelButton')}</Button>
                            <Button type='primary' htmlType='submit' loading={client?.loading}>{t('organizer_common_submitButton')}</Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default ClientAddUpdateUser;
