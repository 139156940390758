import React, { Fragment, useEffect, useState } from 'react';

import { Button, Card, Checkbox, Col, Collapse, Divider, Flex, Form, Input, Row, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

import { createUserType, getByIdUserType, getUserType, setIsSuccess, setIsUpdating, updateUserType } from './redux/slice';
import { getByIdPackage } from '../packages/redux/slice';
import { getDecodeToken } from '../auth/redux/slice';

import BackIcon from '../../components/icon/BackIcon';
import ExpandIcon from '../../components/icon/ExpandIcon';
import openNotification from '../../components/notification';

const { Title, Text } = Typography;

const UpdateUserType = () => {
    const [form] = Form.useForm();
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, userType, auth } = useSelector(state => state)
    const [selectAll, setSelectAll] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [permissionMenu, setPermissionMenu] = useState([])
    const [permissionsState, setPermissionsState] = useState([]);
    const [loginUser, setLoginUser] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const user = jwtDecode(token);
            dispatch(getDecodeToken(user))
            setLoginUser(user);
        }
    }, [])

    useEffect(() => {
        setPermissionMenu(userType.userTypeMenuListing)
        if (!id) {
            setPermissionsState(userType.userTypeMenuListing)
        }
    }, [userType.userTypeMenuListing])

    useEffect(() => {
        if (id) {
            dispatch(setIsUpdating(true))
            setModalType(true)
            dispatch(getByIdUserType(id))
            if (user.loginUserPackage) {
                dispatch(getByIdPackage(user.loginUserPackage))
            }
        }
    }, [id])

    useEffect(() => {
        if (Object.keys(userType.userType).length > 0) {
            if (!modalType) {
                dispatch(createUserType({ userTypeData: userType?.userType, organizerID: loginUser?.organizer }));
            } else {
                if (userType.isUpdating) {
                    form.setFieldsValue(userType.userType);
                    setPermissionsState(
                        userType?.userType.menu?.length > 0
                            ? permissionMenu?.map(item => {
                                const userMenuPermissions = userType?.userType?.menu?.find(i => i.menu === item.menu)?.permissions || {};

                                return {
                                    ...item,
                                    permissions: {
                                        hasAccess: userMenuPermissions.hasAccess || (item?.name === 'Settings') ? true : false,
                                        canCreate: userMenuPermissions.canCreate || item?.name === 'Settings' ? true : false,
                                        canUpdate: userMenuPermissions.canUpdate || item?.name === 'Settings' ? true : false,
                                        canDelete: userMenuPermissions.canDelete || item?.name === 'Settings' ? true : false
                                    },
                                    children: item?.children?.map(child => {
                                        const parentMenuItem = userType?.userType?.menu?.find(parentItem => parentItem?.menu === item?.menu);
                                        if (userType?.userType?.menu?.some(i => i.menu === item.menu) && parentMenuItem?.children?.length > 0) {
                                            const matchingChildItem = parentMenuItem?.children?.find(childItem => childItem?.menu === child?.menu)?.permissions;

                                            return {
                                                ...child,
                                                permissions: {
                                                    hasAccess: (userMenuPermissions?.hasAccess && matchingChildItem?.hasAccess) || child?.name === 'General Setting' ? true : false,
                                                    canCreate: (userMenuPermissions?.hasAccess && matchingChildItem?.canCreate) || child?.name === 'General Setting' ? true : false,
                                                    canUpdate: (userMenuPermissions?.hasAccess && matchingChildItem?.canUpdate) || child?.name === 'General Setting' ? true : false,
                                                    canDelete: (userMenuPermissions?.hasAccess && matchingChildItem?.canDelete) || child?.name === 'General Setting' ? true : false
                                                }
                                            };
                                        } else {
                                            // Default permissions for child if the condition is not match
                                            return {
                                                ...child,
                                                permissions: {
                                                    hasAccess: false,
                                                    canCreate: false,
                                                    canUpdate: false,
                                                    canDelete: false
                                                }
                                            };
                                        }
                                    })
                                };
                            })
                            : permissionMenu?.map(item => ({
                                ...item,
                                permissions: {
                                    hasAccess: false,
                                    canCreate: false,
                                    canUpdate: false,
                                    canDelete: false
                                },
                                children: item.children?.map(child => ({
                                    ...child,
                                    permissions: {
                                        hasAccess: false,
                                        canCreate: false,
                                        canUpdate: false,
                                        canDelete: false
                                    }
                                }))
                            }))
                    );
                } else {
                    dispatch(updateUserType({ userTypeData: { ...userType.userType, id: id }, organizerID: loginUser.organizer }))
                }
            }
        }
        if (userType.isSuccess) {
            handleClear()
            dispatch(setIsSuccess(false))
            setModalType(false)
            navigate('/user/type')
        }
    }, [userType.userType, userType.isSuccess, userType.isUpdating])

    const handleOnFinish = (values) => {
        if (!permissionsState?.find(i => i?.name === 'Dashboard')?.permissions?.hasAccess) {
            openNotification({ message: 'Please give permission for dashboard!', type: 'error' });
        } else {
            if (id) {
                dispatch(setIsUpdating(false));
            }
            dispatch(getUserType({
                ...values,
                organizer: auth?.decodeToken?.organizer ? auth?.decodeToken?.organizer : null,
                menu: permissionsState.map(menuItem => ({
                    menu: menuItem.menu,
                    permissions: menuItem.permissions,
                    children: menuItem.children.map(child => ({
                        menu: child.menu,
                        permissions: child.permissions,
                    })),
                }))
            }));
        }
    };

    const handleClear = () => {
        form.resetFields();
        setModalType(false)
        setPermissionsState(
            permissionMenu?.map(item => ({
                ...item,
                permissions: {
                    hasAccess: false,
                    canCreate: false,
                    canUpdate: false,
                    canDelete: false
                },
                children: item.children?.map(child => ({
                    ...child,
                    permissions: {
                        hasAccess: false,
                        canCreate: false,
                        canUpdate: false,
                        canDelete: false
                    }
                }))
            }))
        );
        setSelectAll(false);
        navigate(-1)
    };

    const renderCollapseItem = (item, ind, handleChange) => {
        return [{
            key: `${ind + 1}`,
            label: (
                <Row>
                    <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24}>
                        <Text>{item.name}</Text>
                    </Col>
                    <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                        <Flex wrap gap={16} align='center' className='h-100'>
                            <Checkbox
                                checked={item.permissions?.hasAccess}
                                disabled={(auth?.decodeToken?.organizer ? (item.name === 'dashboard' || item.name === "Settings") : (item.name === 'dashboard' || item.name === "Settings")) || userType?.userType?.isReadOnly}
                                onChange={(e) => handleChange(e, item.menu, 'hasAccess')}
                            >{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_access') : t('superadmin_usermanagement_usertype_access')}</Checkbox>
                            <Checkbox
                                checked={item.permissions?.canCreate}
                                disabled={(auth?.decodeToken?.organizer ? (item.name === 'dashboard' || item.name === "Settings") : (item.name === 'dashboard' || item.name === "Settings")) || userType?.userType?.isReadOnly}
                                onChange={(e) => handleChange(e, item.menu, 'canCreate')}
                            >{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_addnew') : t('superadmin_usermanagement_usertype_addnew')}</Checkbox>
                            <Checkbox
                                checked={item.permissions?.canUpdate}
                                disabled={(auth?.decodeToken?.organizer ? (item.name === 'dashboard' || item.name === "Settings") : (item.name === 'dashboard' || item.name === "Settings")) || userType?.userType?.isReadOnly}
                                onChange={(e) => handleChange(e, item.menu, 'canUpdate')}
                            >{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_edit') : t('superadmin_usermanagement_usertype_edit')}</Checkbox>
                            <Checkbox
                                checked={item.permissions?.canDelete}
                                disabled={(auth?.decodeToken?.organizer ? (item.name === 'dashboard' || item.name === "Settings") : (item.name === 'dashboard' || item.name === "Settings")) || userType?.userType?.isReadOnly}
                                onChange={(e) => handleChange(e, item.menu, 'canDelete')}
                            >{auth?.decodeToken?.package ? t('organizer_common_deleteButton') : t('superadmin_common_deleteButton')}</Checkbox>
                        </Flex>
                    </Col>
                </Row >
            ),
            children: item?.children?.length > 0
                ? item.children.map((x, index) => {
                    return (
                        <Row key={index}>
                            <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24}>
                                <Text className='paddingX-1'>{x.name}</Text>
                            </Col>
                            <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                                <Flex wrap gap={16} align='center' className='h-100 paddingX-3'>
                                    <Checkbox
                                        checked={x.permissions?.hasAccess}
                                        disabled={userType?.userType?.isReadOnly || x.name === "General Setting"}
                                        onChange={(e) => handleChange(e, x.menu, 'hasAccess')}
                                    >{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_access') : t('superadmin_usermanagement_usertype_access')}</Checkbox>
                                    <Checkbox
                                        checked={x.permissions?.canCreate}
                                        disabled={userType?.userType?.isReadOnly || x.name === "General Setting"}
                                        onChange={(e) => handleChange(e, x.menu, 'canCreate')}
                                    >{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_addnew') : t('superadmin_usermanagement_usertype_addnew')}</Checkbox>
                                    <Checkbox
                                        checked={x.permissions?.canUpdate}
                                        disabled={userType?.userType?.isReadOnly || x.name === "General Setting"}
                                        onChange={(e) => handleChange(e, x.menu, 'canUpdate')}
                                    >{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_edit') : t('superadmin_usermanagement_usertype_edit')}</Checkbox>
                                    <Checkbox
                                        checked={x.permissions?.canDelete}
                                        disabled={userType?.userType?.isReadOnly || x.name === "General Setting"}
                                        onChange={(e) => handleChange(e, x.menu, 'canDelete')}
                                    >{auth?.decodeToken?.package ? t('organizer_common_deleteButton') : t('superadmin_common_deleteButton')}</Checkbox>
                                </Flex>
                            </Col>
                        </Row>
                    );
                })
                : [],
            showArrow: item?.children?.length > 0
        }];
    }

    const handleChange = (e, id, permissionType) => {
        const { checked } = e.target;

        const updatePermissions = (items) => {
            return items.map(item => {
                if (item.menu === id) {
                    const newPermissions = {
                        ...item.permissions,
                        [permissionType]: checked,
                        // Ensure hasAccess is set when any specific permission is selected
                        hasAccess: permissionType !== 'hasAccess' && checked ? true : item.permissions.hasAccess,
                    };

                    // If selecting `hasAccess` on the parent, cascade it down to all children
                    if (permissionType === 'hasAccess' && checked) {
                        return {
                            ...item,
                            permissions: {
                                ...newPermissions,
                                hasAccess: true,
                            },
                            children: item.children?.map(child => ({
                                ...child,
                                permissions: {
                                    ...child.permissions,
                                    hasAccess: true,
                                },
                            })),
                        };
                    }

                    // If unchecking `hasAccess` on the parent, clear all permissions on children
                    if (permissionType === 'hasAccess' && !checked) {
                        return {
                            ...item,
                            permissions: {
                                hasAccess: false,
                                canCreate: false,
                                canUpdate: false,
                                canDelete: false,
                            },
                            children: item.children?.map(child => ({
                                ...child,
                                permissions: {
                                    hasAccess: false,
                                    canCreate: false,
                                    canUpdate: false,
                                    canDelete: false,
                                },
                            })),
                        };
                    }

                    return {
                        ...item,
                        permissions: newPermissions,
                        children: item.children?.map(child => ({
                            ...child,
                            permissions: {
                                ...child.permissions,
                                [permissionType]: checked,
                                hasAccess: permissionType !== 'hasAccess' && checked ? true : child.permissions.hasAccess,
                            },
                        })),
                    };
                } else if (item.children?.length > 0) {
                    const updatedChildren = updatePermissions(item.children, item);

                    // Determine if the parent should keep each permission based on its children
                    const anyChildHasAccess = updatedChildren.some(child => child.permissions.hasAccess);
                    const anyChildCanCreate = updatedChildren.some(child => child.permissions.canCreate);
                    const anyChildCanUpdate = updatedChildren.some(child => child.permissions.canUpdate);
                    const anyChildCanDelete = updatedChildren.some(child => child.permissions.canDelete);

                    return {
                        ...item,
                        permissions: {
                            hasAccess: anyChildHasAccess,
                            canCreate: anyChildCanCreate,
                            canUpdate: anyChildCanUpdate,
                            canDelete: anyChildCanDelete,
                        },
                        children: updatedChildren,
                    };
                }

                return item;
            });
        };

        setPermissionsState(prevState => updatePermissions(prevState));
    };

    const handleSelectAllChange = (e) => {
        const { checked } = e.target;

        const updateAllPermissions = (items) => {
            return items?.map(item => ({
                ...item,
                permissions: {
                    hasAccess: item.name === 'Dashboard' && auth?.decodeToken?.organizer ? true : checked,
                    canCreate: item.name === 'Dashboard' && auth?.decodeToken?.organizer ? true : checked,
                    canUpdate: item.name === 'Dashboard' && auth?.decodeToken?.organizer ? true : checked,
                    canDelete: item.name === 'Dashboard' && auth?.decodeToken?.organizer ? true : checked
                },
                children: item.children?.map(child => ({
                    ...child,
                    permissions: {
                        hasAccess: checked,
                        canCreate: checked,
                        canUpdate: checked,
                        canDelete: checked
                    }
                }))
            }));
        };

        setSelectAll(checked);
        setPermissionsState(updateAllPermissions(permissionsState));
    };

    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    return (
        <Fragment>
            <Flex vertical gap={12} className='user-management'>
                <Button
                    type='text'
                    icon={<BackIcon role={'button'} />}
                    className='bg-white paddingX-3'
                    onClick={() => {
                        handleClear()
                        navigate('/user/type')
                    }}
                />
                <Flex>
                    <Card bordered={false} className='w-100'>
                        <Flex vertical gap={24}>
                            <Title level={3} type='success'>{id ? auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_editusertype') : t('superadmin_usermanagement_usertype_editusertype') : auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_addUserType') : t('superadmin_usermanagement_usertype_addUserType')}</Title>
                            <Form
                                form={form}
                                className='user-filter'
                                name='user'
                                colon={false}
                                layout='vertical'
                                disabled={userType?.userType?.isReadOnly}
                                onFinish={handleOnFinish}
                                requiredMark={(label, isRule) => {
                                    return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                                }}
                            >
                                <Row gutter={18}>
                                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='name'
                                            label={auth?.decodeToken?.package ? t('organizer_common_usertype') : t('superadmin_common_usertype')}
                                            rules={[{ required: true, message: auth?.decodeToken?.package ? t('organizer_common_usertypeerror') : t('superadmin_common_usertypeerror') }]}
                                        >
                                            <Input placeholder={auth?.decodeToken?.package ? t('organizer_common_usertype') : t('superadmin_common_usertype')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Title level={4} type='danger'>{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_permissionsTitle') : t('superadmin_usermanagement_usertype_permissionsTitle')}</Title>

                                <Flex gap={64} align='center' className='paddingY-3'>
                                    <Title level={5}>{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_access') : t('superadmin_usermanagement_usertype_access')}</Title>
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={handleSelectAllChange}
                                    >
                                        {auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_selectall') : t('superadmin_usermanagement_usertype_selectall')}
                                    </Checkbox>
                                </Flex>

                                <Divider className='margin-0' />
                                {
                                    permissionsState?.map((i, ind) => (
                                        i?.children?.length > 0
                                            ? <Fragment key={ind}>
                                                <Collapse
                                                    key={ind}
                                                    items={renderCollapseItem(i, ind, handleChange)}
                                                    bordered={false}
                                                    collapsible='icon'
                                                    expandIcon={customExpandIcon}
                                                />
                                                <Divider className='margin-0' />
                                            </Fragment>
                                            : <Fragment key={ind}>
                                                <Row className='paddingY-1'>
                                                    <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24} className='paddingY-1'>
                                                        <Text className='paddingX-3'>{i.name}</Text>
                                                    </Col>
                                                    <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                                                        <Flex wrap gap={16} align='center' className='h-100 paddingX-3'>
                                                            <Checkbox
                                                                checked={i.permissions?.hasAccess}
                                                                disabled={(auth?.decodeToken?.organizer ? i.name === 'Dashboard' : i.name === 'Dashboard') || userType?.userType?.isReadOnly}
                                                                onChange={(e) => handleChange(e, i.menu, 'hasAccess')}
                                                            >
                                                                {auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_access') : t('superadmin_usermanagement_usertype_access')}
                                                            </Checkbox>
                                                            <Checkbox
                                                                checked={i.permissions?.canCreate}
                                                                disabled={(auth?.decodeToken?.organizer ? i.name === 'Dashboard' : i.name === 'Dashboard') || userType?.userType?.isReadOnly}
                                                                onChange={(e) => handleChange(e, i.menu, 'canCreate')}
                                                            >
                                                                {auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_addnew') : t('superadmin_usermanagement_usertype_addnew')}
                                                            </Checkbox>
                                                            <Checkbox
                                                                checked={i.permissions?.canUpdate}
                                                                disabled={(auth?.decodeToken?.organizer ? i.name === 'Dashboard' : i.name === 'Dashboard') || userType?.userType?.isReadOnly}
                                                                onChange={(e) => handleChange(e, i.menu, 'canUpdate')}
                                                            >
                                                                {auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_edit') : t('superadmin_usermanagement_usertype_edit')}
                                                            </Checkbox>
                                                            <Checkbox
                                                                checked={i.permissions?.canDelete}
                                                                disabled={(auth?.decodeToken?.organizer ? i.name === 'Dashboard' : i.name === 'Dashboard') || userType?.userType?.isReadOnly}
                                                                onChange={(e) => handleChange(e, i.menu, 'canDelete')}
                                                            >
                                                                {auth?.decodeToken?.package ? t('organizer_common_deleteButton') : t('superadmin_common_deleteButton')}
                                                            </Checkbox>
                                                        </Flex>
                                                    </Col>
                                                </Row>
                                                <Divider className='margin-0' />
                                            </Fragment>
                                    ))
                                }
                                <Flex justify='flex-end' gap={10} wrap={true}>
                                    <Button danger type='primary' htmlType='button' onClick={handleClear}
                                    >{auth?.decodeToken?.package ? t('organizer_common_cancelButton') : t('superadmin_common_cancelButton')}</Button>
                                    <Button type='primary' htmlType='submit' loading={userType?.loading && userType?.loading}
                                    >{auth?.decodeToken?.package ? t('organizer_common_submitButton') : t('superadmin_common_submitButton')}</Button>
                                </Flex>
                            </Form>
                        </Flex>
                    </Card>
                </Flex>
            </Flex >
        </Fragment >
    );
};

export default UpdateUserType;
