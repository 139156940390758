import React, { Fragment, useEffect, useState } from 'react';

import { Form, Button, Input, Upload, Col, Row, Select, DatePicker, Typography, Flex, Divider } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { createVacation, getByIdVacation, getLeaveDateLists, getVacation, getVacationListData, setIsVacationSuccess, setIsVacationUpdating, updateVacation } from './redux/slice';

import BackIcon from '../../components/icon/BackIcon';
import UploadIcon from '../../components/icon/UploadIcon';

import '../../styles/pages/new-vacation.scss';
import { getStammdatenUserListing } from '../../redux/slice/global';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const AddUpdateNewVacation = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { id } = useParams();
    const { profile, listing, auth } = useSelector(state => state);
    const [modalType, setModalType] = useState(false);
    const [leaveType, setLeaveType] = useState('');
    const [fileList, setFileList] = useState([]);
    const [document, setDocument] = useState({});
    const { t } = useTranslation();
    const [isCreated, setIsCreated] = useState(false);
    const [stammUserListing, setStammUserListing] = useState([]);
    const [leaveDateLists, setLeaveDateLists] = useState([]);

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(setIsVacationUpdating(false));
        }
        else {
            setIsCreated(true);
        }

        dispatch(getVacation({
            emergencyContactPerson: values?.emergencyContactPerson,
            leaveReason: values?.leaveReason,
            leaveType: values?.leaveType,
            leaveDateRange: {
                startDate: values?.date[0],
                endDate: values?.date[1]
            },
            organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer,
            user: auth?.decodeToken?._id,
            uploadedFile: document
        }));
    }

    useEffect(() => {
        getLeavesDate();
    }, [auth?.decodeToken?.organizer])

    const getLeavesDate = () => {
        auth?.decodeToken?.organizer && dispatch(getLeaveDateLists(auth?.decodeToken?.organizer))
    }

    useEffect(() => {
        setLeaveDateLists(profile?.leaveDateLists);
    }, [profile?.leaveDateLists])


    // Get Replacement contact person user list
    useEffect(() => {
        getStammUserLists();
    }, [auth?.decodeToken?.organizer])

    const getStammUserLists = () => {
        auth?.decodeToken?.organizer && dispatch(getStammdatenUserListing(auth?.decodeToken?.organizer));
    };

    useEffect(() => {
        if (listing.listing?.stammUser?.length > 0) {
            setStammUserListing(listing.listing?.stammUser)
        }
    }, [listing.listing.stammUser]);

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsVacationUpdating(true));
            dispatch(getByIdVacation(id));
        }
    }, [id]);

    // Create and Upadte Vacation
    useEffect(() => {
        if (Object.keys(profile.vacation ?? {}).length > 0) {

            const formData = new FormData();

            Object.entries(profile.vacation).forEach(([key, value]) => {
                if (typeof value === 'object' && value !== null && !(value instanceof File)) {
                    // Handle nested objects
                    Object.entries(value).forEach(([nestedKey, nestedValue]) => {
                        formData.append(`${key}[${nestedKey}]`, nestedValue);
                    });
                } else {
                    // Handle primitive values or File instances
                    formData.append(key, value);
                }
            });

            if (!modalType && isCreated) {
                dispatch(createVacation(formData));
            } else {
                if (profile.isVacationUpdating) {
                    form.setFieldsValue({ ...profile.vacation, date: [dayjs(profile.vacation?.leaveDateRange.startDate), dayjs(dayjs(profile.vacation?.leaveDateRange?.endDate).format('DD-MM-YYYY'), 'DD-MM-YYYY')] });
                    setFileList([
                        {
                            uid: '-1',
                            name: profile.vacation?.uploadedFile, // File name
                            status: 'done',
                            url: `${process.env.REACT_APP_API_URI}/vacation/${profile.vacation?.uploadedFile}`, // File URL
                        },
                    ])
                } else {
                    formData.append('id', id);
                    dispatch(updateVacation(formData));
                }
            }
        }
        if (profile.isVacationSuccess) {
            handleClear();
            navigate(-1);
            setModalType(false);
            dispatch(getVacation({}));
            setIsCreated(false);
            dispatch(setIsVacationSuccess(false));
        }
    }, [profile?.isVacationUpdating, profile?.vacation, profile?.isVacationSuccess]);

    const handleClear = () => {
        form.resetFields();
    };

    const handleLeaveChange = (e) => {
        setLeaveType(e);
    }

    const onUploadChange = ({ file, fileList }) => {
        // Keep only the last uploaded file in the list
        if (fileList.length > 1) {
            fileList.splice(0, fileList.length - 1);
        }

        // Update the file list state
        setFileList(fileList);
        setDocument(file?.originFileObj)

        // Manually trigger validation to clear the error if the file is valid
        form.setFields([
            {
                name: 'files',
                errors: [], // Clear any previous errors
            },
        ]);
    };

    const disabledDates = leaveDateLists.flatMap((leave) => {
        if (
            id &&
            dayjs(leave.startDate).isSame(dayjs(profile?.vacation?.leaveDateRange?.startDate), 'day') &&
            dayjs(leave.endDate).isSame(dayjs(profile?.vacation?.leaveDateRange?.endDate), 'day')
        ) {
            return []; // Exclude the current editing leave dates
        }

        const start = dayjs(leave.startDate);
        const end = dayjs(leave.endDate);
        const dates = [];
        let current = start;

        while (current <= end) {
            dates.push(current);
            current = current.add(1, 'day');
        }
        return dates;
    });

    const disabledDate = (current) => {
        return (
            current &&
            (current < dayjs().startOf('day') ||
                disabledDates.some(disabledDate => current.isSame(disabledDate, 'day'))
            )
        );
    };

    return (
        <Fragment>
            <Flex vertical gap={24} className='new-vacation'>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate(-1, { state: '2' })}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2'>
                    <Flex>
                        <Title level={4} type='success'>{id ? t('organizer_usermanagement_usertype_edit') + ' ' + t('organizer_vacationmanagement_leave') : t('organizer_profilesettings_addnewvaction')}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='vacation'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Fragment>
                            <Row gutter={18}>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='leaveType'
                                        label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type') + ' ' + t('organizer_common_isrequired'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={handleLeaveChange}
                                            options={[
                                                {
                                                    label: t('organizer_common_formal') + ' ' + t('organizer_vacationmanagement_leave'),
                                                    value: 'Formal Leave'
                                                },
                                                {
                                                    label: t('organizer_common_sick') + ' ' + t('organizer_vacationmanagement_leave'),
                                                    value: 'Sick Leave'
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='date'
                                        label={t('organizer_profilesettings_vacations_leavedaterange')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_daterangeerror'),
                                            },
                                        ]}
                                    >
                                        <RangePicker
                                            className='w-100'
                                            disabledDate={disabledDate}
                                            format={'DD-MM-YYYY'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='emergencyContactPerson'
                                        label={t('organizer_profilesettings_vacations_replacementcontactperson')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_profilesettings_vacations_replacementcontactperson') + ' ' + t('organizer_common_isrequired'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder={t('organizer_profilesettings_vacations_replacementcontactperson')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={stammUserListing}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='leaveReason'
                                        label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason') + ' ' + t('organizer_common_isrequired'),
                                            },
                                        ]}
                                    >
                                        <TextArea rows={3} placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason')} autoSize={{ minRows: 3, maxRows: 6 }} />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='files'
                                        label={t('organizer_common_uploadedfile')}
                                        rules={[{ required: fileList?.length <= 0, message: t('organizer_common_uploadedfileerror') }]}
                                        valuePropName='files'
                                        getValueFromEvent={(e) => e && e.fileList}
                                    >
                                        <Upload.Dragger multiple={false} name='files' fileList={fileList} onChange={onUploadChange}>
                                            <Flex justify='center' align='center' gap={24}>
                                                <UploadIcon size={30} />
                                                <Text className='ant-upload-text' strong>{t('organizer_common_uploadedfilehere')}</Text>
                                            </Flex>
                                        </Upload.Dragger>
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Flex vertical gap={12} className='paddingY-3' justify='end'>
                                        <Text type='secondary'>{t('organizer_common_uploadedfileheredescription')}</Text>

                                        <Text type='secondary'>
                                            {t('organizer_common_uploaddocumentsize')}
                                        </Text>
                                    </Flex>
                                </Col>
                            </Row>
                        </Fragment>
                        <Flex justify='flex-end' gap={10} wrap={true}>
                            <Button danger type='primary' htmlType='button' onClick={() => navigate(-1)}>{t('organizer_common_cancelButton')}</Button>
                            <Button type='primary' htmlType='submit' loading={profile?.loadingVacation}>
                                {leaveType?.toLowerCase() === t('organizer_common_sick')?.toLowerCase() + ' ' + t('organizer_vacationmanagement_leave')?.toLowerCase() ? t('organizer_common_submitButton') : t('organizer_profilesettings_vacations_requestleave')}
                            </Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex>
        </Fragment >
    );
};

export default AddUpdateNewVacation;
