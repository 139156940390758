import React, { Fragment, useEffect, useState } from 'react'

import { Button, Col, Divider, Flex, Form, Input, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

import { createStammUser, getByIdStammUser, getStammUser, setGetEditStammUserId, setIsSuccess, setIsUpdating, updateStammUser } from './redux/slice';
import { setLoginUserId, setLoginUserRole } from '../user/redux/slice';

import DeleteIcon from '../../components/icon/DeleteIcon';
import PlusIcon from '../../components/icon/PlusIcon';
import BackIcon from '../../components/icon/BackIcon';

const { Title, Text } = Typography;

const GeneralInformation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { stammdaten } = useSelector(state => state)

    const [form] = Form.useForm();
    const { id } = useParams();
    const [modalType, setModalType] = useState(false);
    const { t } = useTranslation();

    const [loginUser, setLoginUser] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
            dispatch(setLoginUserId(user._id))
            dispatch(setLoginUserRole(user.role))
        }
    }, [])

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsUpdating(true));
            dispatch(setGetEditStammUserId(id))
            dispatch(getByIdStammUser(id));
        }
    }, [id]);

    const handleAdd = (add) => {
        const fieldsValue = form.getFieldsValue();
        const contacts = fieldsValue.otherContact || [];

        if (contacts.length === 0) {
            add({ name: '', email: '', phone: '' });
            return;
        }

        const lastContact = contacts[contacts.length - 1];
        const hasEmptyFields = !lastContact?.name || !lastContact?.email || !lastContact?.phone;

        if (hasEmptyFields) {
            form.setFields([
                {
                    name: ['otherContact', contacts.length - 1, 'name'],
                    errors: !lastContact?.name ? [t('organizer_common_personnameerror')] : [],
                },
                {
                    name: ['otherContact', contacts.length - 1, 'email'],
                    errors: !lastContact?.email ? [t('organizer_common_contactemailerror')] : [],
                },
                {
                    name: ['otherContact', contacts.length - 1, 'phone'],
                    errors: !lastContact?.phone ? [t('organizer_common_personcontactnoerror')] : [],
                },
            ]);
        } else {
            form.setFields([
                { name: ['otherContact', contacts.length - 1, 'name'], errors: [] },
                { name: ['otherContact', contacts.length - 1, 'email'], errors: [] },
                { name: ['otherContact', contacts.length - 1, 'phone'], errors: [] },
            ]);
            add();
        }
    };

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getStammUser({
            ...values,
            address: { streetNo: values?.streetNo, city: values?.city, zip: values?.zip },
            otherContact: values?.otherContact.filter(contact => contact.name && contact.phone && contact?.email),
            organizer: loginUser?.organizer
        }));
    }

    useEffect(() => {
        if (Object.keys(stammdaten?.stammUser).length > 0) {
            if (!modalType) {
                dispatch(createStammUser({ stammUserData: stammdaten?.stammUser, organizerId: loginUser?.organizer }));
            } else {
                if (stammdaten.isUpdating) {
                    form.setFieldsValue({ ...stammdaten?.stammUser, otherContact: stammdaten?.stammUser?.otherContact?.length > 0 ? stammdaten?.stammUser?.otherContact : [{ name: '', email: '', phone: '' }], streetNo: stammdaten?.stammUser?.address?.streetNo, city: stammdaten?.stammUser?.address?.city, zip: stammdaten?.stammUser?.address?.zip });
                } else {
                    dispatch(updateStammUser({ stammUserData: { ...stammdaten.stammUser, id: id }, organizerId: loginUser?.organizer }));
                }
            }
        }
        if (stammdaten.isSuccess) {
            handleClear();
            dispatch(getStammUser({}));
            navigate('/stammdaten');
            setModalType(false);
            dispatch(setIsSuccess(false));
        }
    }, [stammdaten.isUpdating, stammdaten.stammUser, stammdaten.isSuccess]);

    const handleClear = () => {
        form.resetFields();
        dispatch(getStammUser({}));
        navigate(-1)
    };

    return (
        <Fragment>
            <Flex vertical gap={24}>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => { navigate('/stammdaten'); dispatch(getStammUser({})); }}
                    />
                </Flex>
                <Form
                    form={form}
                    layout='vertical'
                    className='w-100 padding-2 rounded-1 bg-white'
                    onFinish={handleOnFinish}
                    requiredMark={(label, isRule) => {
                        return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                    }}
                >
                    <Title level={4} type='success'>{id ? t('organizer_usermanagement_usertype_edit') : t('organizer_common_add')} {t('organizer_common_customer')}</Title>
                    <Divider />
                    <Row gutter={24}>
                        <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24} >
                            <Form.Item
                                name='company'
                                label={t('organizer_common_companyname')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_companynameerror')
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t('organizer_common_companyname')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={12} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='firstName'
                                label={t('organizer_common_firstname')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_firstnameerror'),
                                    },
                                ]}
                            >
                                <Input placeholder={t('organizer_common_firstname')} />
                            </Form.Item>
                        </Col>

                        <Col xxl={8} xl={12} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='lastName'
                                label={t('organizer_common_lastname')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_lastnameerror'),
                                    },
                                ]}
                            >
                                <Input placeholder={t('organizer_common_lastname')} />
                            </Form.Item>
                        </Col>

                        <Col xxl={8} xl={12} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='email'
                                label={t('organizer_common_email')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_emailerror'),
                                    },
                                ]}
                            >
                                <Input placeholder={t('organizer_common_emailerror')} />
                            </Form.Item>
                        </Col>

                        <Col xxl={8} xl={12} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item name='phone' label={t('organizer_common_phoneNo')}>
                                <Input placeholder={t('organizer_common_phoneNo')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Title level={5} className='padding-bottom-2'>{t('organizer_common_address')}</Title>
                    <Row gutter={24}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='streetNo'
                                label={t('organizer_common_streetName')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_streetnameerror')
                                    }
                                ]}
                            >
                                <Input
                                    showCount={false}
                                    placeholder={t('organizer_common_streetName')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='city'
                                label={t('organizer_common_city')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_cityerror')
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t('organizer_common_city')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='zip'
                                label={t('organizer_common_zip')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_ziperror')
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t('organizer_common_zip')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row>
                        <Col xs={24} sm={24} lg={24} md={24} xl={24} xxl={24}>
                            <Form.List
                                name='otherContact'
                                initialValue={[{ name: '', email: '', phone: '' }]}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        <Flex className='paddingY-1' align='center' justify='space-between'>
                                            <Title level={4} type='success' className='paddingY-1'>
                                                {t('organizer_common_addmultiplecontacts')}
                                            </Title>

                                            <PlusIcon
                                                role='button'
                                                onClick={() => handleAdd(add)}
                                            />
                                        </Flex>
                                        {fields.map(({ key, name, ...restField }, index) => (
                                            <Row key={key} gutter={20}>
                                                <Col xxl={7} xl={7} lg={12} md={11} sm={22} xs={22}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'name']}
                                                        label={t('organizer_common_personname')}
                                                    >
                                                        <Input
                                                            placeholder={t('organizer_common_personname')}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xxl={7} xl={7} lg={12} md={11} sm={22} xs={22}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'email']}
                                                        label={t('organizer_common_personemail')}
                                                    >
                                                        <Input
                                                            placeholder={t('organizer_common_personemail')}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xxl={7} xl={7} lg={12} md={11} sm={22} xs={22}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'phone']}
                                                        label={t('organizer_common_personphoneno')}

                                                    >
                                                        <Input
                                                            type='tel'
                                                            placeholder={t('organizer_common_personphoneno')}

                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <Flex align='center' justify='center' className='h-100'>
                                                        <Button
                                                            type='danger'
                                                            icon={<DeleteIcon role='button' />}
                                                            onClick={() => {
                                                                remove(name);

                                                            }}
                                                        />
                                                    </Flex>
                                                </Col>
                                            </Row>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Col>

                        <Col xs={24} sm={24} lg={24} md={24} xl={24} xxl={24}>
                            <Flex justify='flex-end' gap={10} className='paddingY-2'>
                                <Button danger type='primary' htmlType='button' onClick={handleClear}>
                                    {t('organizer_common_cancelButton')}
                                </Button>
                                <Button type='primary' htmlType='submit' loading={stammdaten?.loading}>
                                    {t('organizer_common_submitButton')}
                                </Button>
                            </Flex>
                        </Col>
                    </Row>
                </Form>
            </Flex>
        </Fragment>
    );
};

export default GeneralInformation;
