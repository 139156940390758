import React, { Fragment, useEffect, useState } from 'react';

import { Flex, Row, Col, Input, Tabs, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import RequestLeave from './request-leave';
import AddLeave from './add-leave';
import AllLeaves from './all-leaves';

import SearchIcon from '../../components/icon/SearchIcon';

import { getLeavesListData, getRequestedLeavesListData } from './redux/slice';

import '../../styles/pages/vacation-management.scss';

const { Title } = Typography;

const VacationManagement = () => {
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const { auth, vacation } = useSelector((state) => state);
    const [allUserLeavesList, setAllUserLeavesList] = useState([]);
    const [requestLeaveList, setRequestLeaveList] = useState([]);
    const [month, setMonth] = useState(dayjs().format('MM'));
    const [year, setYear] = useState(dayjs().format('YYYY'));
    const { t } = useTranslation();

    useEffect(() => {
        getLeavesData();
    }, [auth?.decodeToken?.organizer, month, year])

    const getLeavesData = () => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getLeavesListData({
                organizer: auth?.decodeToken?.organizer,
                month: month,
                year: year
            }))
        }
    }

    useEffect(() => {
        setAllUserLeavesList(vacation?.vacationList);
    }, [vacation?.vacationList])

    useEffect(() => {
        getRequestedLeaveData();
    }, [auth?.decodeToken])

    const getRequestedLeaveData = () => {
        if (Object.keys(auth?.decodeToken)?.length > 0) {
            dispatch(getRequestedLeavesListData({
                user: auth?.decodeToken?._id,
                organizer: auth?.decodeToken?.organizer
            }));
        }
    }

    useEffect(() => {
        setRequestLeaveList(vacation?.requestList);
    }, [vacation?.requestList])

    return (
        <Fragment>
            <Flex vertical gap={24}>
                <Row
                    justify='space-between'
                    align='middle'
                    className='padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>{t('organizer_vacationmanagement_main_title')}</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Input
                                    value={search}
                                    placeholder={t('organizer_common_search')}
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon color='#000' size={20} />}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} className='bg-white rounded-2'>
                        <Tabs
                            type='card'
                            size='large'
                            onChange={() => { getLeavesData(); getRequestedLeaveData() }}
                            defaultActiveKey='1'
                            items={[
                                {
                                    label: t('organizer_vacationmanagement_all_leaves'),
                                    key: '1',
                                    children: <AllLeaves allUserLeavesList={allUserLeavesList} month={month} year={year} setMonth={setMonth} setYear={setYear} />,
                                },
                                {
                                    label: t('organizer_common_add') + ' ' + t('organizer_vacationmanagement_leave'),
                                    key: '2',
                                    children: <AddLeave />,
                                },
                                {
                                    label: t('organizer_vacationmanagement_requested') + ' ' + t('organizer_vacationmanagement_leave'),
                                    key: '3',
                                    children: <RequestLeave requestLeaveList={requestLeaveList} />,
                                },
                            ]}
                            className='padding-2'
                        />
                    </Col>
                </Row>
            </Flex>
        </Fragment>
    );
};

export default VacationManagement;
