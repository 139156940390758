import React, { Fragment, useEffect, useState } from 'react';

import { Flex, Row, Col, Input, Typography, Button, Form, Modal, Divider, Tag, Select, Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';

import { createLagar, deleteLagar, getByIdLagar, getLagar, getLagarListData, setIsSuccess, setIsUpdating, updateLagar } from './redux/slice';
import { getCityListing } from '../../redux/slice/global';

import SearchIcon from '../../components/icon/SearchIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import ExpandIcon from '../../components/icon/ExpandIcon';

import { black, lightPrimary, lightSecondary } from '../../utils/theme/config';

const { Title, Text } = Typography;

const Lagar = ({ permission }) => {
    const [form] = Form.useForm();
    const [filterForm] = Form.useForm();

    const dispatch = useDispatch();
    const { lagar, auth, listing } = useSelector(state => state);

    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [isEdit, setIsEdit] = useState(false);
    const [lagarLists, setLagarLists] = useState([]);
    const [totalRecords, setTotalRecords] = useState([]);
    const [editId, setEditId] = useState('');
    const [cityListing, setCityListing] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getCityListing())
    }, [])

    useEffect(() => {
        setCityListing(listing?.listing?.city)
    }, [listing?.listing?.city])

    const handleOnFinish = (values) => {
        if (isEdit) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getLagar({ ...values, organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer }));
    };

    // Get Lagar Data
    useEffect(() => {
        auth?.decodeToken?.organizer && getLagarsList();
    }, [pageSize, page, sortBy, order, search, auth]);

    const getLagarsList = () => {
        dispatch(getLagarListData({
            sortBy: sortBy,
            order: order,
            search: search?.trim(),
            filters: {
                city: [],
            },
            page: page,
            limit: pageSize,
            organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer
        }))
    };

    // Set Lagar Data
    useEffect(() => {
        setLagarLists(lagar?.lagarsList);
        setTotalRecords(lagar.totalRecords);
    }, [lagar?.lagarsList, lagar.totalRecords, search]);

    // Table Column Sort Funcation
    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const handleConfirm = (lagarId) => {
        dispatch(deleteLagar({
            id: lagarId,
            sortBy: '',
            order: '',
            search: '',
            page: page,
            limit: pageSize,
            organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer
        }))
    }

    // Lagar edit funcation
    const handleEditLagar = (record) => {
        setOpen(true);
        setIsEdit(true);
        dispatch(setIsUpdating(true));
        dispatch(getByIdLagar(record?._id));
        setEditId(record._id);
    }

    useEffect(() => {
        if (Object.keys(lagar.lagar).length > 0) {
            if (!editId) {
                dispatch(createLagar({ lagarData: lagar.lagar, organizer: auth?.decodeToken?.organizer }));
            } else {
                if (lagar.isUpdating) {
                    form.setFieldsValue({ ...lagar.lagar });
                } else {
                    dispatch(updateLagar({ lagarData: { ...lagar.lagar, id: editId }, organizer: auth?.decodeToken?.organizer }));
                }
            }
        }
        if (lagar.isSuccess) {
            handleClear();
            setOpen(false);
            setEditId('');
            setIsEdit(false);
            dispatch(getLagar({}));
            dispatch(setIsSuccess(false));
        }
    }, [lagar.isUpdating, lagar.lagar, lagar.isSuccess]);

    const columns = [
        {
            title: t('organizer_warehousemanagement_lager_lagerID'),
            dataIndex: 'uniqueIdentifier',
            key: 'uniqueIdentifier',
            width: '200px',
        },
        {
            title: t('organizer_common_city'),
            dataIndex: 'city',
            key: 'city',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'city'),
        },
        {
            title: t('organizer_common_lagername'),
            dataIndex: 'name',
            key: 'name',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'name'),
        },
        {
            title: t('organizer_common_status'),
            dataIndex: 'isActive',
            key: 'status',
            width: '150px',
            sorter: (_, __, order) => handleSortFunction(order, 'status'),
            render: (_, record) => {
                return (
                    <Fragment>
                        {

                            record?.isActive ?
                                <Tag color={lightPrimary} className='text-center'>{t('organizer_common_active')}</Tag> :
                                <Tag color={lightSecondary} className='text-center'>{t('organizer_common_deactivate')}</Tag>
                        }
                    </Fragment>
                );
            }
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>{t('superadmin_common_action')}</Text></Flex>,
            key: 'action',
            width: '50px',
            render: (_, record) => (
                <Flex justify='center'>
                    {
                        permission?.canUpdate &&
                        <Button type='text'
                            className='padding-1'
                            onClick={() => handleEditLagar(record)}
                        >
                            <EditIcon role='button' />
                        </Button>
                    }
                    {
                        permission?.canDelete &&
                        <Fragment>
                            {
                                record?.isActive ?
                                    <CommonPopconfirm
                                        title={t('organizer_warehousemanagement_lager_deactivate')}
                                        description={t('organizer_warehousemanagement_lager_confirmdescription')}
                                        icon={null}
                                        onConfirm={() => handleConfirm(record?._id)}
                                        cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                    >
                                        <Button type='text' className='padding-1'><DeleteIcon role='button' /></Button>
                                    </CommonPopconfirm>
                                    :
                                    <CommonPopconfirm
                                        title={t('organizer_warehousemanagement_lager_deactivateactivestammuser')}
                                        description={t('organizer_warehousemanagement_lager_confirmactivedescription')}
                                        icon={null}
                                        onConfirm={() => handleConfirm(record?._id)}
                                        cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                    >
                                        <Button type='text' className='padding-1'><DeleteIcon role='button' /></Button>
                                    </CommonPopconfirm>

                            }
                        </Fragment>
                    }
                </Flex>
            ),
        })
    }

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const handleClear = () => {
        form.resetFields();
        setEditId('')
        setIsEdit(false);
        setOpen(false)
        dispatch(getLagar({}))
    };

    const handleFilterFinish = (values) => {
        if (values?.city) {
            dispatch(getLagarListData({
                sortBy: sortBy,
                order: order,
                search: search?.trim(),
                filters: {
                    city: values?.city,
                },
                page: page,
                limit: pageSize,
                organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer
            }))
        }
    };

    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    const filterItems = [
        {
            key: '1',
            label: <Title level={4} type='success' className='text-primary'>{t('organizer_common_filter')}</Title>,
            children: <Fragment>
                <Form
                    form={filterForm}
                    className='user-filter'
                    name='filters'
                    colon={false}
                    layout='vertical'
                    onFinish={handleFilterFinish}
                >
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='city'
                                label={t('organizer_common_city')}
                            >
                                <Select
                                    showSearch
                                    mode='multiple'
                                    allowClear
                                    placeholder={t('organizer_common_city')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={cityListing}
                                    tagRender={(props) => (
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                padding: '5px 10px',
                                                borderRadius: '4px',
                                                backgroundColor: '#f0f0f0',
                                                margin: '2px',
                                                color: '#333',
                                            }}
                                        >
                                            {props.label}
                                            {props.closable && (
                                                <span
                                                    onClick={props.onClose}
                                                    style={{
                                                        marginLeft: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    &times;
                                                </span>
                                            )}
                                        </span>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' onClick={() => { filterForm.resetFields(); getLagarsList() }}>{t('organizer_common_clearbutton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_applybutton')}</Button>
                    </Flex>
                </Form>
            </Fragment >,
        }
    ];

    return (
        <Fragment>
            <Flex vertical gap={24} className='change-tag'>
                <Row
                    justify='space-between'
                    align='middle'
                    className='bg-white padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>{t('organizer_warehousemanagement_lager_lagertitle')}</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Input
                                    value={search}
                                    placeholder={t('organizer_common_search')}
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon color='#000' size={20} />}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                        <Flex justify='end'>
                            {
                                (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                                <Button onClick={() => { setOpen(true); form.resetFields(); setIsEdit(false); setEditId(''); dispatch(getLagar({})) }}>{t('organizer_warehousemanagement_lager_addlager')}</Button>
                            }
                            <Modal
                                open={open}
                                closeIcon={<CloseIcon color={black} role='button' />}
                                // title={<Fragment><Title level={4} type='success'>{isEdit ? `Edit ${lagarId && lagarId} Lagar` : 'Add New Lagar'}</Title><Divider /></Fragment>}
                                title={<Fragment><Title level={4} type='success'>{isEdit ? t('organizer_warehousemanagement_lager_editlager') : t('organizer_warehousemanagement_lager_addlager')}</Title><Divider /></Fragment>}
                                footer={null}
                                onCancel={() => { setOpen(false); setIsEdit(false); setEditId(''); form.resetFields(); dispatch(getLagar({})) }}
                                modalRender={(dom) => (
                                    <Form
                                        layout='vertical'
                                        form={form}
                                        name='lagar-form'
                                        requiredMark={(label, isRule) => {
                                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                                        }}
                                        onFinish={(values) => handleOnFinish(values)}
                                    >
                                        {dom}
                                    </Form>
                                )}
                            >
                                <Form.Item
                                    name='city'
                                    label={t('organizer_common_city')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('organizer_common_cityerror'),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder={t('organizer_common_city')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={cityListing}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='name'
                                    label={t('organizer_common_lagername')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('organizer_common_lagernameerror'),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('organizer_common_lagername')} />
                                </Form.Item>
                                <Form.Item className='margin-0'>
                                    <Row gutter={16}>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Button type='primary' danger block htmlType='button' onClick={handleClear}>{t('organizer_common_cancelButton')}</Button>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Button type='primary' block htmlType='submit' loading={lagar?.loading}>{t('organizer_common_submitButton')}</Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Modal>
                        </Flex>
                    </Col>
                </Row>

                <Flex className='filter-margin-top'>
                    <Collapse
                        items={filterItems}
                        defaultActiveKey={['1']}
                        className='w-100'
                        bordered={false}
                        expandIcon={customExpandIcon}
                        expandIconPosition={'end'}
                    />
                </Flex>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <DataTable
                            columns={columns}
                            pageSize={pageSize}
                            currentPage={page}
                            total={totalRecords}
                            scrollX={1000}
                            showSorterTooltip={false}
                            isPagination={true}
                            handleOnChange={handleOnChange}
                            data={lagarLists}
                            rowKey={(record) => record?._id}
                        />
                    </Col>
                </Row>
            </Flex>
        </Fragment>
    );
};

export default Lagar;
