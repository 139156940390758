import React, { Fragment, useEffect, useState } from 'react';

import { Col, Flex, Form, Row, Typography, Input, Upload, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createCompany, getCompany, setCompanyList, setIsCompanySuccess, setIsUpdatingCompany, updateCompany } from './redux/slice';

import profileImage from '../../assets/images/avatar.jpg';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const CompanyDetails = () => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { auth, profile } = useSelector(state => state);
    const [fileList, setFileList] = useState([]);
    const [profileImg, setProfile] = useState("");
    const [modalType, setModalType] = useState(false);
    const dispatch = useDispatch();


    const handleOnFinish = (values) => {
        values.address = { streetNo: values?.streetNo, city: values?.city, pincode: values?.zip }
        delete values?.city
        delete values?.streetNo
        delete values?.zip
        delete values?.image
        const formData = new FormData();

        formData.append('organizer', auth.decodeToken?.organizer);

        if (Object.keys(profile.company).length > 0) {
            dispatch(setIsUpdatingCompany(false));
        }
        dispatch(setCompanyList(values));
    }

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList.slice(-1));
        newFileList.length > 0 ? setProfile(newFileList.slice(-1)[0]?.originFileObj) : setProfile("");
    };

    useEffect(() => {
        if (Object?.keys(auth.decodeToken)?.length > 0) {
            dispatch(getCompany(auth.decodeToken._id));
        }
    }, [auth.decodeToken]);


    useEffect(() => {
        if (Object?.keys(profile.company)?.length > 0) {
            form.setFieldsValue({ ...profile?.company, streetNo: profile?.company?.address?.streetNo, city: profile?.company?.address?.city, zip: profile?.company?.address?.pincode })
            setFileList([{ url: profile?.company?.profilePicture ? `${process.env.REACT_APP_API_URI}/company/${profile?.company?.profilePicture}` : profileImage }]);
            dispatch(setIsUpdatingCompany(true));
            setModalType(true);
        }
    }, [profile.company]);

    useEffect(() => {
        if (Object.keys(profile.companyList).length > 0) {
            const formData = new FormData();

            // (profileImg && Object.keys(profileImg)?.length > 0) && formData.append('profilePicture', profileImg);
            formData.append('profilePicture', profileImg);
            formData.append('userId', auth.decodeToken._id);

            Object.entries(profile.companyList).forEach(([key, value]) => {
                if (typeof value === 'object' && value !== null) {
                    Object.entries(value).forEach(([subKey, subValue]) => {
                        formData.append(`${key}[${subKey}]`, subValue);
                    });
                } else {
                    formData.append(key, value);
                }
            });

            formData.append('organizer', auth.decodeToken?.organizer);

            if (!modalType && !profile.isUpdatingCompany) {
                dispatch(createCompany({ companyData: formData, id: auth.decodeToken._id }));
            } else {
                if (!profile.isUpdatingCompany) {
                    formData.append("id", profile.company?._id);
                    dispatch(updateCompany({ companyData: formData, id: auth.decodeToken._id }));
                }
            }
        }
        if (profile.isCompanySuccess) {
            dispatch(setCompanyList({}));
            dispatch(setIsCompanySuccess(false));
        }
    }, [profile.isUpdatingCompany, profile.companyList, profile.isCompanySuccess]);

    return (
        <Fragment>
            <Flex className='paddingY-3 paddingX-2 general-settings bg-white' vertical gap={12}>
                <Title level={4}>{t('organizer_common_company_details')}</Title>

                <Form
                    form={form}
                    name='user'
                    colon={true}
                    requiredMark={(label, isRule) => {
                        return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                    }}
                    layout='vertical'
                    onFinish={handleOnFinish}
                >
                    <Row gutter={16}>
                        <Col xxl={18} xl={18} lg={{ span: 18, order: 1 }} md={{ span: 24, order: 2 }} sm={24} xs={24} order={2}>
                            <Row gutter={24}>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24} >
                                    <Form.Item
                                        name='name'
                                        label={t('organizer_common_companyname')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_companynameerror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_companyname')}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='website'
                                        label={t('organizer_common_website')}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_website')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='email'
                                        label={t('organizer_common_email')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_emailerror')
                                            },
                                            {
                                                type: 'email',
                                                message: t('organizer_common_emailinvaliderror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_email')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='phone'
                                        label={t('organizer_common_phoneNo')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_phoneNoerror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_phoneNo')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='telefaxNumber'
                                        label={t('organizer_common_telefaxnumber')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_telefaxnumbererror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_telefaxnumber')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='taxNumber'
                                        label={t('organizer_common_taxNumber')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_taxNumbererror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_taxNumber')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={6} xl={6} lg={{ span: 6, order: 2 }} md={{
                            span: 24,
                            order: 1
                        }} sm={24} xs={24}
                        >
                            <Flex justify='center' align='center' className='h-100'>
                                <Form.Item name='image'
                                >
                                    <Upload
                                        beforeUpload={() => false}
                                        listType='picture-circle'
                                        fileList={fileList}
                                        onChange={handleChange}
                                        multiple={false}
                                        disabled={false}
                                        showUploadList={{ showRemoveIcon: true, showPreviewIcon: false }}
                                    >
                                        {fileList.length === 0 && <Button type='link' disabled={false} >+ {t('organizer_common_upload')}</Button>}
                                    </Upload>
                                </Form.Item>
                            </Flex>
                        </Col>
                    </Row>
                    <Title level={5} className='padding-bottom-1'>{t('organizer_common_address')}</Title>
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='streetNo'
                                label={t('organizer_common_streetName')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_streetnameerror'),
                                    },
                                ]}
                            >
                                <Input
                                    showCount={false}
                                    placeholder={t('organizer_common_streetName')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='zip'
                                label={t('organizer_common_zip')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_ziperror'),
                                    },
                                    {
                                        pattern: new RegExp(/^[0-9]+$/),
                                        message: t('organizer_common_zipinvaliderror')
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t('organizer_common_zip')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='city'
                                label={t('organizer_common_city')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_cityerror'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t('organizer_common_city')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' disabled={false} onClick={() => {
                            form.resetFields();
                            setProfile({})
                            setFileList([])
                            navigate(-1)
                        }}>{t('organizer_common_cancelButton')}</Button>
                        <Button type='primary' htmlType='submit' disabled={false} loading={profile?.loading}>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Form>
            </Flex>
        </Fragment>
    )
}

export default CompanyDetails