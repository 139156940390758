import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import axiosWeather from 'axios';
import openNotification from '../../../components/notification';

const initialState = {
    weatherData: {},
    pdfListData: {},
    company: {},
    construcationDiaryLists: [],
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
    workerData: [],
    performanceData: [],
    disabilitiesData: [],
    performanceChangesData: [],
    photosData: [],
    construcationDiary: {}
}

export const slice = createSlice({
    name: "construction",
    initialState,
    reducers: {
        getConstrucationDiaryLists: (state, action) => {
            state.construcationDiaryLists = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.construcationDiary = {};
            state.isSuccess = action.payload;
        },
        setWatherData: (state, action) => {
            state.weatherData = {
                wheatherType: action.payload.weather[0]?.main,
                temp: action.payload.main.temp,
                text: action.payload.weather[0]?.description
            };
        },
        getConstrucationDiary: (state, action) => {
            state.construcationDiary = action.payload;
        },
        getPDFConstrucationDiaryData: (state, action) => {
            state.pdfListData = action.payload;
        },
        setWorkerData: (state, action) => {
            state.workerData = action.payload;
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setPerformanceData: (state, action) => {
            state.performanceData = action.payload;
        },
        setDisabilitiesData: (state, action) => {
            state.disabilitiesData = action.payload;
        },
        setPerformanceChangesData: (state, action) => {
            state.performanceChangesData = action.payload;
        },
        setPhotosData: (state, action) => {
            state.photosData = action.payload;
        }
    }
});

export const getWeatherData = createAsyncThunk('getWeatherData', (location, { dispatch }) => {
    dispatch(setLoading(true));
    axiosWeather.get(process.env.REACT_APP_WEATHER_API_URL, {
        params: {
            lat: location.latitude,
            lon: location.longitude,
            appid: process.env.REACT_APP_WEATHER_API_KEY,
            units: 'metric',  // Celsius
        },
    }).then((response) => {
        dispatch(setWatherData(response.data));
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getConstrucationDiaryListData = createAsyncThunk('getConstrucationDiaryListData', (organizerId, { dispatch }) => {
    dispatch(setLoading(true));
    axios.get(`/construction-diary/get?organizer=${organizerId}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getConstrucationDiaryLists(response.data.records))
        } else {
            dispatch(getConstrucationDiaryLists([]));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getConstrucationDiaryPDFData = createAsyncThunk('getConstrucationDiaryPDFData', (id, { dispatch }) => {
    dispatch(setLoading(true));
    return axios.get(`/construction-diary?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getPDFConstrucationDiaryData(response.data.record))
            return response.data.record
        } else {
            dispatch(getPDFConstrucationDiaryData({}));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createConstructionDiary = createAsyncThunk('createConstructionDiary', (constructionDiaryData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`construction-diary/insert`, constructionDiaryData?.formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsSuccess(true));
            dispatch(getConstrucationDiaryListData(constructionDiaryData?.organizer));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteConstructionDiary = createAsyncThunk('deleteConstructionDiary', (ids, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`construction-diary/status?id=${ids?.diaryId}`).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getConstrucationDiaryListData(ids?.organizer));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Get Company 
export const getCompany = createAsyncThunk('getCompany', (id, { dispatch }) => {
    axios.get(`/company?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(setCompany(response.data.record));
        } else { }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
    })
})

export const { setError, setLoading, setCompany, getConstrucationDiary, getPDFConstrucationDiaryData, getConstrucationDiaryLists, setIsSuccess, setWatherData, setWorkerData, setPerformanceData, setDisabilitiesData, setPerformanceChangesData, setPhotosData } = slice.actions;

export default slice.reducer;
