import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    profile: {},
    vacatonLists: [],
    vacation: {},
    leaveDateLists: [],
    company: {},
    companyList: {},
    leaves: {},
    loading: false,
    loadingVacation: false,
    error: null,
    totalRecords: 0,
    isUpdating: false,
    isVacationUpdating: false,
    isSuccess: false,
    isVacationSuccess: false,
    isUpdatingCompany: false,
    isCompanySuccess: false,
};

export const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        getVacationList: (state, action) => {
            state.vacation = {};
            state.isUpdating = false;
            state.vacatonLists = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.profile = {};
            state.isSuccess = action.payload;
        },
        setVacationLoading: (state, action) => {
            state.loadingVacation = action.payload
        },
        setIsVacationSuccess: (state, action) => {
            state.vacation = {};
            state.isVacationSuccess = action.payload;
        },
        getProfile: (state, action) => {
            state.profile = action.payload;
        },
        getVacation: (state, action) => {
            state.vacation = action.payload;
        },
        getLeaves: (state, action) => {
            state.leaves = action.payload;
        },
        getLeavesDateLists: (state, action) => {
            state.leaveDateLists = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
        setIsVacationUpdating: (state, action) => {
            state.isVacationUpdating = action.payload;
        },
        setIsUpdatingCompany: (state, action) => {
            state.isUpdatingCompany = action.payload;
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setCompanyList: (state, action) => {
            state.companyList = action.payload;
        },
        setIsCompanySuccess: (state, action) => {
            state.companyList = {};
            state.isCompanySuccess = action.payload;
        },
    },
});

export const getByIdUserProfileData = createAsyncThunk('getByIdUserProfileData', (id, { dispatch }) => {
    axios.get(`/profile/?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getProfile(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    })
});

export const updateUserProfile = createAsyncThunk('updateUserProfile', (userData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/profile/update`, userData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Vacation 
export const getVacationListData = createAsyncThunk('getVacationListData', (filterData, { dispatch }) => {
    dispatch(setVacationLoading(true));
    axios.post(`/vacation/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getVacationList(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            dispatch(getVacationList([]));
            dispatch(setTotalrecords(0))
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setVacationLoading(false))
    })
});

export const createVacation = createAsyncThunk('createVacation', (vacationData, { dispatch }) => {
    dispatch(setVacationLoading(true))
    axios.post(`/vacation/insert`, vacationData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsVacationSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setVacationLoading(false))
    })
});

export const getByIdVacation = createAsyncThunk('getByIdVacation', (id, { dispatch }) => {
    axios.get(`/vacation?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getVacation(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
    })
});

export const updateVacation = createAsyncThunk('updateVacation', (vacationData, { dispatch }) => {
    dispatch(setVacationLoading(true));
    axios.post(`vacation/update`, vacationData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsVacationSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setVacationLoading(false))
    })
});

export const deleteVacation = createAsyncThunk('deleteVacation', (object, { dispatch }) => {
    dispatch(setVacationLoading(true))
    axios.post(`vacation/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getVacationListData(object));
            dispatch(getVacationLeaves(object?.userId))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setVacationLoading(false))
    })
});

export const getVacationLeaves = createAsyncThunk('getVacationLeaves', (userId, { dispatch }) => {
    axios.get(`/leave?id=${userId}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getLeaves(response.data?.record));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getLeaveDateLists = createAsyncThunk('getLeaveDateLists', (organizerId, { dispatch }) => {
    axios.get(`/vacation/get-dates?organizer=${organizerId}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getLeavesDateLists(response.data?.records));
        }
        else {
            dispatch(getLeavesDateLists([]));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

//company
export const createCompany = createAsyncThunk('createCompany', (companyData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post('/company/insert', companyData.companyData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getCompany(companyData.id));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
})

export const getCompany = createAsyncThunk('getCompany', (id, { dispatch }) => {
    axios.get(`/company?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(setCompany(response.data.record));
        } else { }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
    })
})

export const updateCompany = createAsyncThunk('updateCompany', (companyData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/company/update`, companyData.companyData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsCompanySuccess(true));
            dispatch(getCompany(companyData.id));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
})

// Action creators are generated for each case reducer function
export const { setError, getVacationList, getLeavesDateLists, getLeaves, setIsVacationSuccess, setIsVacationUpdating, setVacationLoading, getVacation, setTotalrecords, setLoading, getProfile, setIsUpdating, setIsSuccess, setCompany, setIsUpdatingCompany, setCompanyList, setIsCompanySuccess } = slice.actions;

export default slice.reducer;
