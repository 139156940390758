import * as React from 'react';

const BlackCloseIcon = ({
    size = 24,
    color = '#000000',
    role = 'default',
    style = {},
    ...Rest
}) => {
    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styles}
            {...Rest}
        >
            <path
                d="M19.1875 10C19.1875 4.92588 15.0741 0.8125 10 0.8125C4.92588 0.8125 0.8125 4.92588 0.8125 10C0.8125 15.0741 4.92588 19.1875 10 19.1875C15.0741 19.1875 19.1875 15.0741 19.1875 10Z"
                fill={color}
                stroke="black"
                strokeWidth={1.2}
                strokeMiterlimit={10}
            />
            <path
                d="M6.875 6.875L13.125 13.125"
                stroke="white"
                strokeWidth={1.2}
                strokeLinecap="round"
            />
            <path
                d="M13.125 6.875L6.875 13.125"
                stroke="white"
                strokeWidth={1.2}
                strokeLinecap="round"
            />
        </svg>
    );
}
export default BlackCloseIcon;