import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URI}/api/`, // our API base URL
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Interceptor to handle errors globally
api.interceptors.response.use(
    (response) => { // If the response is successful, return the response
        if (response.data.statusCode === 401 && response.data.isSuccess === false) {
            localStorage.removeItem('token');
            window.location.href = '/user/authentication/sign-in';
        } else {
            return response;
        }
    },
    (error) => {
        const navigate = useNavigate();

        // Checking for various status codes and triggering corresponding pages
        if (error.response) {
            const { status } = error.response;

            // For 401 Unauthorized error
            if (status === 401 || status === 400) {
                navigate('/unauthorized'); // Redirect to your 401 or 400 page
            }

            // For 500 Internal Server Error
            if (status === 500) {
                navigate('/internal-server'); // Redirect to your 500 page
            }
        }

        return Promise.reject(error);
    },
);

export default api;
