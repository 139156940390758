import React, { Fragment, useEffect, useState } from 'react';

import { Layout, Button, Flex, Avatar, Typography, Space, Dropdown, Col, Row, Form, Modal, Divider, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { changePassword, logoutUser, setIsAuthorize, setIsSuccess } from '../../pages/auth/redux/slice';

import { updateLanguage } from '../../redux/slice/global';
import EnglishIcon from '../../components/icon/EnglishIcon';
import GermanIcon from '../../components/icon/GermanIcon';
import MenuIcon from '../../components/icon/MenuIcon';
import DownArrowIcon from '../../components/icon/DownArrowIcon';
import profilImage from '../../assets/images/avatar.jpg';
import CloseIcon from '../../components/icon/CloseIcon';

import { PasswordRegex } from '../../utils/constraints';

import { black } from '../../utils/theme/config';

import '../../styles/layout/header.scss';

const { Header } = Layout;
const { Text, Title, Link } = Typography;

const HeaderComponent = ({ collapsed, setCollapsed }) => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { auth } = useSelector(state => state)
    const [loginUser, setLoginUser] = useState({});
    const [profile, setProfile] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { i18n, t } = useTranslation();
    const { language } = useSelector(state => state.listing);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleLogout = () => {
        dispatch(logoutUser({ id: auth?.decodeToken?._id }));
        localStorage.removeItem('token');
        dispatch(setIsAuthorize(''));
        navigate('/user/authentication/sign-in');
    };

    // Decode login user token //
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
            setProfile(user?.profilePicture ? `${process.env.REACT_APP_API_URI}/profiles/${user?.profilePicture}` : profilImage)
        }
    }, []);

    const handleProfileSetting = () => {
        navigate('/settings/general')
    }

    const handleOnFinish = (values) => {
        dispatch(changePassword({
            id:loginUser?._id,
            currentPassword:values?.currantPassword,
            newPassword:values?.password
        }))
    }

    useEffect(() => {
        if (auth.isSuccess) {
            form.resetFields();
            setIsModalOpen(false);
            dispatch(setIsSuccess(false));
        }
    }, [auth.isSuccess]);

    const handleModalCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
    }

    const handleChangeLanguage = (lang) => {
        if (lang === 'en') {
            dispatch(updateLanguage({
                id: auth?.decodeToken?._id,
                language: lang
            }));
            setSelectedLanguage({ icon: <EnglishIcon />, language: 'English' }); i18n.changeLanguage('en');
        } else {
            dispatch(updateLanguage({
                id: auth?.decodeToken?._id,
                language: lang
            }));
            setSelectedLanguage({ icon: <GermanIcon />, language: 'German' }); i18n.changeLanguage('de');
        }
    }

    const items = [
        {
            key: '0',
            label: <Text onClick={handleProfileSetting} className='paddingX-1'>{t('superadmin_header_profilesetting')}</Text>,
        },
        {
            key: '1',
            label: <Text onClick={showModal} className='paddingX-1'>{t('superadmin_header_changepassword')}</Text>,
        },
        {
            key: '2',
            label: <Text type='danger' onClick={(event) => handleLogout(event)} className='paddingX-1 d-block'>{t('superadmin_header_logout')}</Text>,
        },
    ];

    const languageItems = [
        {
            key: 'en',
            label: <Fragment>
                <Flex align='center' gap={6} className='language-item'>
                    <Text className='padding-top-1'><EnglishIcon /></Text>
                    <Text level={5} className='margin-0 padding-bottom-1'>English</Text>
                </Flex>
            </Fragment>,
            onClick: () => handleChangeLanguage('en')
        },
        {
            key: 'de',
            label: <Fragment>
                <Flex align='center' gap={6} className='language-item'>
                    <Text className='padding-top-1'><GermanIcon /></Text>
                    <Text level={5} className='margin-0 padding-bottom-1'>German</Text>
                </Flex>
            </Fragment>,
            onClick: () => handleChangeLanguage('de')
        }
    ]

    useEffect(() => {
        i18n.changeLanguage(language);
        setSelectedLanguage({ icon: language === 'de' ? <GermanIcon /> : <EnglishIcon />, language: language === 'de' ? 'German' : 'English' });
    }, [language]);

    return (
        <Fragment>
            <Header className='rounded-top-2 header'>
                <Flex justify='space-between' align='center'>
                    <Button
                        type='text'
                        icon={<MenuIcon role={'button'} />}
                        onClick={() => setCollapsed(!collapsed)}
                        className='h-100 paddingY-1'
                    />
                    <Flex gap={12} className='paddingX-1'>
                        <Flex justify='center' align='center'>
                            <Dropdown
                                menu={{
                                    items: languageItems,
                                    selectable: true,
                                    defaultSelectedKeys: ['0'],
                                    className: 'language-dropdown'
                                }}
                                className='header-dropdown'
                                trigger={['click']}
                            >
                                <Link>
                                    <Space role='button'>
                                        <Flex justify='center' align='center' gap={6}>
                                            <Text className='padding-top-1'>{selectedLanguage?.icon}</Text>
                                            <Title level={5} className='margin-0 padding-bottom-1'>{selectedLanguage?.language}</Title>
                                        </Flex>
                                        <DownArrowIcon role='button' size={20} color={black} />
                                    </Space>
                                </Link>
                            </Dropdown>
                        </Flex>
                        <Avatar
                            src={profile}
                            size='large'
                        />
                        <Flex vertical>
                            <Dropdown
                                menu={{
                                    items,
                                    selectable: true,
                                    defaultSelectedKeys: [language],
                                }}
                                className='header-dropdown'
                                trigger={['click']}
                            >
                                <Link>
                                    <Space role='button'>
                                        <Title type='success' level={5}>
                                            {Object.keys(loginUser).length > 0 &&
                                                loginUser?.company ? loginUser?.company :
                                                (loginUser?.firstName
                                                    ? loginUser?.firstName
                                                    : 'User')
                                            }
                                        </Title>
                                        <DownArrowIcon role='button' size={20} />
                                    </Space>
                                </Link>
                            </Dropdown>
                            <Text type='secondary'>{auth?.decodeToken?.permissions?.length && auth?.decodeToken?.permissions[0]?.name}</Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Modal
                    centered
                    open={isModalOpen}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Title level={4} type='success'>{t('superadmin_header_changepassword')}</Title><Divider /></Fragment>}
                    footer={null}
                    onCancel={handleModalCancel}
                    destroyOnClose
                    modalRender={(dom) => (
                        <Form
                            layout='vertical'
                            form={form}
                            name='lagar-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            onFinish={(values) => handleOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row gutter={24}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='currantPassword'
                                label={t('superadmin_header_currentpassword')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('superadmin_header_currentpassworderror')
                                    },
                                ]}
                            >
                                <Input.Password disabled={false} placeholder={t('superadmin_header_currentpassword')} />
                            </Form.Item>
                        </Col>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='password'
                                label={t('superadmin_header_newpassword')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('superadmin_header_newpassworderror')
                                    },
                                    {
                                        pattern: new RegExp(PasswordRegex),
                                        message: t('superadmin_header_newpasswordinvaliderror')
                                    }
                                ]}
                            >
                                <Input.Password disabled={false} placeholder={t('superadmin_header_newpassword')} />
                            </Form.Item>
                        </Col>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='confirmPassword'
                                label={t('superadmin_header_reenterpassword')}
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: t('superadmin_header_reenterpassworderror')
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(t('superadmin_header_reenterpasswordinvaliderror')));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password disabled={false} placeholder={t('superadmin_header_reenterpassword')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' style={{ width: '100%', maxWidth: '10rem' }} htmlType='button' disabled={false} onClick={() => { form.resetFields(); setIsModalOpen(false) }}>
                            {t('superadmin_common_cancelButton')}
                        </Button>
                        <Button type='primary' htmlType='submit' disabled={false}>{t('superadmin_header_changepassword')}</Button>
                    </Flex>
                </Modal>
            </Header>
        </Fragment>
    );
};

export default HeaderComponent;
