import React, { Fragment, useEffect, useState } from 'react';

import { Divider, Flex, Typography, Form, Row, Col, Select, DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';

import { getAllStammdatenProjectListing } from '../../redux/slice/global';
import dayjs from 'dayjs';

const { Title } = Typography;

const ConstructionDiary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { listing } = useSelector((state) => state);
    const [projectDateData, setProjectDateData] = useState({});
    const [loginUser, setLoginUser] = useState({});
    const [stammProjects, setStammProjects] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
        }
    }, [])

    useEffect(() => {
        getStammProjectListing();
    }, [loginUser?.organizer])

    const getStammProjectListing = () => {
        if (loginUser?.organizer) {
            dispatch(getAllStammdatenProjectListing(loginUser?.organizer));
        }
    }

    useEffect(() => {
        setStammProjects(listing.listing?.stammProjects);
    }, [listing.listing?.stammProjects])

    const handleOnChange = (e, type) => {
        if (type === 'date') {
            setProjectDateData({ ...projectDateData, date: dayjs(e).format('YYYY-MM-DD') })
        }
        else {
            setProjectDateData({ ...projectDateData, project: e })
        }
    }

    useEffect(() => {
        if ((Object.keys(projectDateData)?.length > 0 && (projectDateData.date && projectDateData.project))) {
            navigate('/construction-diary/add', { state: projectDateData })
        }
    }, [projectDateData])

    return (
        <Fragment>
            <Flex vertical className='h-100'>
                <Flex vertical className='w-100'>
                    <Title level={4} type='success'>{t('organizer_construction_diary_title')}</Title>
                    <Divider />
                </Flex>
                <Form
                    name='constructiondiary'
                    colon={false}
                    className='h-100'
                    layout='vertical'
                >
                    <Flex justify='center' align='center' vertical className='h-100'>
                        <Row className='w-100' justify={'center'} align={'middle'}>
                            <Col xxl={6} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='date'
                                    label={t('organizer_common_date')}
                                >
                                    <DatePicker onChange={(e) => handleOnChange(e, 'date')} format={'DD-MM-YYYY'} className='w-100' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='w-100' justify={'center'} align={'middle'}>
                            <Col xxl={6} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='project'
                                    label={t('organizer_common_project')}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        className='w-100'
                                        placeholder={t('organizer_common_project')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={(e) => handleOnChange(e, 'project')}
                                        options={stammProjects}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Flex>
                </Form>
            </Flex>
        </Fragment>
    );
};

export default ConstructionDiary;
