import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    vacationList: [],
    requestList: [],
    userLeaveList: {},
    vacation: {},
    totalRecords: 0,
    loading: false,
    requestLoading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'vacation',
    initialState,
    reducers: {
        getLeavesList: (state, action) => {
            state.vacation = {};
            state.isUpdating = false;
            state.vacationList = action.payload;
        },
        getRequestLeavesList: (state, action) => {
            state.requestList = action.payload;
        },
        getUserLeavesList: (state, action) => {
            state.userLeaveList = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setRequestLoading: (state, action) => {
            state.requestLoading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.vacation = {};
            state.isSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        getLeave: (state, action) => {
            state.user = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
    },
});

export const getLeavesListData = createAsyncThunk('getLeavesListData', (leaveData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.get(`/vacation/list?month=${leaveData?.month}&year=${leaveData?.year}&organizer=${leaveData?.organizer}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getLeavesList(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            dispatch(getLeavesList([]));
            dispatch(setTotalrecords(0))
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getUserLeaveListData = createAsyncThunk('getUserLeaveListData', (leaveData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.get(`/vacation/user-details?user=${leaveData?.user}&year=${leaveData?.year}&organizer=${leaveData?.organizer}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getUserLeavesList(response.data.records))
        } else {
            dispatch(getUserLeavesList({}));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getRequestedLeavesListData = createAsyncThunk('getRequestedLeavesListData', (leaveData, { dispatch }) => {
    dispatch(setRequestLoading(true));
    axios.get(`/vacation/get-formal-leaves?user=${leaveData?.user}&organizer=${leaveData?.organizer}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getRequestLeavesList(response.data.records))
        } else {
            dispatch(getRequestLeavesList([]));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setRequestLoading(false))
    })
});

export const approveAndRejectLeave = createAsyncThunk('acceptLeave', (leaveData, { dispatch }) => {
    dispatch(setRequestLoading(true));
    axios.post(`/vacation/update-leave-status?id=${leaveData?.id}&status=${leaveData?.status}`).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getRequestedLeavesListData({ user: leaveData?.user, organizer: leaveData?.organizer }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setRequestLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getRequestLeavesList, getUserLeavesList, setRequestLoading, getLeave, setIsUpdating, setIsSuccess, getLeavesList, setTotalrecords } = slice.actions;

export default slice.reducer;
