import * as React from 'react';

const UserIcon = ({
    size = 24,
    color = '#000000',
    role = 'default',
    style = {},
    ...Rest
}) => {
    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styles}
            {...Rest}
        >
            <path
                d="M7.63314 9.05768C7.5498 9.04935 7.4498 9.04935 7.35814 9.05768C5.3748 8.99102 3.7998 7.36602 3.7998 5.36602C3.7998 3.32435 5.4498 1.66602 7.4998 1.66602C9.54147 1.66602 11.1998 3.32435 11.1998 5.36602C11.1915 7.36602 9.61647 8.99102 7.63314 9.05768Z"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.6747 3.33398C15.2914 3.33398 16.5914 4.64232 16.5914 6.25065C16.5914 7.82565 15.3414 9.10898 13.7831 9.16732C13.7164 9.15898 13.6414 9.15898 13.5664 9.16732"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.4666 12.134C1.44993 13.484 1.44993 15.684 3.4666 17.0257C5.75827 18.559 9.5166 18.559 11.8083 17.0257C13.8249 15.6757 13.8249 13.4757 11.8083 12.134C9.52494 10.609 5.7666 10.609 3.4666 12.134Z"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.2832 16.666C15.8832 16.541 16.4499 16.2993 16.9165 15.941C18.2165 14.966 18.2165 13.3577 16.9165 12.3827C16.4582 12.0327 15.8999 11.7993 15.3082 11.666"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
export default UserIcon;