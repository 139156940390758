import React, { Fragment, useEffect, useState } from 'react';

import { Col, Flex, Form, Row, Input, Upload, Button, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

import { getByIdUser, setIsSuccess, updateUser } from '../user/redux/slice';
import { getDecodeToken } from '../auth/redux/slice';

import profileImage from '../../assets/images/avatar.jpg';

import '../../styles/pages/profile-settings.scss';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const PlatformUserGeneralSettings = () => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { auth, user } = useSelector(state => state);
    const [profile, setProfile] = useState({});
    const [fileList, setFileList] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const user = jwtDecode(token);
            dispatch(getDecodeToken(user))
        }
    }, [])

    useEffect(() => {
        if (Object?.keys(auth.decodeToken)?.length > 0) {
            dispatch(getByIdUser(auth.decodeToken._id));
        }
    }, [auth.decodeToken])

    useEffect(() => {
        if (Object?.keys(auth.decodeToken)?.length > 0 && user.isSuccess) {
            dispatch(getByIdUser(auth.decodeToken._id))
            dispatch(setIsSuccess(false))
        }

    }, [user.isSuccess])

    useEffect(() => {
        form.setFieldsValue({ ...user?.user, streetNo: user?.user?.address?.streetNo, city: user?.user?.address?.city, zip: user?.user?.address?.zip });
        setFileList([{ url: user?.user?.profilePicture ? `${process.env.REACT_APP_API_URI}/profiles/${user?.user?.profilePicture}` : profileImage }])
    }, [user.user])

    const handleOnFinish = (values) => {
        values.address = { streetNo: values?.streetNo, city: values?.city, zip: values?.zip }
        delete values?.city
        delete values?.streetNumber
        delete values?.zip
        delete values?.image
        const formData = new FormData();

        Object.keys(profile)?.length > 0 && formData.append('profilePicture', profile)
        formData.append('id', auth.decodeToken._id)
        Object.entries(values).forEach(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                Object.entries(value).forEach(([subKey, subValue]) => {
                    formData.append(`${key}[${subKey}]`, subValue);
                });
            } else {
                formData.append(key, value);
            }
        });

        dispatch(updateUser({ updatedUser: formData, isProfile: true }))
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList.slice(-1));
        setProfile(newFileList.slice(-1)[0]?.originFileObj);
    };

    return (
        <Fragment>
            <Flex className='paddingY-1 paddingX-2 general-settings bg-white' vertical gap={12}>
                <Title level={4}>{Object?.keys(auth?.decodeToken)?.length > 0 && auth?.decodeToken?.firstName} {t('superadmin_packages_profile')}</Title>
                <Form
                    form={form}
                    name='user'
                    colon={true}
                    requiredMark={(label, isRule) => {
                        return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                    }}
                    layout='vertical'
                    onFinish={handleOnFinish}
                >
                    <Row gutter={16}>
                        <Col xxl={18} xl={18} lg={{ span: 18, order: 1 }} md={{
                            span: 24,
                            order: 2
                        }} sm={24} xs={24} order={2}>
                            <Row gutter={24}>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='firstName'
                                        label={t('superadmin_common_firstname')}
                                    >
                                        <Input
                                            placeholder={t('superadmin_common_firstname')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='lastName'
                                        label={t('superadmin_common_lastname')}
                                    >
                                        <Input
                                            placeholder={t('superadmin_common_lastname')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='phone'
                                        label={t('superadmin_common_phoneNo')}
                                    >
                                        <Input
                                            placeholder={t('superadmin_common_phoneNo')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='email'
                                        label={t('superadmin_common_email')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('superadmin_common_emailerror')
                                            },
                                            {
                                                type: 'email',
                                                message: t('superadmin_common_emailinvaliderror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('superadmin_common_email')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={6} xl={6} lg={{ span: 6, order: 2 }} md={{
                            span: 24,
                            order: 1
                        }} sm={24} xs={24}
                        >
                            <Flex justify='center' align='center' className='h-100'>
                                <Form.Item name='image'>
                                    <Upload
                                        beforeUpload={() => false}
                                        listType='picture-circle'
                                        fileList={fileList}
                                        onChange={handleChange}
                                        multiple={false}
                                        showUploadList={{ showRemoveIcon: true, showPreviewIcon: false }}
                                    >
                                        {fileList.length === 0 && <Button type='link' disabled={false} >+ {t('superadmin_common_upload')}</Button>}
                                    </Upload>
                                </Form.Item>
                            </Flex>
                        </Col>
                    </Row>
                    <Title level={5} className='padding-bottom-2'>{t('superadmin_common_address')}</Title>
                    <Row gutter={24}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='streetNo'
                                label={t('superadmin_common_streetName')}
                            >
                                <Input
                                    showCount={false}
                                    placeholder={t('superadmin_common_streetName')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='city'
                                label={t('superadmin_common_city')}
                            >
                                <Input
                                    placeholder={t('superadmin_common_city')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='zip'
                                label={t('superadmin_common_zip')}
                            >
                                <Input
                                    placeholder={t('superadmin_common_zip')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' disabled={false} onClick={() => {
                            form.resetFields();
                            setProfile({})
                            setFileList([])
                            navigate(-1)
                        }}>{t('superadmin_common_cancelButton')}</Button>
                        <Button type='primary' htmlType='submit' disabled={false} loading={user?.loading}>{t('superadmin_common_submitButton')}</Button>
                    </Flex>
                </Form>
            </Flex >
        </Fragment>
    );
};

export default PlatformUserGeneralSettings;
