import * as React from 'react';

const DiaryDocumentIcon = ({
    size = 24,
    color = '#000000',
    role = 'default',
    style = {},
    ...Rest
}) => {
    const styles = {
        cursor: role === 'button' ? 'pointer' : 'default',
        ...style,
    };
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styles}
            {...Rest}
        >
            <path
                d="M7.5 9.16602V14.166L9.16667 12.4993"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.50065 14.1667L5.83398 12.5"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3327 8.33268V12.4993C18.3327 16.666 16.666 18.3327 12.4993 18.3327H7.49935C3.33268 18.3327 1.66602 16.666 1.66602 12.4993V7.49935C1.66602 3.33268 3.33268 1.66602 7.49935 1.66602H11.666"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3327 8.33268H14.9993C12.4993 8.33268 11.666 7.49935 11.666 4.99935V1.66602L18.3327 8.33268Z"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
export default DiaryDocumentIcon;