import React, { Fragment, useEffect, useState } from 'react';

import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { getDecodeToken } from '../auth/redux/slice';
import GeneralSettings from './GeneralSettings';
import PlatformUserGeneralSettings from './PlatformUserGeneralSettings';

const ProfileSettings = () => {
    const dispatch = useDispatch()
    const [loginUser, setLoginUser] = useState({});

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const user = jwtDecode(token);
            dispatch(getDecodeToken(user))
            setLoginUser(user);
        }
    }, [])
    return (
        <Fragment>
            {
                loginUser?.organizer
                    ? <GeneralSettings />
                    : <PlatformUserGeneralSettings />
            }
        </Fragment>
    );
};

export default ProfileSettings;
