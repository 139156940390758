import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    stammdatenDataLists: [],
    stammdatenDocumentLists: [],
    stammProjectListing: [],
    stammUser: {},
    getEditStammUserId: null,
    stammdatenProjectDataLists: [],
    stammProject: {},
    stammDocument: {},
    totalRecords: 0,
    projectTotalRecords: 0,
    documentTotalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isProjectUpdating: false,
    projectIsUpdating: false,
    isSuccess: false,
    isProjectSuccess: false,
    isDocumentSuccess: false,
    clientIsSuccess: false,
}

export const slice = createSlice({
    name: 'stammdaten',
    initialState: initialState,
    reducers: {
        getStammdatenList: (state, action) => {
            state.stammUser = {};
            state.getEditStammUserId = null;
            state.isUpdating = false;
            state.stammdatenDataLists = action.payload;
        },
        getStammdatenProjectDataLists: (state, action) => {
            state.stammProject = {}
            state.isProjectUpdating = false;
            state.stammdatenProjectDataLists = action.payload;
        },
        getStammdatenDocumentLists: (state, action) => {
            state.stammDocument = {}
            state.isProjectUpdating = false;
            state.stammdatenDocumentLists = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.stammUser = {};
            state.isSuccess = action.payload;
        },
        setStammProjectListing: (state, action) => {
            state.stammProjectListing = action.payload;
        },
        setIsProjectSuccess: (state, action) => {
            state.stammProject = {};
            state.isProjectSuccess = action.payload;
        },
        setIsDocumentSuccess: (state, action) => {
            state.stammDocument = {};
            state.isDocumentSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        setProjectTotalrecords: (state, action) => {
            state.projectTotalRecords = action.payload;
        },
        setDocumentTotalrecords: (state, action) => {
            state.documentTotalRecords = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
        setIsProjectUpdating: (state, action) => {
            state.isProjectUpdating = action.payload;
        },
        setProjectIsUpdating: (state, action) => {
            state.projectIsUpdating = action.payload;
        },
        setGetEditStammUserId: (state, action) => {
            state.getEditStammUserId = action.payload
        },
        getStammUser: (state, action) => {
            state.stammUser = action.payload;
        },
        getStammProject: (state, action) => {
            state.stammProject = action.payload;
        },
        getStammDocument: (state, action) => {
            state.stammDocument = action.payload;
        },
        setGeneralData: (state, action) => {
            state.stammdatenUserData.generalData = action.payload;
        },
        setProjectData: (state, action) => {
            state.stammdatenUserData.projectData = action.payload;
        },
        setDocumentsData: (state, action) => {
            // state.stammdatenUserData.documentData = action.payload;
        }
    }
});

export const getStammdatenData = createAsyncThunk('getStammdatenData', (filterData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/stammuser/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getStammdatenList(response.data.records));
            dispatch(setTotalrecords(response.data.totalRecords));
        } else {
            // openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createStammUser = createAsyncThunk('createStammUser', (stammUserData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/stammuser/insert`, stammUserData?.stammUserData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdStammUser = createAsyncThunk('getByIdStammUser', (id, { dispatch }) => {
    axios.get(`/stammuser?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getStammUser(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
    })
});

export const updateStammUser = createAsyncThunk('updateStammUser', (stammUserData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/stammuser/update`, stammUserData?.stammUserData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteStammUser = createAsyncThunk('deleteStammUser', (object, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/stammuser/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getStammdatenData(object));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// project

export const getStammdatenProjectData = createAsyncThunk('getStammdatenProjectData', (filterData, { dispatch }) => {
    // dispatch(setLoading(true));
    axios.post(`/stammuserproject/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getStammdatenProjectDataLists(response.data.records));
            dispatch(setProjectTotalrecords(response.data.totalRecords));
        } else {
            // openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        // dispatch(setLoading(false))
    })
});

export const createStammProject = createAsyncThunk('createStammProject', (stammUserData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/stammuserproject/insert`, stammUserData?.stammUserData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getStammdatenProjectData({ search: '', sortBy: '', order: '', page: 1, limit: 10, organizer: stammUserData?.organizerId }))
            dispatch(setIsProjectSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdProject = createAsyncThunk('getByIdProject', (id, { dispatch }) => {
    axios.get(`/stammuserproject?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getStammProject(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
    })
});

export const updateProject = createAsyncThunk('updateProject', (stammUserData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/stammuserproject/update`, stammUserData?.stammUserData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getStammdatenProjectData({ search: '', sortBy: '', order: '', page: 1, limit: 10, organizer: stammUserData?.organizerId }))
            dispatch(setIsProjectSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteProject = createAsyncThunk('deleteStammUser', (object, { dispatch }) => {
    axios.post(`/stammuserproject/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getStammdatenProjectData(object));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    })
});

// Document

export const getStammdatenDocumentData = createAsyncThunk('getStammdatenDocumentData', (filterData, { dispatch }) => {
    // dispatch(setLoading(true));
    axios.post(`/stammuserdocument/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getStammdatenDocumentLists(response.data.records));
            dispatch(setDocumentTotalrecords(response.data.totalRecords));
        } else {
            // openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        // dispatch(setLoading(false))
    })
});

export const createStammDocument = createAsyncThunk('createStammDocument', (stammUserData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/stammuserdocument/insert`, stammUserData?.stammUserData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getStammdatenDocumentData({ search: '', sortBy: '', order: '', page: 1, limit: 10, organizer: stammUserData?.organizerId }))
            dispatch(setIsDocumentSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }   
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteDocument = createAsyncThunk('deleteDocument', (object, { dispatch }) => {
    axios.post(`/stammuserdocument/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getStammdatenDocumentData(object));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    })
});

// Project Listing

export const getStammProjectListing = createAsyncThunk('getStammProjectListing', (id, { dispatch }) => {
    axios.get(`/stammuserproject/options?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(setStammProjectListing(response?.data?.records))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    })
});

export const {
    setGeneralData,
    setProjectData,
    setDocumentsData,
    getStammdatenList,
    setError,
    setIsSuccess,
    setProjectIsSuccess,
    setIsDocumentSuccess,
    setIsUpdating,
    setLoading,
    setTotalrecords,
    setProjectTotalrecords,
    getStammUser,
    setGetEditStammUserId,
    getStammdatenProjectDataLists,
    setProjectIsUpdating,
    getStammProject,
    getStammDocument,
    getStammdatenDocumentLists,
    setIsProjectSuccess,
    setDocumentTotalrecords,
    setIsProjectUpdating,
    setStammProjectListing
} = slice.actions;
export default slice.reducer;
