import React, { Fragment, useEffect, useState } from 'react'

import { Button, Col, Divider, Flex, Form, Input, Row, Select, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

import { createStammProject, getStammdatenProjectData, getStammProject, getByIdProject, updateProject, setIsProjectSuccess, deleteProject } from './redux/slice';

import { lightPrimary, lightSecondary } from '../../utils/theme/config';
import { getStammdatenUserListing } from '../../redux/slice/global';

import DataTable from '../../components/data-table';

import PlusIcon from '../../components/icon/PlusIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import EditIcon from '../../components/icon/EditIcon';
import CommonPopconfirm from '../../components/custom-popconfirm';
import CloseIcon from '../../components/icon/CloseIcon';

const { Title, Text } = Typography;

const Project = ({ permission }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { stammdaten, listing } = useSelector(state => state)

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [modalType, setModalType] = useState(false);
    const [stammProjectList, setStammProjectList] = useState([]);
    const [projectEditId, setProjectEditId] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const [stammUserListing, setStammUserListing] = useState([]);
    const [loginUser, setLoginUser] = useState({});
    const [isCreated, setIsCreated] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
        }
    }, [])

    useEffect(() => {
        dispatch(getStammProject({}));
    }, [])

    useEffect(() => {
        getStammProjectListData()
    }, [pageSize, page, sortBy, order, totalRecords, loginUser])

    const getStammProjectListData = () => {
        loginUser?.organizer && dispatch(getStammdatenProjectData({
            sortBy: sortBy,
            order: order,
            search: '',
            page: page,
            limit: pageSize,
            organizer: loginUser?.organizer
        }))
    }

    useEffect(() => {
        getStammUserLists();
    }, [loginUser?.organizer])

    const getStammUserLists = () => {
        loginUser?.organizer && dispatch(getStammdatenUserListing(loginUser?.organizer));
    };

    useEffect(() => {
        if (listing.listing?.stammUser?.length > 0) {
            setStammUserListing(listing.listing?.stammUser)
        }
    }, [listing.listing.stammUser]);

    useEffect(() => {
        setStammProjectList(stammdaten?.stammdatenProjectDataLists)
        setTotalRecords(stammdaten?.projectTotalRecords)
    }, [stammdaten?.stammdatenProjectDataLists, stammdaten?.projectTotalRecords])

    useEffect(() => {
        if (Object.keys(stammdaten?.stammProject).length > 0) {
            if (!projectEditId) {
                if (isCreated) {
                    dispatch(createStammProject({ stammUserData: stammdaten?.stammProject, organizerId: loginUser?.organizer }));
                }
            } else {
                if (isUpdate) {
                    form.setFieldsValue({
                        ...stammdaten?.stammProject,
                        otherContact: stammdaten?.stammProject?.otherContact?.length > 0 ? stammdaten?.stammProject?.otherContact : [{ name: '', email: '', phone: '' }],
                        streetNo: stammdaten?.stammProject?.address?.streetNo,
                        city: stammdaten?.stammProject?.address?.city,
                        zip: stammdaten?.stammProject?.address?.zip
                    });
                } else {
                    dispatch(updateProject({ stammUserData: { ...stammdaten.stammProject, id: projectEditId }, organizerId: loginUser?.organizer }));
                }
            }
        }
        if (stammdaten.isProjectSuccess) {
            handleClear();
            setModalType(false);
            setProjectEditId('');
            dispatch(setIsProjectSuccess(false));
            setIsCreated(false);
        }

    }, [stammdaten.isProjectUpdating, stammdaten.stammProject, stammdaten.isProjectSuccess, isUpdate, loginUser])

    const handleEdit = (values) => {
        setModalType(true);
        setProjectEditId(values?._id);
        setIsUpdate(true)
        dispatch(getByIdProject(values?._id))
    }

    const columns = [
        {
            title: t('organizer_common_createddate'),
            dataIndex: 'createdAt',
            key: 'date',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'createdAt'),
            render: (_, record) => dayjs(record?.createdAt).format('DD-MM-YYYY'),
        },
        {
            title: t('organizer_common_customer'),
            dataIndex: 'stammUser',
            key: 'stammUser',
            width: '200px',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: t('organizer_common_projectname'),
            dataIndex: 'name',
            key: 'projectName',
            width: '200px',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: t('organizer_common_phoneNo'),
            dataIndex: 'phone',
            key: 'phoneNumber',
            width: '200px',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: t('organizer_common_streetName'),
            dataIndex: 'streetNo',
            key: 'streetNo',
            width: '250px',
            sorter: (_, __, order) => handleSortFunction(order, 'streetNo'),
            render: (_, record) => {
                return record?.address?.streetNo
            }
        },
        {
            title: t('organizer_common_city'),
            dataIndex: 'city',
            key: 'city',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'city'),
            render: (_, record) => {
                return record?.address?.city;
            }
        },
        {
            title: t('organizer_common_zip'),
            dataIndex: 'zip',
            key: 'zip',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'zip'),
            render: (_, record) => {
                return record?.address?.zip
            }
        },
        {
            title: t('organizer_common_status'),
            dataIndex: 'isActive',
            key: 'status',
            width: '150px',
            sorter: (_, __, order) => handleSortFunction(order, 'status'),
            render: (_, record) => {
                return (
                    <Fragment>
                        {

                            record?.isActive ?
                                <Tag color={lightPrimary} className='text-center'>{t('organizer_common_active')}</Tag> :
                                <Tag color={lightSecondary} className='text-center'>{t('organizer_common_deactivate')}</Tag>
                        }
                    </Fragment>
                );
            }
        },
    ];

    if (Object.keys(permission ?? {})?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>{t('superadmin_common_action')}</Text></Flex>,
            key: 'action',
            render: (_, record) => (
                <Fragment>
                    <Flex gap={8} justify='center' align='center'>
                        <EditIcon role='button' onClick={() => handleEdit(record)} />
                        {
                            record?.isActive ?
                                <CommonPopconfirm
                                    title={t('organizer_stammdaten_stammuser_deactivateproject')}
                                    description={t('organizer_stammdaten_stammuser_confirmprojectdescription')}
                                    icon={null}
                                    onConfirm={() => dispatch(deleteProject({
                                        id: record?._id,
                                        sortBy: '',
                                        order: '',
                                        search: '',
                                        page: page,
                                        limit: pageSize,
                                        organizer: loginUser?.organizer
                                    }))}
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                >
                                    <Button type='text' className='padding-1'><CloseIcon role='button' /></Button>
                                </CommonPopconfirm>
                                :
                                <CommonPopconfirm
                                    title={t('organizer_stammdaten_stammuser_deactivateprojectactivestammuser')}
                                    description={t('organizer_stammdaten_stammuser_confirmprojectactivedescription')}
                                    icon={null}
                                    onConfirm={() => dispatch(deleteProject({
                                        id: record?._id,
                                        sortBy: '',
                                        order: '',
                                        search: '',
                                        page: page,
                                        limit: pageSize,
                                        organizer: loginUser?.organizer
                                    }))}
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                >
                                    <Button type='text' className='padding-1'><CloseIcon role='button' /></Button>
                                </CommonPopconfirm>
                        }
                    </Flex>
                </Fragment>
            ),
        })
    }

    const onFinish = (values) => {
        if (modalType) {
            setIsUpdate(false)
        }
        else {
            setIsCreated(true);
        }
        dispatch(getStammProject({
            ...values,
            address: { streetNo: values?.streetNo, city: values?.city, zip: values?.zip },
            otherContact: values?.otherContact.filter(contact => contact.name && contact.phone && contact?.email),
            organizer: loginUser?.organizer
        }));
    };

    const handleAdd = (add) => {
        const fieldsValue = form.getFieldsValue();
        const contacts = fieldsValue.otherContact || [];

        if (contacts.length === 0) {
            add({ name: '', email: '', phone: '' });
            return;
        }

        const lastContact = contacts[contacts.length - 1];
        const hasEmptyFields = !lastContact?.name || !lastContact?.email || !lastContact?.phone;

        if (hasEmptyFields) {
            form.setFields([
                {
                    name: ['otherContact', contacts.length - 1, 'name'],
                    errors: !lastContact?.name ? [t('organizer_common_personnameerror')] : [],
                },
                {
                    name: ['otherContact', contacts.length - 1, 'email'],
                    errors: !lastContact?.email ? [t('organizer_common_contactemailerror')] : [],
                },
                {
                    name: ['otherContact', contacts.length - 1, 'phone'],
                    errors: !lastContact?.phone ? [t('organizer_common_personcontactnoerror')] : [],
                },
            ]);
        } else {
            form.setFields([
                { name: ['otherContact', contacts.length - 1, 'name'], errors: [] },
                { name: ['otherContact', contacts.length - 1, 'email'], errors: [] },
                { name: ['otherContact', contacts.length - 1, 'phone'], errors: [] },
            ]);
            add();
        }
    };

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const handleClear = () => {
        form.resetFields();
        dispatch(getStammProject({}));
        navigate(-1)
    };

    return (
        <Fragment>
            <Flex vertical gap={24} className='change-tag'>
                <Row
                    justify='space-between'
                    align='middle'
                    className='bg-white padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>{t('organizer_common_project')}</Title>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    (Object.keys(permission ?? {})?.length > 0 && permission?.canCreate) &&
                    <Form
                        form={form}
                        name='user'
                        layout='vertical'
                        className='padding-2 bg-white rounded-1'
                        onFinish={onFinish}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                    >
                        <Title level={4} type='success' >{projectEditId ? t('organizer_usermanagement_usertype_edit') : t('organizer_common_add')} {t('organizer_common_project')}</Title>
                        <Divider />
                        <Row gutter={18}>
                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='stammUser'
                                    label={t('organizer_common_customer')}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t('organizer_common_please_select')} ${t('organizer_common_customer')?.toLowerCase()}`
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        placeholder={t('organizer_common_customer')}
                                        options={stammUserListing}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='name'
                                    label={t('organizer_common_projectname')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('organizer_common_projectnameerror'),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t('organizer_common_projectname')}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='phone'
                                    label={t('organizer_common_phoneNo')}
                                >
                                    <Input
                                        placeholder={t('organizer_common_phoneNo')}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Title level={5} className='padding-bottom-2'>{t('organizer_common_address')}</Title>
                        <Row gutter={24}>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='streetNo'
                                    label={t('organizer_common_streetName')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('organizer_common_streetnameerror')
                                        }
                                    ]}
                                >
                                    <Input
                                        showCount={false}
                                        placeholder={t('organizer_common_streetName')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='city'
                                    label={t('organizer_common_city')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('organizer_common_cityerror')
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder={t('organizer_common_city')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='zip'
                                    label={t('organizer_common_zip')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('organizer_common_ziperror')
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder={t('organizer_common_zip')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} lg={24} md={24} xl={24} xxl={24}>
                                <Form.List
                                    name='otherContact'
                                    initialValue={[
                                        { name: '', email: '', phone: '' },
                                    ]}
                                >
                                    {(fields, { add, remove }) => (
                                        <>
                                            <Flex className='paddingY-1' align='center' justify='space-between'>
                                                <Title level={4} type='success' className='paddingY-1' > {t('organizer_common_addmultiplecontacts')}</Title>

                                                <PlusIcon
                                                    role='button'
                                                    onClick={() => handleAdd(add)}
                                                />
                                            </Flex>
                                            {fields.map(({ key, name, ...restField }, index) => (
                                                <Row key={key} gutter={20}>
                                                    <Col xxl={7} xl={7} lg={7} md={7} sm={24} xs={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'name']}
                                                            label={t('organizer_common_personname')}
                                                        >
                                                            <Input
                                                                placeholder={t('organizer_common_personname')}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xxl={7} xl={7} lg={7} md={7} sm={24} xs={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'email']}
                                                            label={t('organizer_common_personemail')}
                                                        >
                                                            <Input
                                                                placeholder={t('organizer_common_personemail')}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xxl={7} xl={7} lg={7} md={7} sm={24} xs={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'phone']}
                                                            label={t('organizer_common_personphoneno')}
                                                        >
                                                            <Input
                                                                type='tel'
                                                                placeholder={t('organizer_common_personphoneno')}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xxl={2} xl={2} lg={8} md={2} sm={24} xs={24}>
                                                        <Flex align='center' justify='center' className='h-100'>
                                                            <Button
                                                                type='danger'
                                                                icon={<DeleteIcon role='button' />}
                                                                onClick={() => remove(name)}
                                                            />
                                                        </Flex>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </>
                                    )}
                                </Form.List>

                                <Flex justify='flex-end' gap={10} wrap={true} className='paddingY-2'>
                                    <Button danger type='primary' htmlType='button' onClick={handleClear}>{t('organizer_common_cancelButton')}</Button>
                                    <Button type='primary' htmlType='submit' loading={stammdaten?.loading}>{t('organizer_common_submitButton')}</Button>
                                </Flex>

                            </Col>
                        </Row>
                    </Form>
                }
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <DataTable
                            columns={columns}
                            pageSize={pageSize}
                            currentPage={page}
                            total={totalRecords}
                            scrollX={900}
                            showSorterTooltip={false}
                            isPagination={true}
                            handleOnChange={handleOnChange}
                            data={stammProjectList}
                            rowKey={(record) => record?._id}
                        />
                    </Col>
                </Row>
            </Flex>
        </Fragment>
    )
};

export default Project;
