import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Flex, Form, DatePicker, Select, Row, Col, Typography, Divider, Collapse, Image, Input, Button, InputNumber, Modal, TimePicker, Upload, Table } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { BlobProvider, pdf } from '@react-pdf/renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

import openNotification from '../../components/notification';
import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';
import ConstructionDiaryPDF from './pdf';

import SunIcon from '../../components/icon/SunIcon';
import PlusIcon from '../../components/icon/PlusIcon';
import UserIcon from '../../components/icon/UserIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import UploadIcon from '../../components/icon/UploadIcon';
import BlackCloseIcon from '../../components/icon/BlackCloseIcon';
import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import ConstructionDiaryExpandIcon from '../../components/icon/ConstructionDiaryExpandIcon';
import DiaryDocumentIcon from '../../components/icon/DiaryDocumentIcon';
import { PDFDocument, rgb, degrees, StandardFonts } from 'pdf-lib';
import ExpandIcon from '../../components/icon/ExpandIcon';

import noteIcon from '../../assets/images/noteIcon.svg';
import galleryIcon from '../../assets/images/galleryIcon.svg';
import diaryIcon from '../../assets/images/diaryIcon.png';
import bookIcon from '../../assets/images/book.png';
import EyeIcon from '../../components/icon/EyeIcon';

import { black, white } from '../../utils/theme/config';

import '../../styles/pages/construction-diary.scss';
import { getWeatherData, setDisabilitiesData, setPerformanceData, setWorkerData, setPerformanceChangesData, setPhotosData, getConstrucationDiary, createConstructionDiary, getConstrucationDiaryListData, deleteConstructionDiary, getConstrucationDiaryPDFData, getCompany } from './redux/slice';
import { getAllStammdatenProjectListing, getAllStammdatenUsersListing } from '../../redux/slice/global';
import ConstructionDiaryAPIPDF from './apiPdf';

const { Title, Text } = Typography;
const { TextArea } = Input;

const AddConstructionDiary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { t } = useTranslation();

    // Form Hooks //
    const [form] = Form.useForm();
    const [userForm] = Form.useForm();
    const [signatureForm] = Form.useForm();
    const [performanceResultForm] = Form.useForm();
    const [weatherForm] = Form.useForm();
    const [disabilitiesForm] = Form.useForm();
    const [performanceChangeForm] = Form.useForm();
    const [photosForm] = Form.useForm();
    const { weatherData, workerData, performanceData, company, pdfListData, construcationDiaryLists, isSuccess, loading, disabilitiesData, performanceChangesData, photosData, construcationDiary } = useSelector(state => state.construction);
    const { listing } = useSelector(state => state);
    const [selectedUser, setSelectedUsers] = useState([]);
    const [isUserUpdate, setIsUserUpdate] = useState({ isUpdate: false, id: null });
    const [isPerformanceUpdate, setIsPerformanceUpdate] = useState({ isUpdate: false, id: null });
    const [isDisabilitiesUpdate, setIsDisabilitiesUpdate] = useState({ isUpdate: false, id: null });
    const [isPerformanceChangeUpdate, setIsPerformanceChangeUpdate] = useState({ isUpdate: false, id: null });

    // Modals and Filelist Hooks //
    const [open, setOpen] = useState(false);
    const [signatureModal, setSignatureModal] = useState(false);
    const [managerModal, setManagerModal] = useState(false);
    const [isUserModal, setIsUserModal] = useState(false);
    const [isPerformanceResultModal, setIsPerformanceResultModal] = useState(false);
    const [isDisabilitiesModal, setIsDisabilitiesModal] = useState(false);
    const [isPerformanceChangeModal, setIsPerformanceChangeModal] = useState(false);
    const [isPhotosModal, setIsPhotosModal] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [disabilitiesFileList, setDisabilitiesFileList] = useState([]);
    const [performanceChangeFileList, setPerformanceChangeFileList] = useState([]);
    const [photosFileList, setPhotosFileList] = useState([]);
    const [downloadUrl, setDownload] = useState('');

    // Datas Hooks //
    const [weathers, setWeathers] = useState([]);
    const [companyData, setCompanyData] = useState({});
    const [stammUsers, setStammUsers] = useState([]);
    const [stammProjects, setStammProjects] = useState([]);
    const [diaryFormData, setDiaryFormData] = useState({ date: '', project: '', bauvorhaben: '' });
    const [loginUser, setLoginUser] = useState({});
    const [weatherEditId, setWeatherEditId] = useState('');
    const sigCanvas = useRef(null);
    const [isCreate, setIsCreate] = useState(false);
    const [trimmedUrl, setTrimmedUrl] = useState(null);
    const managerSigCanvas = useRef(null);
    const [managerTrimmedUrl, setManagerTrimmedUrl] = useState(null);
    const [diaryVersions, setDiaryVersions] = useState([]);

    const fetchBlobToArrayBuffer = async (blobUrl) => {
        const response = await fetch(blobUrl);
        if (!response.ok) {
            return;
        }
        const pdfBytes = await response.arrayBuffer();
        return pdfBytes;
    };

    const addWatermarkToPdf = async (pdfBytes) => {
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const pages = pdfDoc.getPages();
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

        for (const page of pages) {
            const { width, height } = page.getSize();

            const text = "DRAFT";
            const fontSize = 100;

            const textWidth = font.widthOfTextAtSize(text, fontSize);
            const textHeight = font.heightAtSize(fontSize);

            const x = (width - textWidth) / 1.2;
            const y = (height - textHeight) / 2;

            page.drawText(text, {
                x: x,
                y: y,
                size: fontSize,
                rotate: degrees(45),
                color: rgb(150 / 255, 150 / 255, 150 / 255),
                opacity: 0.3,
            });
        }

        const modifiedPdfBytes = await pdfDoc.save();
        return modifiedPdfBytes;
    }

    const applyWatermarkToBlobUrl = async (blobUrl) => {
        const pdfBytes = await fetchBlobToArrayBuffer(blobUrl);
        const watermarkedPdfBytes = await addWatermarkToPdf(pdfBytes);
        const blob = new Blob([watermarkedPdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(
            url,
            '_blank'
        );
    }

    const handleDownload = async () => {
        const pdfBytes = await fetchBlobToArrayBuffer(downloadUrl);
        const watermarkedPdfBytes = await addWatermarkToPdf(pdfBytes);
        const blob = new Blob([watermarkedPdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "ConstructionDiaryPDF.pdf";
        link.click();

        URL.revokeObjectURL(url);
    }

    // Set Date Project values
    useEffect(() => {
        if (state?.date) {
            const validDate = dayjs(state.date.$d);

            if (validDate.isValid()) {
                form.setFieldsValue({
                    project: state.project,
                    date: validDate
                });
            } else {
                console.error("Invalid date!", state.date);
            }
        } else {
            navigate("/construction-diary");
        }
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
        }
    }, [])

    useEffect(() => {
        getStammListing();
    }, [loginUser?.organizer])

    const getStammListing = () => {
        if (loginUser?.organizer) {
            dispatch(getAllStammdatenUsersListing(loginUser?.organizer));
            dispatch(getAllStammdatenProjectListing(loginUser?.organizer));
            dispatch(getCompany(loginUser?._id));
        }
    }

    useEffect(() => {
        if (Object.keys(company).length > 0) {
            setCompanyData(company);
        }
    }, [company])

    useEffect(() => {
        setStammUsers(listing.listing?.allStammUser);
        setStammProjects(listing.listing?.stammProjects);
    }, [listing.listing?.allStammUser, listing.listing?.stammProjects])

    // Construction Diary Versions //

    const handleClick = async (id) => {
        const data = await dispatch(getConstrucationDiaryPDFData(id));
        const blob = await pdf(<ConstructionDiaryAPIPDF
            data={{
                pdfListData: data.payload || null,
                company: companyData,
            }}
        />).toBlob();
        // Create a URL for the blob
        const blobUrl = URL.createObjectURL(blob);
        const pdfBytes = await fetchBlobToArrayBuffer(blobUrl);
        const watermarkedPdfBytes = await addWatermarkToPdf(pdfBytes);
        const blobResult = new Blob([watermarkedPdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blobResult);
        window.open(url, '_blank');
    }

    const handleDownloadPdfClick = async (id) => {
        const data = await dispatch(getConstrucationDiaryPDFData(id));
        const blob = await pdf(<ConstructionDiaryAPIPDF
            data={{
                pdfListData: data.payload || null,
                company: companyData,
            }}
        />).toBlob();
        // Create a URL for the blob
        const blobUrl = URL.createObjectURL(blob);
        const pdfBytes = await fetchBlobToArrayBuffer(blobUrl);
        const watermarkedPdfBytes = await addWatermarkToPdf(pdfBytes);
        const blobResult = new Blob([watermarkedPdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blobResult);
        const link = document.createElement("a");
        link.href = url;
        link.download = "ConstructionDiaryPDF.pdf";
        link.click();

        URL.revokeObjectURL(url);
    }

    const diaryVersionColumns = [
        {
            title: t('organizer_common_uniqid'),
            dataIndex: 'uniqID',
            key: 'uniqID',
        },
        {
            title: `${t('organizer_common_date')} - ${t('organizer_common_time')}`,
            dataIndex: 'dateTime',
            key: 'dateTime',
        },
        {
            title: t('organizer_common_createdby'),
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: t('organizer_common_PDFversion'),
            dataIndex: 'pdfVersion',
            key: 'pdfVersion',
        },
        {
            title: <Flex justify='center'><Text>{t('organizer_common_action')}</Text></Flex>,
            key: 'action',
            width: '150px',
            render: (_, record) => {
                return (
                    <Flex justify='center' align='center'>
                        <Button
                            type="text"
                            className="padding-1 eyebutton"
                            icon={<EyeIcon role="button" color={black} size={32} />}
                            onClick={() => handleClick(record?._id)}
                        />
                        <Button
                            type='text'
                            className='padding-1'
                            icon={<DiaryDocumentIcon role='button' />}
                            onClick={() => handleDownloadPdfClick(record?._id)}
                        />
                        <CommonPopconfirm
                            icon={null}
                            title={t('organizer_construction_diary_deleteLeave')}
                            description={t('organizer_construction_diary_confirmdescription')}
                            onConfirm={() => dispatch(deleteConstructionDiary({ diaryId: record?._id, organizer: loginUser?.organizer }))}
                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                        >
                            <Button
                                type='text'
                                className='padding-1'
                                icon={<DeleteIcon role='button' />}
                            />
                        </CommonPopconfirm>
                    </Flex >
                );
            },
        }
    ]

    const diaryVersionsExpandIcon = ({ isActive }) => {
        return <ConstructionDiaryExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    const diaryVersionsItems = [
        {
            key: '1',
            label: <Flex gap={8}><Image src={bookIcon} preview={false} /><Title level={5}>{t('organizer_common_constructiondiary')} {t('organizer_common_versions')}</Title></Flex>,
            children: <Fragment>
                <Flex justify='center' style={{ height: '100%', minHeight: '15.25rem', overflowY: 'scroll' }}>
                    <DataTable
                        columns={diaryVersionColumns}
                        scrollX={900}
                        scrollY={192}
                        showSorterTooltip={false}
                        isPagination={false}
                        data={diaryVersions}
                        rowKey={(record) => record?._id}
                    />

                </Flex>
            </Fragment>,
        }
    ];

    // Weather //
    const weatherExpandIcon = ({ isActive }) => {
        // return <ExpandIcon
        //     style={{
        //         transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
        //         transition: 'transform 0.3s ease',
        //     }}
        //     role='button'
        // />
        return <PlusIcon color={black} role='button' onClick={(e) => { e.stopPropagation(); setOpen(true); }} />
    };

    const weatherColumns = [
        {
            title: t('organizer_common_wether'),
            dataIndex: 'weather',
            key: 'weather',
            render: (_, record) => {
                return record?.weather?.charAt(0)?.toUpperCase() + record?.weather?.slice(1)?.toLowerCase();
            }
        },
        {
            title: t('organizer_common_temperature'),
            dataIndex: 'temperature',
            key: 'temperature',
            render: (temperature) => (
                <span>
                    {temperature}
                    <sup>°c</sup>
                </span>
            ),
        },
        {
            title: t('organizer_common_text'),
            dataIndex: 'note',
            key: 'note',
            render: (text) => (
                <span>
                    {!text ? '-' : text?.length > 25 ? `${text.slice(0, 20)}...` : text}
                </span>
            ),
        },
        {
            title: <Flex justify='center'><Text>{t('organizer_common_action')}</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                return (
                    <Flex justify='center'>
                        <Button
                            type='text'
                            className='padding-1'
                            onClick={() => { setOpen(true); weatherForm.setFieldsValue(record); setWeatherEditId(record?.id) }}
                        >
                            <EditIcon role='button' />
                        </Button>
                        <CommonPopconfirm
                            icon={null}
                            title={t('organizer_common_deleteTitle') + ' ' + t('organizer_common_wether')?.toLowerCase()}
                            description={t('organizer_common_confirmationmessage') + ' ' + t('organizer_common_wether') + '?'}
                            onConfirm={() => setWeathers(weathers?.filter((item) => item.id !== record?.id))}
                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                        >
                            <Button
                                type='text'
                                className='padding-1'>
                                <DeleteIcon
                                    role='button'
                                />
                            </Button>
                        </CommonPopconfirm>
                    </Flex >
                );
            },
        }
    ];

    const weatherItems = [
        {
            key: '1',
            label: <Flex gap={8}><SunIcon /><Title level={5}>{t('organizer_common_wether')}</Title></Flex>,
            children: <Fragment>
                <Flex justify='center' style={{ height: '100%', minHeight: '15.25rem', overflowY: 'scroll' }}>
                    {
                        weathers?.length > 0 ?
                            <DataTable
                                columns={weatherColumns}
                                scrollX={500}
                                scrollY={192}
                                showSorterTooltip={false}
                                isPagination={false}
                                data={weathers}
                                rowKey={(record) => record?.id}
                            />
                            :
                            <Text>{t('organizer_common_notfound', { name: t('organizer_common_wether') })}</Text>
                    }
                </Flex>
            </Fragment>,
        }
    ];

    const handleWeatherOnFinish = (values) => {
        if (weatherEditId) {
            const getIndex = weathers?.findIndex((item) => item.id === weatherEditId);
            const newWeathers = [...weathers];
            newWeathers[getIndex] = { ...values, id: weatherEditId };

            setWeathers([...newWeathers]);
            setOpen(false);
            handleWeatherClear();
        }
        else {
            setWeathers([...weathers, { ...values, id: Date.now() }]);
            setOpen(false);
            handleWeatherClear();
        }
    };

    const handleWeatherClear = () => {
        weatherForm.resetFields();
        setOpen(false);
        setWeatherEditId('');
    };
    // User //

    const userColumns = [
        {
            title: t('organizer_common_name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('organizer_common_totalhours'),
            dataIndex: 'totalHrs',
            key: 'totalHrs',
        },
        {
            title: <Flex justify='center'><Text>{t('organizer_common_action')}</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                return (
                    <Flex justify='center'>
                        <Button
                            type='text'
                            className='padding-1'
                            onClick={() => handleUserUpdate(record)}
                        >
                            <EditIcon role='button' />
                        </Button>
                        <CommonPopconfirm
                            icon={null}
                            title={t('organizer_common_deleteTitle') + ' ' + t('organizer_common_user')?.toLowerCase()}
                            description={t('organizer_common_confirmationmessage') + ' ' + t('organizer_common_user')?.toLowerCase()}
                            // onConfirm={() => dispatch(deleteStammUser({
                            //     id: record?._id,
                            //     search: '', sortBy: '', order: '', page: 1, limit: 10, organizer: loginUser?.organizer
                            // }))}
                            onConfirm={() => handleDeleteUser(record.id)}
                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                        >
                            <Button
                                type='text'
                                className='padding-1'>
                                <DeleteIcon
                                    role='button'
                                />
                            </Button>
                        </CommonPopconfirm>
                    </Flex >
                );
            },
        }
    ];

    const userItems = [
        {
            key: '1',
            label: <Flex gap={8}><UserIcon /><Title level={5}>{t('organizer_construction_diary_workerslist')}</Title></Flex>,
            children: <Fragment>
                <Flex justify='center' style={{ height: '100%', minHeight: '15.25rem' }}>
                    {
                        workerData?.length > 0 ?
                            <DataTable
                                columns={userColumns}
                                scrollX={300}
                                scrollY={192}
                                showSorterTooltip={false}
                                isPagination={false}
                                data={workerData}
                                rowKey={(record) => record?.id}
                            />
                            :
                            <Flex align='center'><Text>{t('organizer_common_notfound', { key: 'Beschäftigen Arbeiter', name: 'Workers List' })}</Text></Flex>
                    }
                </Flex>
            </Fragment>,
        }
    ];

    const userExpandIcon = ({ isActive }) => {
        // return <ExpandIcon
        //     style={{
        //         transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
        //         transition: 'transform 0.3s ease',
        //     }}
        //     role='button'
        // />
        return <PlusIcon color={black} role='button' onClick={(e) => { e.stopPropagation(); setIsUserModal(true); setIsUserUpdate({ isUpdate: false, id: null }) }} />
    };

    const handleUserClear = () => {
        userForm.resetFields();
        setIsUserModal(false);
    };

    const handleSelectedUser = (values) => {
        const selectedOptions = values.map(option => ({
            value: option.value,
            label: option.label,
        }));

        setSelectedUsers(selectedOptions);
    };

    const handleUserUpdate = (record) => {
        setIsUserUpdate({
            isUpdate: true,
            id: record.id
        });
        setIsUserModal(true);
        const data = workerData.filter((user) => record.id === user.id)[0];

        userForm.setFieldsValue({
            worker: data.name,
            workingHours: data.workingHours,
            breakHours: data.breakHours
        });
    }

    const handleUserOnFinish = (values) => {
        const workingHours = dayjs(values.workingHours, 'HH:mm');
        const breakHours = dayjs(values.breakHours, 'HH:mm');

        // Calculate total combined minutes
        const totalMinutes = workingHours.hour() * 60 + workingHours.minute() + breakHours.hour() * 60 + breakHours.minute();
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const totalCombinedHours = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

        if (isUserUpdate.isUpdate) {
            const updatedArray = workerData.map(item =>
                item.id === isUserUpdate.id ? {
                    ...item, totalHrs: totalCombinedHours, workingHours: values.workingHours,
                    breakHours: values.breakHours
                } : item
            );
            dispatch(setWorkerData(
                updatedArray
            ));
            setIsUserModal(false);
            userForm.resetFields();
        } else {
            const { worker } = values;

            // Check if any worker in already in workerData
            const existingWorkers = workerData.map(item => item.id);
            const duplicateWorkers = worker.filter(w => existingWorkers.includes(w.value));

            if (duplicateWorkers.length > 0) {
                openNotification({ type: 'error', message: t('organizer_common_workerexisterror') });
                return;
            }

            const data = [];
            selectedUser.map((user) => {
                data.push(
                    {
                        id: user.value,
                        name: user.label,
                        totalHrs: totalCombinedHours,
                        workingHours: values.workingHours,
                        breakHours: values.breakHours
                    }
                )
            });
            dispatch(setWorkerData([
                ...workerData,
                ...data
            ]))
            setIsUserModal(false);
            userForm.resetFields();
        }
    };

    const handleDeleteUser = (id) => {
        const updatedArray = workerData.filter(item => item.id !== id);
        dispatch(setWorkerData(
            updatedArray
        ));
    };

    // Performance Result //

    const performanceResultsColumns = [
        {
            title: t('organizer_common_note'),
            dataIndex: 'note',
            key: 'note',
            width: '300px',
            render: (text, record) => (
                <Flex style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    <Text>{text}</Text>
                </Flex>
            ),
        },
        {
            title: <Flex justify='center'><Text>{t('organizer_common_action')}</Text></Flex>,
            key: 'action',
            width: '40px',
            render: (_, record) => {
                return (
                    <Flex justify='center'>
                        <Button
                            type='text'
                            className='padding-1'
                            onClick={() => handlePerformanceUpdate(record)}
                        >
                            <EditIcon role='button' />
                        </Button>
                        <CommonPopconfirm
                            icon={null}
                            title={t('organizer_common_deleteTitle') + ' ' + t('organizer_common_note')?.toLowerCase()}
                            description={t('organizer_common_confirmationmessage') + ' ' + t('organizer_common_note')?.toLowerCase()}

                            onConfirm={() => handleDeletePerformance(record.id)}
                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                        >
                            <Button
                                type='text'
                                className='padding-1'>
                                <DeleteIcon
                                    role='button'
                                />
                            </Button>
                        </CommonPopconfirm>
                    </Flex >
                );
            },
        }
    ]

    const performanceResultItem = [
        {
            key: '1',
            label: <Flex gap={8}><Image src={noteIcon} preview={false} /><Title level={5}>{t('organizer_construction_diary_performanceresult')}</Title></Flex>,
            children: <Fragment>
                <Flex justify='center' style={{ height: '100%', minHeight: '15.25rem' }}>
                    {
                        performanceData?.length > 0 ?
                            <>
                                <Table
                                    className='w-100 x-auto data-table'
                                    columns={performanceResultsColumns}
                                    dataSource={performanceData}
                                    scroll={{
                                        x: 300,
                                        y: 192,
                                    }}
                                    pagination={false}
                                    expandable={{
                                        expandedRowRender: (record) => (
                                            <div>
                                                {record.file.length > 0 && (
                                                    <Flex wrap>
                                                        {record.file.map((file) => (
                                                            <div key={file.uid} className='paddingX-1'>
                                                                <Image
                                                                    src={URL.createObjectURL(file.originFileObj)}
                                                                    alt={file.name}
                                                                    style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '0.5rem' }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Flex>
                                                )}
                                            </div>
                                        ),
                                        rowExpandable: () => true,
                                        expandIcon: () => null,
                                    }}
                                    expandIconColumnIndex={-1}
                                    expandedRowKeys={performanceData.map((item) => item.key)}
                                />
                            </>
                            :
                            <Flex align='center'><Text>{t('organizer_common_notfound', { key: 'Leistungsergebnisse', name: 'Performance Result' })}</Text></Flex>
                    }
                </Flex>
            </Fragment>,
        }
    ];

    const performanceResultExpandIcon = ({ isActive }) => {
        return <PlusIcon color={black} role='button' onClick={(e) => { e.stopPropagation(); setIsPerformanceResultModal(true); setIsPerformanceUpdate({ isUpdate: false, id: null }) }} />
    };

    const handlePerformanceResultClear = () => {
        performanceResultForm.resetFields();
        setIsPerformanceResultModal(false);
        setFileList([]);
    };

    const handlePerformanceResultOnFinish = (values) => {
        if (!values.note && !values.files) {
            openNotification({ type: 'error', message: 'At least one field is required' });
        } else {
            if (isPerformanceUpdate.isUpdate) {
                const updatedArray = performanceData.map((item) =>
                    item.id === isPerformanceUpdate.id ? { ...item, note: values.note, file: fileList } : item
                );

                dispatch(setPerformanceData(updatedArray));
                setIsPerformanceResultModal(false);

                const updatedPhotosData = photosData.filter(existingFile => {
                    const isRelatedToCurrentRecord = performanceData.some(item =>
                        item.id === isPerformanceUpdate.id && item.file.some(file => file.uid === existingFile.uid)
                    );
                    return !isRelatedToCurrentRecord || fileList.some(newFile => newFile.uid === existingFile.uid);
                });

                const newFiles = fileList.filter(newFile =>
                    !updatedPhotosData.some(existingFile => existingFile.uid === newFile.uid)
                );

                const finalPhotosData = [...updatedPhotosData, ...newFiles];

                dispatch(setPhotosData(finalPhotosData));

                performanceResultForm.resetFields();
                setFileList([]);
            } else {

                dispatch(setPerformanceData([
                    ...performanceData,
                    {
                        id: Date.now(),
                        note: values.note,
                        file: fileList
                    }
                ]));

                const updatedPhotosData = [...photosData, ...fileList];
                dispatch(setPhotosData(updatedPhotosData));

                setIsPerformanceResultModal(false);
                performanceResultForm.resetFields();
                setFileList([]);
            }
        }

        setFileList([]);
    };

    const handlePerformanceUpdate = (values) => {
        setIsPerformanceUpdate({
            isUpdate: true,
            id: values.id
        })

        setIsPerformanceResultModal(true);
        const data = performanceData.filter((item) => values.id === item.id)[0];

        setFileList(data.file);
        performanceResultForm.setFieldsValue({
            note: data.note,
        });
    }

    const handleDeletePerformance = (id) => {
        const updatedArray = performanceData.filter(item => item.id !== id);

        const deletedRecord = performanceData.find(item => item.id === id);
        const deletedFiles = deletedRecord ? deletedRecord.file : [];

        const updatedPhotosData = photosData.filter(existingFile =>
            !deletedFiles.some(deletedFile => deletedFile.uid === existingFile.uid)
        );

        dispatch(setPerformanceData(updatedArray));
        dispatch(setPhotosData(updatedPhotosData));
    }

    const onUploadChange = (info) => {
        setFileList(info.fileList);
    };

    const handleDelete = (uid) => {
        setFileList((prevList) => prevList.filter((file) => file.uid !== uid));
    };

    // Disabilities // 
    const disabilitiesColumns = [
        {
            title: t('organizer_common_note'),
            dataIndex: 'note',
            key: 'note',
            width: '300px',
            render: (text) => (
                <span style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {text}
                </span>
            ),
        },
        {
            title: <Flex justify='center'><Text>{t('organizer_common_action')}</Text></Flex>,
            key: 'action',
            width: '40px',
            render: (_, record) => {
                return (
                    <Flex justify='center'>
                        <Button
                            type='text'
                            className='padding-1'
                            onClick={() => handleDisabilitiesUpdate(record)}
                        >
                            <EditIcon role='button' />
                        </Button>
                        <CommonPopconfirm
                            icon={null}
                            title={t('organizer_common_deleteTitle') + ' ' + t('organizer_common_note')?.toLowerCase()}
                            description={t('organizer_common_confirmationmessage') + ' ' + t('organizer_common_note')?.toLowerCase()}
                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                            onConfirm={() => handleDisabilitiesDelete(record.id)}
                        >
                            <Button
                                type='text'
                                className='padding-1'>
                                <DeleteIcon
                                    role='button'
                                />
                            </Button>
                        </CommonPopconfirm>
                    </Flex >
                );
            },
        }
    ]

    const disabilitiesItem = [
        {
            key: '1',
            label: <Flex gap={8}><Image src={noteIcon} preview={false} /><Title level={5}>{t('organizer_construction_diary_disabilities')}</Title></Flex>,
            children: <Fragment>
                <Flex justify='center' style={{ height: '100%', minHeight: '15.25rem' }}>
                    {
                        disabilitiesData?.length > 0 ?
                            <Table
                                className='w-100 x-auto data-table'
                                columns={disabilitiesColumns}
                                dataSource={disabilitiesData}
                                scroll={{
                                    x: 300,
                                    y: 192,
                                }}
                                pagination={false}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <div>
                                            {record.file.length > 0 && (
                                                <Flex wrap>
                                                    {record.file.map((file) => (
                                                        <div key={file.uid} className='paddingX-1'>
                                                            <Image
                                                                src={URL.createObjectURL(file.originFileObj)}
                                                                alt={file.name}
                                                                style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '0.5rem' }}
                                                            />
                                                        </div>
                                                    ))}
                                                </Flex>
                                            )}
                                        </div>
                                    ),
                                    rowExpandable: () => true,
                                    expandIcon: () => null,
                                }}
                                expandIconColumnIndex={-1}
                                expandedRowKeys={disabilitiesData.map((item) => item.key)}
                            />
                            :
                            <Flex align='center'><Text>{t('organizer_common_notfound', { key: 'Behinderungen', name: 'Disabilities' })}</Text></Flex>
                    }
                </Flex>
            </Fragment>,
        }
    ];

    const disabilitiesExpandIcon = ({ isActive }) => {
        return <PlusIcon color={black} role='button' onClick={(e) => { e.stopPropagation(); setIsDisabilitiesModal(true); setIsDisabilitiesUpdate({ isUpdate: false, id: null }) }} />
    };

    const handledisabilitiesClear = () => {
        disabilitiesForm.resetFields();
        setIsDisabilitiesModal(false);
        setDisabilitiesFileList([]);
    };

    const handleDisabilitiesOnFinish = (values) => {
        if (!values.note && !values.files) {
            openNotification({ type: 'error', message: 'Atleast one field is required' });
        } else {
            if (isDisabilitiesUpdate.isUpdate) {
                const updatedArray = disabilitiesData.map((item) =>
                    item.id === isDisabilitiesUpdate.id ? { ...item, note: values.note, file: disabilitiesFileList } : item
                );

                dispatch(setDisabilitiesData(
                    updatedArray
                ));
                setIsDisabilitiesModal(false);

                const updatedPhotosData = photosData.filter(existingFile => {
                    const isRelatedToCurrentRecord = disabilitiesData.some(item =>
                        item.id === isDisabilitiesUpdate.id && item.file.some(file => file.uid === existingFile.uid)
                    );
                    return !isRelatedToCurrentRecord || disabilitiesFileList.some(newFile => newFile.uid === existingFile.uid);
                });

                const newFiles = disabilitiesFileList.filter(newFile =>
                    !updatedPhotosData.some(existingFile => existingFile.uid === newFile.uid)
                );

                const finalPhotosData = [...updatedPhotosData, ...newFiles];

                dispatch(setPhotosData(finalPhotosData));

                disabilitiesForm.resetFields();
                setDisabilitiesFileList([]);
            } else {
                dispatch(setDisabilitiesData([
                    ...disabilitiesData,
                    {
                        id: Date.now(),
                        note: values.note,
                        file: disabilitiesFileList
                    }
                ]));

                const updatedPhotosData = [...photosData, ...disabilitiesFileList];
                dispatch(setPhotosData(updatedPhotosData));

                setIsDisabilitiesModal(false);
                disabilitiesForm.resetFields();
                setDisabilitiesFileList([]);
            }
        }
    };

    const handleDisabilitiesUpdate = (values) => {
        setIsDisabilitiesUpdate({
            isUpdate: true,
            id: values.id
        });
        setIsDisabilitiesModal(true);

        const data = disabilitiesData.filter((item) => values.id === item.id)[0];

        setDisabilitiesFileList(data.file);
        disabilitiesForm.setFieldsValue({
            note: data.note,
        });
    }

    const handleDisabilitiesDelete = (id) => {
        const updatedArray = disabilitiesData.filter(item => item.id !== id);

        const deletedRecord = disabilitiesData.find(item => item.id === id);
        const deletedFiles = deletedRecord ? deletedRecord.file : [];

        const updatedPhotosData = photosData.filter(existingFile =>
            !deletedFiles.some(deletedFile => deletedFile.uid === existingFile.uid)
        );

        dispatch(setDisabilitiesData(updatedArray));
        dispatch(setPhotosData(updatedPhotosData));
    }

    const onDisabilitiesUploadChange = (info) => {
        setDisabilitiesFileList(info.fileList);
    };

    const handleDisabilitiesDeleteFileList = (uid) => {
        setDisabilitiesFileList((prevList) => prevList.filter((file) => file.uid !== uid));
    };

    // Performance Change //
    const performanceChangeColumns = [
        {
            title: t('organizer_common_note'),
            dataIndex: 'note',
            key: 'note',
            width: '300px',
            render: (text) => (
                <span style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {text}
                </span>
            ),
        },
        {
            title: <Flex justify='center'><Text>{t('organizer_common_action')}</Text></Flex>,
            key: 'action',
            width: '40px',
            render: (_, record) => {
                return (
                    <Flex justify='center'>
                        <Button
                            type='text'
                            className='padding-1'
                            onClick={() => handlePerformanceChangesUpdate(record)}
                        >
                            <EditIcon role='button' />
                        </Button>
                        <CommonPopconfirm
                            icon={null}
                            title={t('organizer_common_deleteTitle') + ' ' + t('organizer_common_note')?.toLowerCase()}
                            description={t('organizer_common_confirmationmessage') + ' ' + t('organizer_common_note')?.toLowerCase()}
                            onConfirm={() => handlePerformanceDelete(record.id)}
                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                        >
                            <Button
                                type='text'
                                className='padding-1'>
                                <DeleteIcon
                                    role='button'
                                />
                            </Button>
                        </CommonPopconfirm>
                    </Flex >
                );
            },
        }
    ]

    const performanceChangesItem = [
        {
            key: '1',
            label: <Flex gap={8}><Image src={noteIcon} preview={false} /><Title level={5}>{t('organizer_construction_diary_performancechanges')}</Title></Flex>,
            children: <Fragment>
                <Flex justify='center' align='center' style={{ height: '100%', minHeight: '15.25rem' }}>
                    {
                        performanceChangesData?.length > 0 ?
                            <Table
                                className='w-100 x-auto data-table'
                                columns={performanceChangeColumns}
                                dataSource={performanceChangesData}
                                scroll={{
                                    x: 300,
                                    y: 192,
                                }}
                                pagination={false}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <div>
                                            {record.file.length > 0 && (
                                                <Flex wrap>
                                                    {record.file.map((file) => (
                                                        <div key={file.uid} className='paddingX-1'>
                                                            <Image
                                                                src={URL.createObjectURL(file.originFileObj)}
                                                                alt={file.name}
                                                                style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '0.5rem' }}
                                                            />
                                                        </div>
                                                    ))}
                                                </Flex>
                                            )}
                                        </div>
                                    ),
                                    rowExpandable: () => true,
                                    expandIcon: () => null,
                                }}
                                expandIconColumnIndex={-1}
                                expandedRowKeys={performanceChangesData.map((item) => item.key)}
                            />
                            :
                            <Text>{t('organizer_common_notfound', { key: 'Leistungsänderungen', name: 'Performance Changes' })}</Text>
                    }
                </Flex>
            </Fragment>,
        }
    ];

    const performanceChangesExpandIcon = ({ isActive }) => {
        return <PlusIcon color={black} role='button' onClick={(e) => { e.stopPropagation(); setIsPerformanceChangeModal(true); setIsPerformanceChangeUpdate({ isUpdate: false, id: null }) }} />
    };

    const handlePerformanceChangesClear = () => {
        performanceChangeForm.resetFields();
        setIsPerformanceChangeModal(false);
        setPerformanceChangeFileList([]);
    };

    const handlePerformanceChangesOnFinish = (values) => {
        if (!values.note && !values.files) {
            openNotification({ type: 'error', message: 'Atleast one field is required' });
        } else {
            if (isPerformanceChangeUpdate.isUpdate) {
                const updatedArray = performanceChangesData.map((item) =>
                    item.id === isPerformanceChangeUpdate.id ? { ...item, note: values.note, file: performanceChangeFileList } : item
                );

                dispatch(setPerformanceChangesData(
                    updatedArray
                ));
                setIsPerformanceChangeModal(false);

                const updatedPhotosData = photosData.filter(existingFile => {
                    const isRelatedToCurrentRecord = performanceChangesData.some(item =>
                        item.id === isPerformanceChangeUpdate.id && item.file.some(file => file.uid === existingFile.uid)
                    );
                    return !isRelatedToCurrentRecord || performanceChangeFileList.some(newFile => newFile.uid === existingFile.uid);
                });

                const newFiles = performanceChangeFileList.filter(newFile =>
                    !updatedPhotosData.some(existingFile => existingFile.uid === newFile.uid)
                );

                const finalPhotosData = [...updatedPhotosData, ...newFiles];

                dispatch(setPhotosData(finalPhotosData));

                performanceChangeForm.resetFields();
                setPerformanceChangeFileList([]);
            } else {
                dispatch(setPerformanceChangesData([
                    ...performanceChangesData,
                    {
                        id: Date.now(),
                        note: values.note,
                        file: performanceChangeFileList
                    }
                ]));

                const updatedPhotosData = [...photosData, ...performanceChangeFileList];
                dispatch(setPhotosData(updatedPhotosData));

                setIsPerformanceChangeModal(false);
                performanceChangeForm.resetFields();
                setPerformanceChangeFileList([]);
            }
        }
    };

    const handlePerformanceChangesUpdate = (values) => {
        setIsPerformanceChangeUpdate({
            isUpdate: true,
            id: values.id
        });
        setIsPerformanceChangeModal(true);

        const data = performanceChangesData.filter((item) => values.id === item.id)[0];

        setPerformanceChangeFileList(data.file);
        performanceChangeForm.setFieldsValue({
            note: data.note,
        });
    }

    const handlePerformanceDelete = (id) => {
        const updatedArray = performanceChangesData.filter(item => item.id !== id);

        const deletedRecord = performanceChangesData.find(item => item.id === id);

        const deletedFiles = deletedRecord ? deletedRecord.file : [];

        const updatedPhotosData = photosData.filter(existingFile =>
            !deletedFiles.some(deletedFile => deletedFile.uid === existingFile.uid)
        );

        dispatch(setPerformanceChangesData(updatedArray));
        dispatch(setPhotosData(updatedPhotosData));
    }

    const onPerformanceChangesUploadChange = (info) => {
        setPerformanceChangeFileList(info.fileList);
    };

    const handlePerformanceChangesDelete = (uid) => {
        setPerformanceChangeFileList((prevList) => prevList.filter((file) => file.uid !== uid));
    };

    // Photos //
    const photosItem = [
        {
            key: '1',
            label: <Flex gap={8}><Image src={galleryIcon} preview={false} /><Title level={5}>{t('organizer_common_photos')}</Title></Flex>,
            children: <Fragment>
                <Flex style={{ height: '100%', minHeight: '15.25rem' }}>
                    {photosData.length > 0 ? (
                        <Flex wrap>
                            {photosData.map((file) => (
                                <div key={file.uid} style={{ position: 'relative', margin: '8px' }}>
                                    <Image
                                        src={URL.createObjectURL(file.originFileObj)}
                                        alt={file.name}
                                        style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '0.5rem' }}
                                    />
                                    <Button
                                        type="link"
                                        icon={<BlackCloseIcon role='button' />}
                                        onClick={() => handlePhotosDataDelete(file.uid)}
                                        style={{
                                            position: 'absolute',
                                            top: '-14px',
                                            right: '-9px',
                                        }}
                                    />
                                </div>
                            ))}
                        </Flex>
                    ) : <Flex justify='center' align='center' className='w-100'><Text>{t('organizer_common_notfound', { key: t('organizer_common_photos'), name: t('organizer_common_photos') })}</Text></Flex>
                    }
                </Flex>
            </Fragment>,
        }
    ];

    const photosExpandIcon = ({ isActive }) => {
        return <PlusIcon color={black} role='button' onClick={(e) => { e.stopPropagation(); setIsPhotosModal(true) }} />
    };

    const handlePhotosClear = () => {
        photosForm.resetFields();
        setIsPhotosModal(false);
        setPhotosFileList([]);
    };

    const handlePhotosOnFinish = (values) => {
        const data = [...photosFileList, ...photosData];
        dispatch(setPhotosData(data));
        setIsPhotosModal(false);
        setPhotosFileList([]);
    };

    const onPhotosUploadChange = (info) => {
        setPhotosFileList(info.fileList);
    };

    const handlePhotosDelete = (uid) => {
        setPhotosFileList((prevList) => prevList.filter((file) => file.uid !== uid));
    };

    const handlePhotosDataDelete = (uid) => {
        const data = photosData.filter((file) => file.uid !== uid);
        dispatch(setPhotosData(data));
    }

    // Signature //
    const handleSignatureClear = () => {
        sigCanvas.current.clear();
        setTrimmedUrl(null);
        signatureForm.resetFields();
    };

    const handleSignatureOnFinish = (values) => {
        const canvas = sigCanvas.current.getTrimmedCanvas();

        canvas.toBlob((blob) => {
            const imageUrl = URL.createObjectURL(blob);
            setTrimmedUrl(imageUrl);
        }, 'image/png');

        sigCanvas.current.clear();
        setSignatureModal(false);
        setManagerModal(true);
    };

    // Construction Manager Signature  Modal //
    const handleManagerClear = () => {
        managerSigCanvas.current.clear();
        setManagerTrimmedUrl(null);
    };

    const handleManagerOnFinish = () => {
        const canvas = managerSigCanvas.current.getTrimmedCanvas();

        canvas.toBlob((blob) => {
            const imageUrl = URL.createObjectURL(blob);

            setManagerTrimmedUrl(imageUrl);
        }, 'image/png');

        managerSigCanvas.current.clear();
        setSignatureModal(false);

        dispatch(getConstrucationDiary({
            organizer: loginUser?.organizer,
            user: loginUser?._id,
            project: diaryFormData?.project ? diaryFormData?.project : state.project,
            date: diaryFormData?.date ? diaryFormData?.date : state.date,
            bauvorhaben: diaryFormData?.bauvorhaben,
            weather: weathers?.map((item) => ({
                weather: item?.weather,
                temperature: item?.temperature,
                note: item?.note
            })),
            workers: workerData?.map((item) => ({
                name: item?.id,
                totalHrs: item?.totalHrs,
            })),
            performanceResults: performanceData?.map((item) => ({
                note: item?.note,
            })),
            performanceResultsPhotos: performanceData?.flatMap((item) => (
                item?.file?.map((i) => i)
            )),
            disabilities: disabilitiesData?.map((item) => ({
                note: item?.note,
            })),
            disabilitiesPhotos: disabilitiesData?.flatMap((item) => (
                item?.file?.map((i) => i)
            )),
            performanceChanges: performanceChangesData?.map((item) => ({
                note: item?.note,
            })),
            performanceChangesPhotos: performanceChangesData?.flatMap((item) => (
                item?.file?.map((i) => i)
            )),
            photos: photosData?.map((item) => ({
                originFileObj: item?.originFileObj
            })),
        }));

        setIsCreate(true);
    };

    useEffect(() => {
        if ((Object.keys(construcationDiary).length > 0 && (trimmedUrl && managerTrimmedUrl))) {
            if (isCreate) {
                const getFormData = async () => {
                    const formData = new FormData();

                    // Append basic fields
                    formData.append('organizer', construcationDiary?.organizer);
                    formData.append('user', construcationDiary?.user);
                    formData.append('project', construcationDiary?.project);
                    formData.append('date', construcationDiary?.date);
                    formData.append('constructionProjectOrbuildingComponent', construcationDiary?.bauvorhaben);
                    formData.append("weather", JSON.stringify(construcationDiary?.weather));
                    formData.append("workers", JSON.stringify(construcationDiary?.workers));
                    formData.append("performanceResults", JSON.stringify(construcationDiary?.performanceResults));
                    formData.append("disabilities", JSON.stringify(construcationDiary?.disabilities));
                    formData.append("performanceChanges", JSON.stringify(construcationDiary?.performanceChanges));

                    // Append photos array
                    construcationDiary?.photos?.forEach((photo) => {
                        formData.append(`photos`, photo.originFileObj);
                    });
                    construcationDiary?.performanceResultsPhotos?.forEach((photo) => {
                        formData.append(`performanceResultsPhotos`, photo.originFileObj);
                    });
                    construcationDiary?.disabilitiesPhotos?.forEach((photo) => {
                        formData.append(`disabilitiesPhotos`, photo.originFileObj);
                    });
                    construcationDiary?.performanceChangesPhotos?.forEach((photo) => {
                        formData.append(`performanceChangesPhotos`, photo.originFileObj);
                    });

                    const builderSignatureBlob = await fetch(trimmedUrl).then((res) => res.blob());
                    const managerSignatureBlob = await fetch(managerTrimmedUrl).then((res) => res.blob());

                    formData.append('builderSignature', builderSignatureBlob, 'builder-signature.png');
                    formData.append('constructionManagerSignature', managerSignatureBlob, 'manager-signature.png');

                    dispatch(createConstructionDiary({ formData: formData, organizer: construcationDiary?.organizer }));
                }

                getFormData();
            }
        }
    }, [construcationDiary, trimmedUrl, managerTrimmedUrl, isCreate]);

    useEffect(() => {
        if (isSuccess) {
            setWeathers([]);
            dispatch(setWorkerData([]));
            dispatch(setPerformanceData([]));
            dispatch(setDisabilitiesData([]));
            dispatch(setPerformanceChangesData([]));
            dispatch(setPhotosData([]));
            setTrimmedUrl(null);
            setManagerTrimmedUrl(null);
            dispatch(getConstrucationDiary({}));
            setDiaryFormData({ date: '', project: '', bauvorhaben: '' });
            setIsCreate(false);
            setManagerModal(false);
            form.resetFields();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (!isCreate) {
            setWeathers([]);
            dispatch(setWorkerData([]));
            dispatch(setPerformanceData([]));
            dispatch(setDisabilitiesData([]));
            dispatch(setPerformanceChangesData([]));
            dispatch(setPhotosData([]));
            setTrimmedUrl(null);
            setManagerTrimmedUrl(null);
            dispatch(getConstrucationDiary({}));
            setDiaryFormData({ date: '', project: '', bauvorhaben: '' });
        }
    }, [])

    // Get Construcation Diary Version List
    useEffect(() => {
        getConstrucationDiaryData();
    }, [loginUser?.organizer])

    const getConstrucationDiaryData = () => {
        loginUser?.organizer && dispatch(getConstrucationDiaryListData(loginUser?.organizer));
    }

    useEffect(() => {
        setDiaryVersions(construcationDiaryLists)
    }, [construcationDiaryLists])

    const getLocationPermission = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handlePermissionSuccess, handlePermissionDecline);
        } else {
            openNotification({ type: 'error', message: 'Geolocation not supported' });
        }
    };

    const handlePermissionSuccess = (position) => {
        dispatch(getWeatherData({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        }));
    };

    const handlePermissionDecline = () => {
        openNotification({ type: 'warning', message: 'Please allow location permission' });
    }

    useEffect(() => {
        getLocationPermission();
    }, []);

    useEffect(() => {
        Object.keys(weatherData).length !== 0 && setWeathers([
            {
                id: Date.now(),
                weather: weatherData?.wheatherType,
                temperature: Math.round(weatherData?.temp),
                note: weatherData?.text[0].toUpperCase() + weatherData?.text.slice(1)
            }
        ])
    }, [weatherData])

    const handleCancelAll = () => {
        setWeathers([]);
        dispatch(setWorkerData([]));
        dispatch(setPerformanceData([]));
        dispatch(setDisabilitiesData([]));
        dispatch(setPerformanceChangesData([]));
        dispatch(setPhotosData([]));
        setTrimmedUrl(null);
        setManagerTrimmedUrl(null);
        setDiaryFormData({ date: '', project: '', bauvorhaben: '' });
    }

    return (
        <Flex vertical gap={24} className='bg-white padding-1 construction-diary'>
            <Flex vertical className='w-100'>
                <Title level={4} type='success'>{t('organizer_construction_diary_title')}</Title>
                <Divider />
            </Flex>
            {/* Select Date and Project Form */}
            <Form
                form={form}
                name='constructiondiary'
                colon={false}
                className='h-100'
                layout='vertical'
            >
                <Row className='w-100 margin-0' gutter={24}>
                    <Col xxl={6} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='date'
                            label={t('organizer_common_date')}
                        >
                            <DatePicker format={'DD-MM-YYYY'} onChange={(e) => setDiaryFormData({ ...diaryFormData, date: dayjs(e).format('YYYY-MM-DD') })} className='w-100' allowClear={false} />
                        </Form.Item>
                    </Col>
                    <Col xxl={6} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='project'
                            label={t('organizer_common_project')}
                        >
                            <Select
                                showSearch
                                allowClear
                                className='w-100'
                                placeholder={t('organizer_common_project')}
                                onChange={(e) => setDiaryFormData({ ...diaryFormData, project: e })}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={stammProjects}
                            />
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={8} lg={24} md={24} sm={24} xs={24}>
                        {
                            Array.isArray(weathers) && weathers?.length > 0 &&
                            Array.isArray(workerData) && workerData?.length > 0 &&
                            Array.isArray(performanceData) && performanceData?.length > 0 &&
                            Array.isArray(disabilitiesData) && disabilitiesData?.length > 0 &&
                            Array.isArray(performanceChangesData) && performanceChangesData?.length > 0 &&
                            <Flex justify='flex-end' align='center' className='h-100' gap={8}>
                                <Button type='text' className='border-light-green' icon={<DiaryDocumentIcon role='Button' />} onClick={handleDownload}><Text>{t('organizer_construction_download_PDF')}</Text></Button>
                            </Flex>
                        }
                    </Col>
                </Row>
                <Row className='w-100 margin-0' gutter={24}>
                    <Col xxl={6} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='bauvorhaben'
                            label={t('organizer_construction_diary_constructioncomponent')}
                        >
                            <Input placeholder={t('organizer_construction_diary_constructioncomponent')} onChange={(e) => setDiaryFormData({ ...diaryFormData, bauvorhaben: e?.target?.value })} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className='w-100 margin-0' gutter={[24, 24]}>
                    {/* Constriction Diary Versions */}
                    {/* Show This collapse After Manager and Building owners Signture done */}

                    {
                        diaryVersions?.length > 0 &&
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='rounded-1'>
                            <Collapse
                                items={diaryVersionsItems}
                                defaultActiveKey={['1']}
                                collapsible='header'
                                className='w-100 border-light-green'
                                bordered={false}
                                expandIcon={diaryVersionsExpandIcon}
                                expandIconPosition={'end'}
                            />
                        </Col>
                    }

                    {/* Weather */}
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} className='rounded-1'>
                        <Collapse
                            items={weatherItems}
                            defaultActiveKey={['1']}
                            collapsible='header'
                            className='w-100 border-light-green'
                            bordered={false}
                            expandIcon={weatherExpandIcon}
                            expandIconPosition={'end'}
                        />
                    </Col>

                    {/* Workers List */}
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} className='rounded-1'>
                        <Collapse
                            items={userItems}
                            defaultActiveKey={['1']}
                            className='w-100 border-light-green'
                            bordered={false}
                            expandIcon={userExpandIcon}
                            expandIconPosition={'end'}
                        />
                    </Col>

                    {/* Performance Result */}
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='rounded-1'>
                        <Collapse
                            items={performanceResultItem}
                            defaultActiveKey={['1']}
                            className='w-100 border-light-green'
                            bordered={false}
                            expandIcon={performanceResultExpandIcon}
                            expandIconPosition={'end'}
                        />
                    </Col>

                    {/* Disabilities */}
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='rounded-1'>
                        <Collapse
                            items={disabilitiesItem}
                            defaultActiveKey={['1']}
                            className='w-100 border-light-green'
                            bordered={false}
                            expandIcon={disabilitiesExpandIcon}
                            expandIconPosition={'end'}
                        />
                    </Col>

                    {/* Performance Changes */}
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='rounded-1'>
                        <Collapse
                            items={performanceChangesItem}
                            defaultActiveKey={['1']}
                            className='w-100 border-light-green'
                            bordered={false}
                            expandIcon={performanceChangesExpandIcon}
                            expandIconPosition={'end'}
                        />
                    </Col>

                    {/* Photos */}
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='rounded-1'>
                        <Collapse
                            items={photosItem}
                            defaultActiveKey={['1']}
                            className='w-100 border-light-green'
                            bordered={false}
                            expandIcon={photosExpandIcon}
                            expandIconPosition={'end'}
                        />
                    </Col>
                </Row>
                <Flex justify='flex-end' gap={10} wrap={true} className='paddingY-3'>
                    {
                        Array.isArray(weathers) && weathers.length > 0 &&
                        Array.isArray(workerData) && workerData.length > 0 &&
                        Array.isArray(performanceData) && performanceData.length > 0 &&
                        Array.isArray(disabilitiesData) && disabilitiesData.length > 0 &&
                        Array.isArray(performanceChangesData) && performanceChangesData.length > 0 &&
                        (
                            (trimmedUrl || managerTrimmedUrl) ?
                                <BlobProvider
                                    key={`${trimmedUrl ?? 'default'}-${managerTrimmedUrl ?? 'default'}`}
                                    document={
                                        <ConstructionDiaryPDF
                                            data={{
                                                weather: weathers,
                                                date: diaryFormData?.date ? diaryFormData?.date : state.date,
                                                bauvorhaben: diaryFormData?.bauvorhaben || 'default',
                                                company: companyData,
                                                worker: workerData,
                                                signature: trimmedUrl ?? '',
                                                managerSignature: managerTrimmedUrl ?? '',
                                                performance: performanceData,
                                                disabilities: disabilitiesData,
                                                performanceChanges: performanceChangesData,
                                            }}
                                        />
                                    }
                                >
                                    {({ url, _ }) => {
                                        setDownload(url);
                                        return <a
                                            onClick={() => applyWatermarkToBlobUrl(url)}
                                            target='_blank'
                                            className='rounded-1'
                                            style={{
                                                display: 'block',
                                                lineHeight: 2.5,
                                                textAlign: 'center',
                                                color: white,
                                                width: '12.25rem',
                                                backgroundColor: black,
                                                boxShadow: 'none',
                                            }}
                                        >
                                            {t('organizer_common_preview')}
                                        </a>
                                    }}
                                </BlobProvider>
                                :
                                <BlobProvider
                                    document={
                                        <ConstructionDiaryPDF
                                            data={{
                                                date: diaryFormData?.date ? diaryFormData?.date : state.date,
                                                bauvorhaben: diaryFormData?.bauvorhaben || 'default',
                                                company: companyData || null,
                                                weather: weathers || null,
                                                worker: workerData || null,
                                                performance: performanceData || null,
                                                disabilities: disabilitiesData || null,
                                                performanceChanges: performanceChangesData || null,
                                            }}
                                        />
                                    }
                                >
                                    {({ url, _ }) => {
                                        setDownload(url)
                                        return <a
                                            onClick={() => applyWatermarkToBlobUrl(url)}
                                            target='_blank'
                                            className='rounded-1'
                                            style={{
                                                display: 'block',
                                                lineHeight: 2.5,
                                                textAlign: 'center',
                                                color: white,
                                                width: '12.25rem',
                                                backgroundColor: black,
                                                boxShadow: 'none',
                                            }}
                                        >
                                            {t('organizer_common_preview')}
                                        </a>
                                    }}
                                </BlobProvider>
                        )
                    }
                    <Button danger type='primary' htmlType='button' onClick={handleCancelAll} style={{ width: '12.25rem' }}>{t('organizer_common_cancelButton')}</Button>
                    <Button type='primary' htmlType='button' onClick={() => setSignatureModal(true)}>{t('organizer_common_add')} {t('organizer_common_constructiondiary')}</Button>
                </Flex>

                {/* Weather Modal */}
                <Modal
                    width={650}
                    open={open}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Flex gap={8}><SunIcon /><Title level={5}>{t('organizer_common_wether')}</Title></Flex><Divider /></Fragment>}
                    footer={null}
                    onCancel={handleWeatherClear}
                    modalRender={(dom) => (
                        <Form
                            layout='vertical'
                            form={weatherForm}
                            name='weather-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            onFinish={(values) => handleWeatherOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row gutter={24}>
                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='weather'
                                label={t('organizer_common_wether')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_wether') + ' ' + t('organizer_common_isrequired'),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('organizer_common_wether')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: 'overcast',
                                            label: t('organizer_construction_diary_overcast')
                                        },
                                        {
                                            label: t('organizer_construction_diary_frost'),
                                            value: 'frost'
                                        },
                                        {
                                            label: t('organizer_construction_diary_fog'),
                                            value: 'fog'
                                        },
                                        {
                                            label: t('organizer_construction_diary_rain'),
                                            value: 'rain'
                                        },
                                        {
                                            label: t('organizer_construction_diary_snow'),
                                            value: 'snow'
                                        },
                                        {
                                            label: t('organizer_construction_diary_sunny'),
                                            value: 'sunny'
                                        },
                                        {
                                            label: t('organizer_construction_diary_cloudy'),
                                            value: 'cloudy'
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='temperature'
                                label={t('organizer_common_temperature')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_temperature') + ' ' + t('organizer_common_isrequired'),
                                    },
                                ]}
                            >
                                <InputNumber min={'0'} className='w-100' placeholder={t('organizer_common_temperature')} />
                            </Form.Item>
                        </Col>

                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='note'
                                label={t('organizer_common_note')}
                            >
                                <TextArea rows={3} placeholder={t('organizer_common_note') + '...'} autoSize={{ minRows: 3, maxRows: 6 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='end' gap={18}>
                        <Button type='primary' danger htmlType='button' onClick={handleWeatherClear}>{t('organizer_common_cancelButton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Modal>

                {/* Workers Modal */}
                <Modal
                    width={650}
                    open={isUserModal}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Flex gap={8}><UserIcon /><Title level={5}>{t('organizer_common_add')} {t('organizer_construction_diary_workerslist')}</Title></Flex><Divider /></Fragment>}
                    footer={null}
                    onCancel={handleUserClear}
                    modalRender={(dom) => (
                        <Form
                            layout='vertical'
                            form={userForm}
                            name='workers-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            onFinish={(values) => handleUserOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row gutter={24}>
                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='worker'
                                label={t('organizer_common_worker')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_please_select') + ' ' + t('organizer_common_worker')?.toLowerCase(),
                                    },
                                ]}
                            >
                                <Select
                                    mode='multiple'
                                    showSearch
                                    allowClear
                                    labelInValue
                                    placeholder={t('organizer_common_worker')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={handleSelectedUser}
                                    disabled={isUserUpdate.isUpdate}
                                    options={stammUsers}
                                    tagRender={(props) => (
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                padding: '5px 10px',
                                                borderRadius: '4px',
                                                backgroundColor: '#f0f0f0',
                                                margin: '2px',
                                                color: '#333',
                                            }}
                                        >
                                            {props.label}
                                            {props.closable && (
                                                <span
                                                    onClick={props.onClose}
                                                    style={{
                                                        marginLeft: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    &times;
                                                </span>
                                            )}
                                        </span>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='workingHours'
                                label={t('organizer_common_workinghours')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_workinghours') + ' ' + t('organizer_common_isrequired'),
                                    },
                                ]}
                            >
                                <TimePicker format={'HH:mm'} placeholder={t('organizer_common_workinghours')} className='w-100' />
                            </Form.Item>
                        </Col>

                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='breakHours'
                                label={t('organizer_common_breakhours')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_breakhours') + ' ' + t('organizer_common_isrequired'),
                                    },
                                ]}
                            >
                                <TimePicker format={'HH:mm'} placeholder={t('organizer_common_breakhours')} className='w-100' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='end' gap={18}>
                        <Button type='primary' danger htmlType='button' onClick={handleUserClear}>{t('organizer_common_cancelButton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Modal>

                {/* Performance Result  */}
                <Modal
                    width={650}
                    open={isPerformanceResultModal}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Flex gap={8}><Image src={noteIcon} preview={false} /><Title level={5}>{t('organizer_construction_diary_performanceresult')}</Title></Flex><Divider /></Fragment>}
                    footer={null}
                    onCancel={handlePerformanceResultClear}
                    modalRender={(dom) => (
                        <Form
                            layout='vertical'
                            form={performanceResultForm}
                            name='performance-result-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            onFinish={(values) => handlePerformanceResultOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row gutter={24}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='note'
                                label={t('organizer_common_note')}
                            >
                                <TextArea rows={3} placeholder={t('organizer_common_note') + '...'} autoSize={{ minRows: 3, maxRows: 6 }} />
                            </Form.Item>
                        </Col>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='files'
                                label={t('organizer_common_uploadedfile')}
                                valuePropName='files'
                                getValueFromEvent={(e) => e && e.fileList}
                            >
                                <Upload.Dragger multiple={true} showUploadList={false} name='files' fileList={fileList} onChange={onUploadChange}>
                                    <Flex justify='center' align='center' gap={24}>
                                        <UploadIcon size={30} />
                                        <Text>{t('organizer_common_upload_photo_here')} </Text>
                                    </Flex>
                                </Upload.Dragger>
                            </Form.Item>
                            {fileList.length > 0 && (
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {fileList.map((file) => (
                                        <div key={file.uid} style={{ position: 'relative', margin: '8px' }}>
                                            <img
                                                src={URL.createObjectURL(file.originFileObj)}
                                                alt={file.name}
                                                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '0.5rem' }}
                                            />
                                            <Button
                                                type="link"
                                                icon={<BlackCloseIcon role='button' />}
                                                onClick={() => handleDelete(file.uid)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '-14px',
                                                    right: '-9px',
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Flex justify='end' gap={18}>
                        <Button type='primary' danger htmlType='button' onClick={handlePerformanceResultClear}>{t('organizer_common_cancelButton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Modal>

                {/* Disabilities  */}
                <Modal
                    width={650}
                    open={isDisabilitiesModal}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Flex gap={8}><Image src={noteIcon} preview={false} /><Title level={5}>{t('organizer_construction_diary_disabilities')}</Title></Flex><Divider /></Fragment>}
                    footer={null}
                    onCancel={handledisabilitiesClear}
                    modalRender={(dom) => (
                        <Form
                            layout='vertical'
                            form={disabilitiesForm}
                            name='disabilities-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            onFinish={(values) => handleDisabilitiesOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row gutter={24}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='note'
                                label={t('organizer_common_note')}
                            >
                                <TextArea rows={3} placeholder={t('organizer_common_note') + '...'} autoSize={{ minRows: 3, maxRows: 6 }} />
                            </Form.Item>
                        </Col>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='files'
                                label={t('organizer_common_uploadedfile')}
                                valuePropName='files'
                                getValueFromEvent={(e) => e && e.fileList}
                            >
                                <Upload.Dragger multiple={true} showUploadList={false} name='files' fileList={disabilitiesFileList} onChange={onDisabilitiesUploadChange}>
                                    <Flex justify='center' align='center' gap={24}>
                                        <UploadIcon size={30} />
                                        <Text>{t('organizer_common_upload_photo_here')} </Text>
                                    </Flex>
                                </Upload.Dragger>
                            </Form.Item>
                            {disabilitiesFileList.length > 0 && (
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {disabilitiesFileList.map((file) => (
                                        <div key={file.uid} style={{ position: 'relative', margin: '8px' }}>
                                            <img
                                                src={URL.createObjectURL(file.originFileObj)}
                                                alt={file.name}
                                                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '0.5rem' }}
                                            />
                                            <Button
                                                type="link"
                                                icon={<BlackCloseIcon role='button' />}
                                                onClick={() => handleDisabilitiesDeleteFileList(file.uid)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '-14px',
                                                    right: '-9px',
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Flex justify='end' gap={18}>
                        <Button type='primary' danger htmlType='button' onClick={handledisabilitiesClear}>{t('organizer_common_cancelButton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Modal>

                {/* Performance Changes */}
                <Modal
                    width={650}
                    open={isPerformanceChangeModal}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Flex gap={8}><Image src={noteIcon} preview={false} /><Title level={5}>{t('organizer_construction_diary_performancechanges')}</Title></Flex><Divider /></Fragment>}
                    footer={null}
                    onCancel={handlePerformanceChangesClear}
                    modalRender={(dom) => (
                        <Form
                            layout='vertical'
                            form={performanceChangeForm}
                            name='performancechanges-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            onFinish={(values) => handlePerformanceChangesOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row gutter={24}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='note'
                                label={t('organizer_common_note')}
                            >
                                <TextArea rows={3} placeholder={t('organizer_common_note') + '...'} autoSize={{ minRows: 3, maxRows: 6 }} />
                            </Form.Item>
                        </Col>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='files'
                                label={t('organizer_common_uploadedfile')}
                                valuePropName='files'
                                getValueFromEvent={(e) => e && e.fileList}
                            >
                                <Upload.Dragger multiple={true} showUploadList={false} name='files' fileList={performanceChangeFileList} onChange={onPerformanceChangesUploadChange}>
                                    <Flex justify='center' align='center' gap={24}>
                                        <UploadIcon size={30} />
                                        <Text>{t('organizer_common_upload_photo_here')} </Text>
                                    </Flex>
                                </Upload.Dragger>
                            </Form.Item>
                            {performanceChangeFileList?.length > 0 && (
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {performanceChangeFileList?.map((file) => (
                                        <div key={file.uid} style={{ position: 'relative', margin: '8px' }}>
                                            <img
                                                src={URL.createObjectURL(file.originFileObj)}
                                                alt={file.name}
                                                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '0.5rem' }}
                                            />
                                            <Button
                                                type="link"
                                                icon={<BlackCloseIcon role='button' />}
                                                onClick={() => handlePerformanceChangesDelete(file.uid)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '-14px',
                                                    right: '-9px',
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Flex justify='end' gap={18}>
                        <Button type='primary' danger htmlType='button' onClick={handlePerformanceChangesClear}>{t('organizer_common_cancelButton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Modal>

                {/* Photos */}
                <Modal
                    width={650}
                    open={isPhotosModal}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Flex gap={8}><Image src={galleryIcon} preview={false} /><Title level={5}>{t('organizer_common_photos')}</Title></Flex><Divider /></Fragment>}
                    footer={null}
                    onCancel={handlePhotosClear}
                    modalRender={(dom) => (
                        <Form
                            layout='vertical'
                            form={photosForm}
                            name='photos-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            onFinish={(values) => handlePhotosOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row gutter={24}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='files'
                                label={t('organizer_common_upload_photo')}
                                valuePropName='files'
                                getValueFromEvent={(e) => e && e.fileList}
                            >
                                <Upload.Dragger multiple={true} showUploadList={false} name='files' fileList={photosFileList} onChange={onPhotosUploadChange}>
                                    <Flex justify='center' align='center' gap={24}>
                                        <UploadIcon size={30} />
                                        <Text>{t('organizer_common_upload_photo_here')} </Text>
                                    </Flex>
                                </Upload.Dragger>
                            </Form.Item>
                            {photosFileList?.length > 0 && (
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {photosFileList?.map((file) => (
                                        <div key={file.uid} style={{ position: 'relative', margin: '8px' }}>
                                            <Image
                                                src={URL.createObjectURL(file.originFileObj)}
                                                alt={file.name}
                                                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '0.5rem' }}
                                            />
                                            <Button
                                                type="link"
                                                icon={<BlackCloseIcon role='button' />}
                                                onClick={() => handlePhotosDelete(file.uid)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '-14px',
                                                    right: '-9px',
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Flex justify='end' gap={18}>
                        <Button type='primary' danger htmlType='button' onClick={handlePhotosClear}>{t('organizer_common_cancelButton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Modal>

                {/* Signature Modal */}
                <Modal
                    width={650}
                    open={signatureModal}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Flex gap={8}><Image src={diaryIcon} preview={false} /><Title level={5}>{t('organizer_construction_diary_conformation_diary')}</Title></Flex><Divider /></Fragment>}
                    footer={null}
                    onCancel={() => { setSignatureModal(false); setTrimmedUrl(null); sigCanvas.current.clear(); signatureForm.resetFields(); }}
                    modalRender={(dom) => (
                        <Form
                            form={signatureForm}
                            layout='vertical'
                            name='signature-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            onFinish={(values) => handleSignatureOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row gutter={24}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='files'
                                label={t('organizer_construction_diary_signature_of_the_builders')}
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => {
                                            // Check if the canvas exists and is not empty
                                            const canvas = sigCanvas.current;
                                            if (canvas && !canvas.isEmpty()) {
                                                return Promise.resolve(); // Validation passed
                                            }
                                            return Promise.reject(new Error(t('Signature is required!'))); // Validation failed
                                        },
                                    },
                                ]}
                            >
                                <Flex className='border-light-green rounded-1 padding-1' style={{ height: '12rem' }}>
                                    <SignatureCanvas
                                        ref={sigCanvas}
                                        penColor={black}
                                        backgroundColor={white}
                                        canvasProps={{
                                            className: 'w-100 h-100'
                                        }}
                                    />
                                </Flex>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='end' gap={18}>
                        <Button type='primary' danger htmlType='button' onClick={handleSignatureClear}>{t('organizer_common_clear')} {t('organizer_common_signature')}</Button>
                        <Button type='primary' htmlType='submit' style={{ width: '9.25rem' }}>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Modal>

                {/* Construction Manager Signature  Modal */}
                <Modal
                    width={650}
                    open={managerModal}
                    closeIcon={<CloseIcon color={black} role='button' />}
                    title={<Fragment><Flex gap={8}><Image src={diaryIcon} preview={false} /><Title level={5}>{t('organizer_construction_diary_conformation_diary')}</Title></Flex><Divider /></Fragment>}
                    footer={null}
                    onCancel={() => { setManagerModal(false); managerSigCanvas.current.clear(); }}
                    modalRender={(dom) => (
                        <Form
                            layout='vertical'
                            name='managersignature-form'
                            requiredMark={(label, isRule) => {
                                return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                            }}
                            onFinish={(values) => handleManagerOnFinish(values)}
                        >
                            {dom}
                        </Form>
                    )}
                >
                    <Row gutter={24}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name='files'
                                label={t('organizer_construction_diary_signature_of_the_manager')}
                            >
                                <Flex className='border-light-green rounded-1 padding-1' style={{ height: '12rem' }}>
                                    <SignatureCanvas
                                        ref={managerSigCanvas}
                                        penColor={black}
                                        backgroundColor={white}
                                        canvasProps={{
                                            className: 'w-100 h-100'
                                        }}
                                    />
                                </Flex>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='end' gap={18}>
                        <Button type='primary' htmlType='button' onClick={() => { setSignatureModal(true); setManagerModal(false) }} style={{ backgroundColor: black, boxShadow: 'none', width: '9.25rem' }}>{t('organizer_common_back')}</Button>
                        <Button type='primary' danger htmlType='button' onClick={handleManagerClear}>{t('organizer_common_clear')} {t('organizer_common_signature')}</Button>
                        <Button type='primary' htmlType='submit' style={{ width: '9.25rem' }} loading={loading}>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Modal>
            </Form >
        </Flex >
    );
};

export default AddConstructionDiary;
