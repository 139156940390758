import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Collapse, Flex, Form, Input, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { createCity, getByIdCity, getCity, getCityListsData, setIsSuccess, setIsUpdating, updateCity } from './redux/slice';

import DataTable from '../../components/data-table';

import ExpandIcon from '../../components/icon/ExpandIcon';
import BackIcon from '../../components/icon/BackIcon';
import EditIcon from '../../components/icon/EditIcon';

const { Title, Text } = Typography;

const City = ({ permission }) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { city, auth } = useSelector(state => state);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [cityLists, setCityLists] = useState([]);
    const [modalType, setModalType] = useState(false);
    const [cityEditId, setCityEditId] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        getCityList()
    }, [page, pageSize, auth, sortBy, order, totalRecords])

    useEffect(() => {
        setCityLists(city.cityLists);
        setTotalRecords(city.totalRecords);
    }, [city.cityLists, city.totalRecords]);

    const getCityList = () => {
        dispatch(getCityListsData({
            sortBy: sortBy,
            order: order,
            search: '',
            page: page,
            limit: pageSize,
        }))
    }

    const handleOnFinish = (values) => {
        if (modalType) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getCity({ ...values }));
    };

    useEffect(() => {
        if (Object.keys(city.city).length > 0) {
            if (!modalType && !city.isUpdating) {
                dispatch(createCity({ apiData: city.city }));
            } else {
                if (city.isUpdating) {
                    form.setFieldsValue(city?.city);
                } else {
                    dispatch(updateCity({ apiData: { ...city.city, id: cityEditId } }));
                }
            }
        }
        if (city.isSuccess) {
            handleClear();
            setModalType(false);
            dispatch(setIsSuccess(false));
        }
    }, [city.isUpdating, city.city, city.isSuccess, cityEditId]);

    const handleClear = () => {
        form.resetFields();
        dispatch(getCity({}))
        setCityEditId('');
        setModalType(false);
    };

    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const handleEditCity = (cityId) => {
        setModalType(true);
        setCityEditId(cityId);
        dispatch(setIsUpdating(true));
        dispatch(getByIdCity(cityId));
    }

    // Table columns
    const columns = [
        {
            title: t('organizer_common_city'),
            dataIndex: 'name',
            key: 'name',
            render: (text) => <Text>{text}</Text>,
            sorter: (_, __, order) => handleSortFunction(order, 'name'),
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate)) {
        columns.push({
            title: <Flex justify='center'><Text>{t('superadmin_common_action')}</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                return (
                    <Flex justify='center'>
                        {
                            permission?.canUpdate &&
                            <Button
                                type='text'
                                className='padding-1'
                                onClick={() => handleEditCity(record?._id)}
                            >
                                <EditIcon role='button' />
                            </Button>
                        }
                    </Flex >
                );
            },
        })
    }

    const items = [
        {
            key: '1',
            label: <Title level={4} type='success' className='text-primary'>{modalType ? t('organizer_usermanagement_usertype_edit') + ' ' + t('organizer_common_city') : t('organizer_common_add') + ' ' + t('organizer_common_city')}</Title>,
            children: <Fragment>
                <Form
                    form={form}
                    className='user-filter'
                    name='city'
                    colon={false}
                    layout='vertical'
                    onFinish={handleOnFinish}
                    requiredMark={(label, isRule) => {
                        return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                    }}
                >
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24} >
                            <Form.Item
                                name='name'
                                label={t('organizer_common_city')}
                                rules={[{ required: true, message: t('organizer_common_cityerror') }]}
                            >
                                <Input placeholder={t('organizer_common_city')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' onClick={handleClear}>{t('organizer_common_cancelButton')}</Button>
                        <Button type='primary' htmlType='submit' loading={city?.loading}>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Form>
            </Fragment>,
        }
    ];

    return (
        <Fragment>
            <Flex vertical gap={12} className='user-management change-tag'>
                <Button
                    type='text'
                    icon={<BackIcon role={'button'} />}
                    className='bg-white paddingX-3'
                    onClick={() => navigate(-1)}
                />
                {
                    (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                    <Flex className='filter-margin-top'>
                        <Collapse
                            items={items}
                            defaultActiveKey={['1']}
                            className='w-100'
                            bordered={false}
                            expandIcon={customExpandIcon}
                            expandIconPosition={'end'}
                        />
                    </Flex>
                }
                <Flex vertical className='bg-white rounded-top-1'>
                    <DataTable
                        isPagination={true}
                        showSorterTooltip={false}
                        total={totalRecords}
                        pageSize={pageSize}
                        currentPage={page}
                        columns={columns}
                        data={cityLists}
                        handleOnChange={handleOnChange}
                        rowKey={(record) => record?._id}
                    />
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default City;
