import React, { cloneElement, Fragment, isValidElement, useEffect, useState } from 'react';

import {
    Route,
    Routes,
    Navigate,
    Outlet,
    useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';

import Wrapper from '../layout/Wrapper';
import NotFound from '../pages/404';
import Unauthorized from '../pages/401';
import InternalServerError from '../pages/500';

import { privateRoutes, publicRoutes } from '../navigations/navigations';

const RequireAuth = () => {
    const location = useLocation();
    const { auth } = useSelector(state => state);
    const getToken = localStorage.getItem('token');

    if (!getToken) {
        return <Navigate to='/user/authentication/sign-in' replace />;
    }

    if (getToken && (location.pathname === '/user/authentication/sign-in' || location.pathname === '/')) {
        return <Navigate to='/dashboard' replace />;
    }

    if ((getToken && auth?.isAuthorize) && location.pathname !== '/settings/general') {
        return <Navigate to='/settings/general' replace />;
    }
    return <Outlet />;
};

const CheckIsLoggedIn = ({ element }) => {
    const location = useLocation();
    const getToken = localStorage.getItem('token');

    if (!getToken) {
        return element;
    }
    if (getToken && (publicRoutes.filter((e) => e.to.includes(location.pathname.split('/')?.[1])) || location.pathname === '/')) {
        return <Navigate to='/dashboard' replace />;
    }
    return <Outlet />;
};

const AppRouter = () => {
    const { user, auth } = useSelector(state => state)
    const [accessibleRoutes, setAccessibleRoutes] = useState([]);


    useEffect(() => {
        const token = localStorage.getItem('token');
        const user = token && jwtDecode(token);

        const filterRoutes = (routes, permissions) => {
            return routes?.map(route => {
                const matchingPermission = permissions?.find(
                    permission => permission?.menu === route.menu && permission?.permissions?.hasAccess
                );

                if (matchingPermission) {
                    if (route.children && route.children.length > 0) {
                        const filteredChildren = route.children
                            .filter(child =>
                                matchingPermission.children &&
                                matchingPermission.children?.some(permissionChild =>
                                    permissionChild?.menu === child?.menu && permissionChild?.permissions?.hasAccess
                                )
                            )
                            .map(child => {
                                const childPermission = matchingPermission.children?.find(
                                    permissionChild => permissionChild?.menu === child?.menu
                                );
                                return {
                                    ...child,
                                    ...childPermission,
                                };
                            });

                        return {
                            ...route,
                            ...matchingPermission,
                            children: filteredChildren,
                        };
                    }

                    return {
                        ...route,
                        ...matchingPermission,
                    };
                }

                return null;
            }).filter(route => route !== null);
        };

        const allRoutes = filterRoutes(privateRoutes, user?.permissions[0]?.menu);

        setAccessibleRoutes(allRoutes);

    }, [user, privateRoutes, auth]);

    return (
        <Routes>
            <Route
                path='*'
                element={
                    (
                        <NotFound />
                    )
                }
            />
            <Route
                path='/unauthorized'
                element={
                    (
                        <Unauthorized />
                    )
                }
            />
            <Route
                path='/internal-server'
                element={
                    (
                        <InternalServerError />
                    )
                }
            />
            {
                publicRoutes.map((routes) => {
                    return (
                        <Route
                            key={routes.to}
                            element={<CheckIsLoggedIn element={routes.element} />}
                            path={routes.to}
                        />
                    );
                })
            }
            <Route element={<RequireAuth />} path='/'>
                {
                    accessibleRoutes?.map((route) => {
                        const element = isValidElement(route.element) ? cloneElement(route.element, { permission: route?.permissions }) : route.element;
                        return (
                            <Fragment key={route.to}>
                                {
                                    route.children?.length <= 0 &&
                                    <Route
                                        key={route.to}
                                        element={<Wrapper>{element}</Wrapper>}
                                        path={route.to}
                                    />
                                }
                                {
                                    route.children?.length > 0 &&
                                    <Route
                                        key={route.to}
                                        path={route.to}
                                    >
                                        {
                                            route.children?.map((children) => {
                                                const childElement = isValidElement(children.element) ? cloneElement(children.element, { permission: children?.permissions }) : children.element;
                                                return (
                                                    <Route
                                                        key={children.to}
                                                        element={<Wrapper>{childElement}</Wrapper>}
                                                        path={children.to}
                                                    />
                                                )

                                            })
                                        }
                                    </Route>
                                }
                            </Fragment>
                        );
                    })
                }
            </Route>
        </Routes>
    );
};

export default AppRouter;
