import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Flex, Input, Row, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

import { deleteUser, getUsersListData, setLoginUserId, setLoginUserRole } from './redux/slice';
import { checkInvitation, resendInvitation, setInvitationMsg, setIsInvitation, setIsSuccess } from '../auth/redux/slice';
import { deleteClient, getClientsListData } from '../administrator/redux/slice';

import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';

import DeleteIcon from '../../components/icon/DeleteIcon';
import EditIcon from '../../components/icon/EditIcon';
import EmailIcon from '../../components/icon/EmailIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import SearchIcon from '../../components/icon/SearchIcon';

import { lightPrimary, lightSecondary, lightYellow } from '../../utils/theme/config';

const { Title, Text } = Typography;

const User = ({ permission }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, auth, client } = useSelector(state => state);

    const [pageSize, setPageSize] = useState(10);
    const [loginUser, setLoginUser] = useState({});
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [userList, setUserList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [clientTotalRecords, setClientTotalRecords] = useState(0);
    const [search, setSearch] = useState('');
    const [userId, setUserId] = useState('')
    const [visiblePopconfirm, setVisiblePopconfirm] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
            dispatch(setLoginUserId(user._id))
            dispatch(setLoginUserRole(user.role))
        }
    }, [])

    useEffect(() => {
        getUsersList();
    }, [pageSize, page, sortBy, order, search, loginUser]);

    const getUsersList = () => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getClientsListData({
                sortBy: sortBy,
                order: order,
                search: search?.trim(),
                page: page,
                limit: pageSize,
                organizer: auth?.decodeToken?.organizer
            }))
        } else {
            dispatch(getUsersListData({
                sortBy: sortBy,
                order: order,
                search: search?.trim(),
                page: page,
                limit: pageSize,
                ...(user.loginUserRole === 'Client') && { client: user.loginUserId }
            }))
        }
    };

    useEffect(() => {
        if (auth?.decodeToken?.organizer) {
            setUserList(client.clientsList)
        } else {
            setUserList(user.usersList);
        }
        setTotalRecords(user.totalRecords);
        setClientTotalRecords(client?.totalRecords)
    }, [user.usersList, client.clientsList, user.totalRecords, search]);

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    useEffect(() => {
        document.documentElement.scrollTop = document.documentElement.clientHeight;
        document.documentElement.scrollLeft = document.documentElement.clientWidth;
    }, []);

    const handleCheckInvitation = (userId) => {
        setUserId(userId);
        dispatch(checkInvitation(userId))
            .then((result) => {
                if (result.meta.requestStatus === 'fulfilled') {
                    if (Object.keys(auth?.invitationMsg).length > 0) {
                        setVisiblePopconfirm(prevState => ({
                            ...prevState,
                            [userId]: true
                        }));
                    }
                }
            });
    };

    const handleCancel = (userId) => {
        setVisiblePopconfirm(prevState => ({
            ...prevState,
            [userId]: false
        }));
        setUserId('');
    };

    const handleConfirm = (userId) => {
        dispatch(resendInvitation(userId));
        dispatch(setInvitationMsg({}));
        handleCancel(userId);
    };

    useEffect(() => {
        if (Object.keys(auth?.invitationMsg).length > 0 && auth?.isInvitation && auth.isSuccess) {
            setVisiblePopconfirm(prevState => ({
                ...prevState,
                [userId]: true
            }));
            setUserId('');
            dispatch(setIsSuccess(false));
            dispatch(setIsInvitation(false));
        }
    }, [auth?.invitationMsg, auth?.isInvitation, auth.isSuccess]);

    const columns = [
        {
            title: auth?.decodeToken?.package ? t('organizer_common_firstname') : t('superadmin_common_firstname'),
            dataIndex: 'firstName',
            width: '150px',
            key: 'firstName',
            sorter: (_, __, order) => handleSortFunction(order, 'firstName'),
            render: (_, record) => record?.firstName || '-'
        },
        {
            title: auth?.decodeToken?.package ? t('organizer_common_lastname') : t('superadmin_common_lastname'),
            dataIndex: 'lastName',
            width: '150px',
            key: 'lastname',
            sorter: (_, __, order) => handleSortFunction(order, 'lastName'),
            render: (_, record) => record?.lastName || '-'
        },
        {
            title: auth?.decodeToken?.package ? t('organizer_common_email') : t('superadmin_common_email'),
            dataIndex: 'email',
            width: '200px',
            key: 'email',
            sorter: (_, __, order) => handleSortFunction(order, 'email')
        },
        {
            title: auth?.decodeToken?.package ? t('organizer_common_phoneNo') : t('superadmin_common_phoneNo'),
            dataIndex: 'phone',
            width: '200px',
            key: 'phone number',
            sorter: (_, __, order) => handleSortFunction(order, 'phone')
        },
        {
            title: auth?.decodeToken?.package ? t('organizer_common_usertype') : t('superadmin_common_usertype'),
            dataIndex: 'userType',
            key: 'userType',
            width: '250px',
            sorter: (_, __, order) => handleSortFunction(order, 'userType'),
            render: (type) => {
                return <Tag color={lightPrimary} className='text-center'>{type}</Tag>
            }
        },
        {
            title: auth?.decodeToken?.package ? t('organizer_common_streetName') : t('superadmin_common_streetName'),
            dataIndex: 'streetNo',
            key: 'streetNo',
            width: '250px',
            sorter: (_, __, order) => handleSortFunction(order, 'streetNo'),
            render: (_, record) => {
                return record?.address?.streetNo && record?.address?.streetNo?.toString()
                    ?.split(' ')
                    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    ?.join(' ');
            }
        },
        {
            title: auth?.decodeToken?.package ? t('organizer_common_city') : t('superadmin_common_city'),
            dataIndex: 'city',
            key: 'city',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'city'),
            render: (_, record) => {
                const city = record?.address?.city && record.address.city
                    ?.split(' ')
                    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    ?.join(' ');
                return city;
            }
        },
        {
            title: auth?.decodeToken?.package ? t('organizer_common_zip') : t('superadmin_common_zip'),
            dataIndex: 'zip',
            key: 'zip',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'zip'),
            render: (_, record) => {
                return record?.address?.zip
            }
        },
        {
            title: auth?.decodeToken?.package ? t('organizer_common_status') : t('superadmin_common_status'),
            dataIndex: 'status',
            key: 'status',
            width: '250px',
            sorter: (_, __, order) => handleSortFunction(order, 'status'),
            render: (_, record) => {
                return (
                    <Fragment>
                        {
                            Number(record.status) === 1 ?
                                <Tag color={lightPrimary} className='text-center'>{auth?.decodeToken?.package ? t('organizer_common_active') : t('superadmin_common_active')}</Tag> :
                                Number(record.status) === 2 ?
                                    <Tag color={lightYellow} className='text-center'>{auth?.decodeToken?.package ? t('organizer_common_confirmation') : t('superadmin_common_confirmation')}</Tag> :
                                    <Tag color={lightSecondary} className='text-center'>{auth?.decodeToken?.package ? t('organizer_common_deactivate') : t('superadmin_common_deactivate')}</Tag>
                        }
                    </Fragment>
                );
            }
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>{auth?.decodeToken?.package ? t('organizer_common_action') : t('superadmin_common_action')}</Text></Flex>,
            key: 'action',
            width: '150px',
            render: (_, record) => {
                return (
                    < Flex justify='center'>
                        {
                            Number(record?.status) === 2 &&
                            <Fragment>
                                <CommonPopconfirm
                                    visible={!!visiblePopconfirm[record?._id]}
                                    title={Object.keys(auth?.invitationMsg).length > 0 && auth?.invitationMsg?.title}
                                    description={Object.keys(auth?.invitationMsg).length > 0 && auth?.invitationMsg?.description}
                                    onConfirm={() => handleConfirm(record?._id)}
                                    onCancel={() => handleCancel(record?._id)}
                                    icon={null}
                                    okText={auth?.decodeToken?.package ? t('superadmin_common_okButton') : t('superadmin_common_okButton')}
                                    cancelText={auth?.decodeToken?.package ? t('organizer_common_cancelButton') : t('superadmin_common_cancelButton')}
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                    onVisibleChange={(visible) => !visible && handleCancel(record?._id)}
                                >
                                    <Button
                                        type='text'
                                        className='padding-1'
                                        onClick={() => handleCheckInvitation(record._id)}
                                    >
                                        <EmailIcon role='button' />
                                    </Button>
                                </CommonPopconfirm>
                                {
                                    permission?.canUpdate &&
                                    <Button
                                        type='text'
                                        className='padding-1'
                                        onClick={() => navigate(`/user/edit/${record?._id}`)}>
                                        <EditIcon role='button' />
                                    </Button>
                                }
                                {
                                    permission?.canDelete &&
                                    <Fragment>
                                        {
                                            Number(record?.status) !== 3 ?
                                                !record?.isImmutable
                                                && (record?._id !== loginUser?._id && (<CommonPopconfirm
                                                    title={auth?.decodeToken?.package ? t('organizer_usermanagement_user_deactivateUser', { name: 'user', status: "Deactivate" }) : t('superadmin_usermanagement_user_deactivateUser', { name: 'user', status: "Deactivate" })}
                                                    description={auth?.decodeToken?.package ? t('organizer_usermanagement_user_confirmdescription', { name: 'user', status: "deactivate" }) : t('superadmin_usermanagement_user_confirmdescription', { name: 'user', status: "deactivate" })}
                                                    onConfirm={() =>
                                                        loginUser?.organizer ?
                                                            dispatch(deleteClient({
                                                                id: record?._id,
                                                                sortBy: '',
                                                                order: '',
                                                                search: '',
                                                                page: page,
                                                                limit: pageSize,
                                                                organizer: loginUser?.organizer ? loginUser?.organizer : null
                                                            }))
                                                            : dispatch(deleteUser({
                                                                id: record?._id,
                                                                sortBy: '',
                                                                order: '',
                                                                search: '',
                                                                page: page,
                                                                limit: pageSize,
                                                            }))}
                                                    icon={null}
                                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                                >
                                                    <Button
                                                        type='text'
                                                        className='padding-1'>
                                                        <DeleteIcon
                                                            role='button'
                                                        />
                                                    </Button>
                                                </CommonPopconfirm>))
                                                :
                                                <CommonPopconfirm
                                                    icon={null}
                                                    title={auth?.decodeToken?.package ? t('organizer_usermanagement_user_deactivateUser', { name: 'user', status: "Activate" }) : t('superadmin_usermanagement_user_deactivateUser', { name: 'user', status: "Activate" })}
                                                    description={auth?.decodeToken?.package ? t('organizer_usermanagement_user_confirmdescription', { name: 'user', status: "activate" }) : t('superadmin_usermanagement_user_confirmdescription', { name: 'user', status: "activate" })}
                                                    onConfirm={() =>
                                                        auth?.decodeToken?.organizer ?
                                                            dispatch(deleteClient({
                                                                id: record?._id,
                                                                sortBy: '',
                                                                order: '',
                                                                search: '',
                                                                page: page,
                                                                limit: pageSize,
                                                                organizer: loginUser?.organizer ? loginUser?.organizer : null
                                                            }))
                                                            : dispatch(deleteUser({
                                                                id: record?._id,
                                                                sortBy: '',
                                                                order: '',
                                                                search: '',
                                                                page: page,
                                                                limit: pageSize,
                                                            }))}
                                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                                >
                                                    <Button
                                                        type='text'
                                                        className='padding-1'
                                                    >
                                                        <CloseIcon role='button' />
                                                    </Button>
                                                </CommonPopconfirm>
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        }
                        {
                            Number(record?.status) !== 2 &&
                            <Fragment>
                                {
                                    permission?.canUpdate &&
                                    <Button
                                        type='text'
                                        className='padding-1'
                                        onClick={() => navigate(`/user/edit/${record?._id}`)}>
                                        <EditIcon role='button' />
                                    </Button>
                                }
                                {
                                    permission?.canDelete &&
                                    <Fragment>
                                        {
                                            Number(record?.status) !== 3 ?
                                                !record?.isImmutable
                                                && (record?._id !== loginUser?._id && (<CommonPopconfirm
                                                    title={auth?.decodeToken?.package ? t('organizer_usermanagement_user_deactivateUser', { name: 'user', status: "Deactivate" }) : t('superadmin_usermanagement_user_deactivateUser', { name: 'user', status: "Deactivate" })}
                                                    description={auth?.decodeToken?.package ? t('organizer_usermanagement_user_confirmdescription', { name: 'user', status: "deactivate" }) : t('superadmin_usermanagement_user_confirmdescription', { name: 'user', status: "deactivate" })}
                                                    onConfirm={() => loginUser?.organizer ?
                                                        dispatch(deleteClient({
                                                            id: record?._id,
                                                            sortBy: '',
                                                            order: '',
                                                            search: '',
                                                            page: page,
                                                            limit: pageSize,
                                                            organizer: loginUser?.organizer ? loginUser?.organizer : null
                                                        }))
                                                        : dispatch(deleteUser({
                                                            id: record?._id,
                                                            sortBy: '',
                                                            order: '',
                                                            search: '',
                                                            page: page,
                                                            limit: pageSize,
                                                        }))}
                                                    icon={null}
                                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                                >
                                                    <Button
                                                        type='text'
                                                        className='padding-1'>
                                                        <DeleteIcon
                                                            role='button'
                                                        />
                                                    </Button>
                                                </CommonPopconfirm>))
                                                :
                                                <CommonPopconfirm
                                                    icon={null}
                                                    title={auth?.decodeToken?.package ? t('organizer_usermanagement_user_deactivateUser', { name: 'user', status: "Activate" }) : t('superadmin_usermanagement_user_deactivateUser', { name: 'user', status: "Activate" })}
                                                    description={auth?.decodeToken?.package ? t('organizer_usermanagement_user_confirmdescription', { name: 'user', status: "activate" }) : t('superadmin_usermanagement_user_confirmdescription', { name: 'user', status: "activate" })}
                                                    onConfirm={() =>
                                                        auth?.decodeToken?.organizer ?
                                                            dispatch(deleteClient({
                                                                id: record?._id,
                                                                sortBy: '',
                                                                order: '',
                                                                search: '',
                                                                page: page,
                                                                limit: pageSize,
                                                                organizer: loginUser?.organizer ? loginUser?.organizer : null
                                                            }))
                                                            : dispatch(deleteUser({
                                                                id: record?._id,
                                                                sortBy: '',
                                                                order: '',
                                                                search: '',
                                                                page: page,
                                                                limit: pageSize,
                                                            }))}
                                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                                >
                                                    <Button
                                                        type='text'
                                                        className='padding-1'
                                                    >
                                                        <CloseIcon role='button' />
                                                    </Button>
                                                </CommonPopconfirm>
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Flex >
                );
            },
        })
    }

    return (
        <Fragment>
            <Flex vertical gap={24} className='change-tag'>
                <Row
                    justify='space-between'
                    align='middle'
                    className='bg-white padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>{auth?.decodeToken?.package ? t('organizer_usermanagement_user_userListTitle') : t('superadmin_usermanagement_user_userListTitle')}</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Input
                                    value={search}
                                    placeholder={auth?.decodeToken?.package ? t('organizer_common_search') : t('superadmin_common_search')}
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon color='#000' size={20} />}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                        <Flex justify='end'>
                            {
                                (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                                <Button onClick={() => navigate('/user/add')} >{auth?.decodeToken?.package ? t('organizer_usermanagement_user_addNewUser') : t('superadmin_usermanagement_user_addNewUser')}</Button>
                            }
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <DataTable
                            columns={columns}
                            pageSize={pageSize}
                            currentPage={page}
                            total={auth?.decodeToken?.organizer ? clientTotalRecords : totalRecords}
                            scrollX={1000}
                            showSorterTooltip={false}
                            isPagination={true}
                            handleOnChange={handleOnChange}
                            data={userList}
                            rowKey={(record) => record?._id}
                        />
                    </Col>
                </Row>
            </Flex>
        </Fragment>
    );
};

export default User;
