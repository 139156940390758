import React, { Fragment } from 'react';

import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

const CommonPopconfirm = ({
    title = 'No title provided',
    description = 'No description provided',
    onConfirm,
    okText,
    cancelText,
    children,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Popconfirm
                title={title}
                icon={null}
                description={description}
                onConfirm={onConfirm}
                okText={okText ? okText : t('superadmin_common_yesButton')}
                cancelText={cancelText ? cancelText : t('superadmin_common_noButton')}
                {...rest}
            >
                {children}
            </Popconfirm>
        </Fragment>
    );
};

export default CommonPopconfirm;
