import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';

import { themeConfig } from './utils/theme/config';

import './utils/languages/translations';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider wave={{ disabled: true }} theme={themeConfig}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>
);
