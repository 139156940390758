import React, { Fragment } from 'react';

import { Flex, Typography, Table, Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import DocumentIcon from '../../../components/icon/DocumentIcon';
import { useDispatch, useSelector } from 'react-redux';
import { approveAndRejectLeave } from '../redux/slice';

const { Title, Text } = Typography;

const RequestLeave = ({ requestLeaveList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const columns = [
    {
      title: t('organizer_common_user'),
      dataIndex: 'user',
      key: 'user',
      render: (text, record) => <Flex align='center' gap={10}>
        <div>
          <span style={{ backgroundColor: `${record.leaveType === "Sick" ? "#FFCFC5" : '#B7E7AF'}`, padding: '0px 10px', borderRadius: '10px' }}></span>
        </div>
        {text}
      </Flex>,
    },
    {
      title: t('organizer_vacationmanagement_leave_from_date'),
      dataIndex: 'leaveDateRange',
      key: 'leaveDateRange',
      render: (_, record) => {
        return (
          <Flex gap={8} align='center'>
            <Text>{`${dayjs(record?.leaveDateRange?.startDate).format('DD.MM.YY')} to ${dayjs(record?.leaveDateRange?.endDate).format('DD.MM.YY')}`}</Text>
          </Flex>
        )
      },
    },
    {
      title: t('organizer_vacationmanagement_leave') + t('organizer_common_type'),
      dataIndex: 'leaveType',
      key: 'leaveType',
    },
    {
      title: t('organizer_common_total') + ' ' + t('organizer_vacationmanagement_leave') + t('organizer_common_days'),
      dataIndex: 'totalLeaveDays',
      key: 'totalLeaveDays',
      render: (text) => <Text>{text} {t('organizer_common_days')}</Text>,
    },
    {
      title: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason'),
      dataIndex: 'leaveReason',
      key: 'leaveReason',
    },
    {
      title: t('organizer_common_uploadedfile'),
      dataIndex: 'upload',
      key: 'upload',
      render: (_, record) => {
        return <Flex align='center' gap={3}>
          <DocumentIcon />
          <Text>{record?.uploadedFile}</Text>
        </Flex>
      },
    },
  ];

  const hanldeDeclineLeave = (record) => {
    dispatch(approveAndRejectLeave({
      id: record?._id,
      status: 'Rejected',
      user: auth?.decodeToken?._id,
      organizer: auth?.decodeToken?.organizer
    }))
  }

  const handleApproveLeave = (record) => {
    dispatch(approveAndRejectLeave({
      id: record?._id,
      status: 'Approved',
      user: auth?.decodeToken?._id,
      organizer: auth?.decodeToken?.organizer
    }))
  }

  return (
    <Fragment>
      <Flex vertical gap={16}>
        <Flex vertical>
          <Title level={4} type='success' className='paddingX-2'>{t('organizer_common_all')} {t('organizer_vacationmanagement_leave')} {t('organizer_common_requests')}</Title>
          <Divider />
        </Flex>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <Flex gap={15} justify='end' align='center'>
                <Text className='rounded-1 padding-1 paddingX-3 bg-gray'>
                  {t('organizer_common_total') + ' ' + t('organizer_vacationmanagement_leave')} - <Text type='success'>{record.totalLeaves}</Text>
                </Text>
                <Text className='rounded-1 padding-1 paddingX-3 bg-gray'>
                  {t('organizer_vacationmanagement_remainingleaves')} - <Text type='success'>{record.remainPaidLeaves}</Text>
                </Text>
                <Button danger type='primary' onClick={() => hanldeDeclineLeave(record)}>{t('organizer_common_decline') + ' ' + t('organizer_vacationmanagement_leave')}</Button>
                <Button type='primary' onClick={() => handleApproveLeave(record)}>{t('organizer_common_approve') + ' ' + t('organizer_vacationmanagement_leave')}</Button>
              </Flex>
            ),
            rowExpandable: () => true,
            expandIcon: () => null,
          }}
          pagination={false}
          dataSource={requestLeaveList}
          scroll={{ x: 1000, y: null }}
          rowKey={"_id"}
          expandedRowKeys={requestLeaveList.map((item) => item._id)}
          expandIconColumnIndex={-1}
        />
      </Flex>
    </Fragment>
  );
};

export default RequestLeave;
