import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    usersList: [],
    administratorsList: [],
    user: {},
    loginUserId: null,
    loginUserRole: '',
    loginUserPackage: null,
    totalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUsersList: (state, action) => {
            state.user = {};
            state.isUpdating = false;
            state.usersList = action.payload;
            state.administratorsList = action.payload;
        },
        getAdministratorsList: (state, action) => {
            state.user = {};
            state.isUpdating = false;
            state.administratorsList = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.user = {};
            state.isSuccess = action.payload;
        },
        setLoginUserId: (state, action) => {
            state.loginUserId = action.payload;
        },
        setLoginUserRole: (state, action) => {
            state.loginUserRole = action.payload;
        },
        setLoginUserPackage: (state, action) => {
            state.loginUserPackage = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        getUser: (state, action) => {
            state.user = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
    },
});

export const getUsersListData = createAsyncThunk('getUsersListData', (filterData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/user/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getUsersList(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            dispatch(getUsersList([]));
            dispatch(setTotalrecords(0))
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createUser = createAsyncThunk('createUser', (userData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/user/insert`, userData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getUsersListData({ search: '', sortBy: '', order: '', page: 1, limit: 10 }))
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdUser = createAsyncThunk('getByIdUser', (id, { dispatch }) => {
    axios.get(`/user?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getUser(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
    })
});

export const updateUser = createAsyncThunk('updateUser', (userData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`user/update`, userData.updatedUser).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            if (!userData.isProfile) {
                dispatch(getUsersListData({ search: '', sortBy: '', order: '', page: 1, limit: 10 }))
            }
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteUser = createAsyncThunk('deleteUser', (object, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`user/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getUsersListData(object));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getUser, setIsUpdating, getAdministratorsList, setIsSuccess, getUsersList, setTotalrecords, setLoginUserId, setLoginUserRole, setLoginUserPackage } = slice.actions;

export default slice.reducer;
