import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Flex, Input, Modal, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

import { deleteUserType, getUserTypesByOrganizerListData, getUserTypesListData } from './redux/slice';

import DataTable from '../../components/data-table';

import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import SearchIcon from '../../components/icon/SearchIcon';
import EyeIcon from '../../components/icon/EyeIcon';
import { black } from '../../utils/theme/config';
import { getDecodeToken } from '../auth/redux/slice';

const { Title, Text, Paragraph } = Typography;

const UserType = ({ permission }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { userType, auth } = useSelector(state => state)

    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [totalRecord, setTotalRecord] = useState(0)
    const [userTypeList, setUserTypeList] = useState([])
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            dispatch(getDecodeToken(user))
        }
    }, [])

    useEffect(() => {
        getUserTypeList()
    }, [page, pageSize, order, sortBy, search, auth])

    useEffect(() => {
        setUserTypeList(userType.userTypesList)
        setTotalRecord(userType.totalRecords)
    }, [userType.userTypesList, userType.totalRecords])

    const getUserTypeList = () => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getUserTypesByOrganizerListData({
                sortBy: sortBy,
                order: order,
                search: search?.trim(),
                page: page,
                limit: pageSize,
                organizer: auth?.decodeToken?.organizer
            }))
        } else {

            dispatch(getUserTypesListData({
                sortBy: sortBy,
                order: order,
                search: search?.trim(),
                page: page,
                limit: pageSize,
                organizer: null
            }))
        }
    }

    const handleDeleteModal = (id) => {
        setIsDeleteModal(!isDeleteModal)
        setDeleteId(id)
    }

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const columns = [
        {
            title: auth?.decodeToken?.package ? t('organizer_usermanagement_usertype') : t('superadmin_usermanagement_usertype'),
            dataIndex: 'name',
            key: 'name',
            render: (text) => <Text>{text}</Text>,
            sorter: (_, __, order) => handleSortFunction(order, 'name')
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>{auth?.decodeToken?.package ? t('organizer_common_action') : t('superadmin_common_action')}</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                return (
                    <Flex justify='center'>
                        {
                            permission?.canUpdate &&
                            <Button
                                type='text'
                                className='padding-1'
                                onClick={() => navigate(`/user/type/edit/${record?._id}`)}>
                                {
                                    record?.isReadOnly
                                        ? <EyeIcon role='button' size={30} color={black} />
                                        : <EditIcon role='button' />
                                }
                            </Button>
                        }
                        {
                            permission?.canDelete &&
                            <Fragment>
                                {
                                    !record?.isReadOnly
                                        ? <Button
                                            type='text'
                                            className='padding-1'>
                                            <DeleteIcon
                                                role='button'
                                                onClick={() => handleDeleteModal(record?._id)}
                                            />
                                        </Button>
                                        : null
                                }
                            </Fragment>
                        }
                    </Flex >
                );
            },
        },)
    }

    return (
        <Fragment>
            <Flex vertical gap={12} className='user-management'>
                <Row
                    justify='space-between'
                    align='middle'
                    className='bg-white padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_title') : t('superadmin_usermanagement_usertype_title')}</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Input
                                    value={search}
                                    placeholder={auth?.decodeToken?.package ? t('organizer_common_search') : t('superadmin_common_search')}
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon color='#000' size={20} />}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                        <Flex justify='end'>
                            {
                                (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                                <Button onClick={() => navigate('/user/type/add')} >{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_addUserType') : t('superadmin_usermanagement_usertype_addUserType')}</Button>
                            }
                        </Flex>
                    </Col>
                </Row>
                <Flex vertical className='bg-white rounded-top-1'>
                    <DataTable
                        isPagination={true}
                        showSorterTooltip={false}
                        total={totalRecord}
                        pageSize={pageSize}
                        currentPage={page}
                        columns={columns}
                        data={userTypeList}
                        handleOnChange={handleOnChange}
                        rowKey={(record) => record?._id}
                    />
                </Flex>
            </Flex>

            <Modal
                centered
                open={isDeleteModal}
                closable={false}
                width={380}
                footer={[
                    <Button key='cancel' type='default' onClick={handleDeleteModal}>
                        {auth?.decodeToken?.package ? t('organizer_common_cancelButton') : t('superadmin_common_cancelButton')}
                    </Button>,
                    <Button
                        danger type='primary' htmlType='button'
                        onClick={() => {
                            dispatch(deleteUserType({
                                id: deleteId,
                                sortBy: '',
                                order: '',
                                search: '',
                                page: page,
                                limit: pageSize,
                                organizer: auth?.decodeToken?.organizer ? auth?.decodeToken?.organizer : null
                            }))
                            setIsDeleteModal(false)
                            setDeleteId('')
                        }}
                    >
                        {auth?.decodeToken?.package ? t('organizer_common_deleteButton') : t('superadmin_common_deleteButton')}
                    </Button>,
                ]}
            >
                <Flex vertical align='center' justify='center'>
                    <Title level={2} className='paddingY-2' type='danger'>{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_confirmTitle') : t('superadmin_usermanagement_usertype_confirmTitle')}</Title>
                    <Paragraph type='danger'>{auth?.decodeToken?.package ? t('organizer_usermanagement_usertype_confirmText') : t('superadmin_usermanagement_usertype_confirmText')}</Paragraph>
                </Flex>
            </Modal>
        </Fragment>
    );
};

export default UserType;
