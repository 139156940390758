import React, { Fragment, useEffect, useState } from 'react';

import { Button, Row, Col, DatePicker, Tooltip, Flex, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { getWorkHistoryListsData } from '../time-tracking/redux/slice';

import NextIcon from '../../components/icon/NextIcon';
import BackIcon from '../../components/icon/BackIcon';
import CalendarIcon from '../../components/icon/CalendarIcon';

import { primary, secondary } from '../../utils/theme/config';

import '../../styles/pages/work-history.scss';

const CustomDatePicker = ({ selectedDate, onDateChange }) => {
    const [calendarVisible, setCalendarVisible] = useState(false);
    const datePickerRef = React.useRef(null);
    const { t } = useTranslation();

    const handlePrevious = () => {
        onDateChange(selectedDate.subtract(1, 'month'));
    };

    const handleNext = () => {
        onDateChange(selectedDate.add(1, 'month'));
    };

    const handleOpenCalendar = () => {
        setCalendarVisible(true);
    };

    const handleDateChange = (date) => {
        setCalendarVisible(false);
        onDateChange(date || dayjs());
    };

    const handleClickOutside = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setCalendarVisible(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <Row align='middle' gutter={8} style={{ position: 'relative' }}>
            <Col>
                <span style={{ paddingLeft: '40px', fontSize: '12px' }}>{t('organizer_common_month')}</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button icon={<BackIcon size={24} color={primary} role='button' />} type='text' onClick={handlePrevious} style={{ margin: '0' }} />
                    <span style={{ margin: '0 8px', fontSize: '18px', fontWeight: '600' }}>{selectedDate.format('MMM YYYY')}</span>
                    <Button icon={<NextIcon size={30} color={primary} role='button' />} type='text' onClick={handleNext} style={{ margin: '0', marginTop: '8px' }} />
                    <Button type='text' icon={<CalendarIcon color={secondary} role='button' />} onClick={handleOpenCalendar} style={{ margin: '0', marginLeft: '10px' }} />
                    {calendarVisible && (
                        <DatePicker
                            picker='month'
                            open={calendarVisible}
                            value={selectedDate}
                            onChange={handleDateChange}
                            variant='borderless'
                            allowClear={false}
                            suffixIcon={() => null}
                            onClick={() => setCalendarVisible(true)}
                            onBlur={() => setCalendarVisible(false)}
                            style={{
                                position: 'absolute',
                                zIndex: 1000,
                                marginTop: '8px',
                                width: '0',
                                color: 'transparent'
                            }}
                        />
                    )}
                </div>
            </Col>
        </Row>
    );
};

const WorkHistory = () => {
    const [workHistoryDaya, setWorkHistoryData] = useState([]);

    const { auth, timeTracking } = useSelector(state => state);
    const dispatch = useDispatch();
    const [month, setMonth] = useState(dayjs().format('MM'));
    const [year, setYear] = useState(dayjs().format('YYYY'));
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [totalHours, setTotalHours] = useState(0);
    const { t } = useTranslation();

    const handleDateChange = (date) => {
        setSelectedDate(date || dayjs());
        setYear(dayjs(date).format('YYYY'))
        setMonth(dayjs(date).format('MM'))
    };

    useEffect(() => {
        if (Object.keys(auth?.decodeToken).length > 0) {
            getWorkHistoryData();
        }
    }, [auth, month, year])

    const getWorkHistoryData = () => {
        dispatch(getWorkHistoryListsData({
            user: auth?.decodeToken?._id,
            organizer: auth?.decodeToken?.organizer,
            month: month,
            year: year
        }))
    }

    useEffect(() => {
        setWorkHistoryData(timeTracking?.workHistory);
        setTotalHours(timeTracking?.workHistoryTotalRecords);
    }, [timeTracking?.workHistory, timeTracking?.workHistoryTotalRecords, timeTracking])

    const renderTimeEntries = (timeEntries) => {
        return (
            <div style={{ display: 'flex', width: '100%' }}>
                {timeEntries.map((entry, index) => {
                    const isWork = entry.type === 'work';
                    const color = isWork ? '#B7E7AF' : '#FFCFC5';
                    const startTime = dayjs(entry.start, 'HH:mm');
                    const endTime = dayjs(entry.end, 'HH:mm');
                    const entryDurationMinutes = endTime.diff(startTime, 'minute');
                    const startOffsetMinutes = startTime.diff(dayjs("04:00", 'HH:mm'), 'minute');
                    const widthPercent = (entryDurationMinutes * 100) / (20 * 60);
                    const startOffsetPercent = (startOffsetMinutes * 100) / (20 * 60);

                    return (
                        <Tooltip title={entry.duration} key={index}>
                            <div
                                style={{
                                    backgroundColor: color,
                                    width: `${widthPercent}%`,
                                    marginLeft: index === 0 ? `${startOffsetPercent}%` : '0%',
                                    height: '30px',
                                    textAlign: 'center',
                                    lineHeight: '30px',
                                    fontSize: '12px'
                                }}
                            >
                                {entry.duration >= '01:00' ? entry.duration : ''}
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
        );
    };

    const renderTimeLabels = () => {
        const timeLabels = [];

        for (let i = 4; i <= 23; i++) {
            timeLabels.push(`${i.toString().padStart(2, '0')}:00`);
        }

        return (
            <Row align='start' className='w-100' justify='space-between'>
                {timeLabels.map((label, index) => (
                    <div style={{ fontSize: '12px', width: '5%', padding: '6px 0' }}>
                        {label}
                    </div>
                ))}
            </Row>
        );
    };

    const columns = [
        {
            title: t('organizer_common_date'),
            dataIndex: 'date',
            fixed: 'left',
            key: 'date',
            width: '130px',
            render: (_, record) => {
                return dayjs(record?.date).format('DD-MM-YYYY') || '-'
            }
        },
        {
            title: t('organizer_common_totalhours'),
            dataIndex: 'totalHours',
            fixed: 'left',
            key: 'totalHours',
            width: '80px',
        },
        {
            title: t('organizer_common_breakhours'),
            dataIndex: 'breakHours',
            fixed: 'left',
            key: 'breakHours',
            width: '80px',
        },
        {
            title: t('organizer_common_overtime'),
            dataIndex: 'ourTime',
            fixed: 'left',
            key: 'ourTime',
            width: '100px',
        },
        {
            title: (
                <Flex align='center' justify='space-between'>
                    <span style={{ padding: '5px 0' }}>{t('organizer_common_time')}</span>
                </Flex>
            ),
            key: 'time',
            children: [
                {
                    title: renderTimeLabels(),
                    dataIndex: 'building',
                    key: 'building',
                    render: (_, record) => (
                        <div>
                            {renderTimeEntries(record.timeEntries)}
                        </div>
                    ),
                },
            ],
        },
    ];

    return (
        <Fragment>
            <Flex vertical className='padding-1 tracking-table bg-white'>
                <Flex align='center' justify='space-between' wrap>
                    <CustomDatePicker selectedDate={selectedDate} onDateChange={handleDateChange} />
                    <Flex>
                        <div style={{ paddingRight: '10px' }}>
                            <span style={{ backgroundColor: '#B7E7AF', padding: '0px 10px', borderRadius: '4px' }}></span>
                            <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_workinghours')}</b></span>
                        </div>
                        <div>
                            <span style={{ backgroundColor: '#FFCFC5', padding: '0px 10px', borderRadius: '4px' }}></span>
                            <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_breakhours')}</b></span>
                        </div>
                    </Flex>
                </Flex>
                <Table
                    columns={columns}
                    dataSource={workHistoryDaya}
                    pagination={false}
                    scroll={{ x: 2200 }}
                    bordered
                    rowKey={(record) => record?._id}
                />
            </Flex>
        </Fragment>
    );
};

export default WorkHistory;
